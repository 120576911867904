// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Grid,
    Paper,
    TextField,
    Typography,
    List, ListItemText, ListItem,
    Breadcrumbs,
    Link, Box,
    Tabs, Tab,
    AppBar,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    CardActions, Card, CardContent, CardActionArea, CardHeader, Avatar,
    IconButton,
    Snackbar
} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import appVars from '../../../appVars.json';
import { Formik, Field, Form } from 'formik';
import { FileCopy } from "@material-ui/icons";
import Prism from 'prismjs';
import FacebookLogo from '../../../images/Facebook-Logo-700x394.png';
import ClipboardJS from "clipboard";
import themeFile from '../../../theme.json';
import FileUploads from '../../file-uploads';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "6px 0",
    },
    inputs: {
        marginBottom: theme.spacing(2.5),
        minWidth: 120,
    },
    paper: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(2)
    },
    breadcrumbs: {
        margin: "0 1em",
    },
    cardHead: {
        background: theme.palette.secondary.main
    },
    TabPanel: {
        margin: "1em 0"
    }
}));

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

const MyInput = ({ field, form, ...props }) => {
    return <TextField variant="outlined" shrink {...field} {...props} />;
};


const MySelectDonationTypes = ({ field, form, ...props }) => {
    return <TextField select variant="outlined" size="small" label="Revenue Trackable" fullWidth {...field} {...props} >
        <MenuItem value="">Select One</MenuItem>
        <MenuItem value="donation">eCommerce/Donation</MenuItem>
        <MenuItem value="website">Website</MenuItem>
    </TextField>
};



const myRevenueSelect = ({ field, form, ...props }) => {
    return <TextField select variant="outlined" size="small" label="Revenue Trackable" fullWidth {...field} {...props} >
        <MenuItem value="">Select One</MenuItem>
        <MenuItem value="true">Yes</MenuItem>
        <MenuItem value="false">No</MenuItem>
    </TextField>
};


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
var id = getLastItem(window.location.href);

const Ecommerce = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [copySuccessShow, setCopySuccessShow] = useState(false);
    const [copySuccess, setCopySuccess] = useState("");
    const [saveOpen, setSaveOpen] = useState(false);
    const [saveMsg, setSaveMsg] = useState("");
    const [saveSeverity, setSeverity] = useState("success");
    const [hasError, setErrors] = useState({ status: false });
    const [site, setSite] = useState(false);
    const [clientUsers, setClientUsers] = useState([
        { "Client": "Test", "ClientID": "1" }
    ]);
    const [payloadTemplate, setPayloadTemplate] = useState({});
    const [value, setValue] = React.useState(0);


    const childToParent = (childdata) => {

        console.log(childdata);
        var f = appVars.api_url + '/imageFiles/' + childdata.imageName;
        const img = new Image();
        img.onload = function () {
            var SI = document.getElementById('message');
            SI.style.backgroundImage = "url(" + f + ")";
            SI.style.height = this.height + "px";
            SI.style.width = this.width + "px";

        }
        img.id = "imageBuilder";
        img.src = f;


        // SI.appendChild(IB);
        // IB.style.display = "none";

        // var MSG = document.getElementById('logo');
        // MSG.src = "Sample Name";



    };



    const handleChange = (event, newValue) => {

        console.log(newValue)

    };



    const handleTabChange = (event, newValue) => {
        setValue(newValue);
        Prism.highlightAll();
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrors({ status: false });;
        setCopySuccessShow(false);
        setSaveOpen(false);
    };


    const handlepayload = (e) => {

        payloadTemplate[e.currentTarget.id] = e.currentTarget.value;
        setPayloadTemplate(payloadTemplate);
        site.payload = payloadTemplate;
        //site.payload = JSON.stringify(site.payload);
        setSite(site);
        //   console.log(site);
    };

    async function fetchData() {
        const res = await fetch(appVars.api_url + "/sites/donation/" + id);
        res
            .json()
            .then((res) => {
                setSite(res[0]);
                setPayloadTemplate(JSON.parse(res[0].payload));
                console.log(res[0]);
            });
        const res2 = await fetch(appVars.api_url + "/tagmanagers/ecommerce/" + id);
        res2
            .json()
            .then((res2) => {
                setClientUsers(res2);
                //console.log(res2);
            });



    }

    useEffect(() => {


        if (!site) {
            fetchData();


            var clipboard = new ClipboardJS("#gtmcopy, #fbcopy,#copy-script,#copy-event");
            clipboard.on("success", function (e) {
                setCopySuccess(e.action + ": " + e.text);
                setCopySuccessShow(true);
                e.clearSelection();
            });

        }

    }, []);





    return (
        <div className={classes.root} >
            {(site ?
                <Formik
                    initialValues={site}
                    onSubmit={async (values) => {

                        delete values.updatedAt;
                        delete values.createdAt;
                        //delete values.lasttrack;
                        //  values.payload=JSON.stringify(values.payload, null, 2);

                        setSite(values);
                        //console.log(JSON.stringify(values, null, 2));
                        // return;
                        const re3 = await fetch(appVars.api_url2 + "/sites/donation/update",
                            {
                                method: "PUT",
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(values)
                            }
                        );
                        re3
                            .json()
                            .then((res) => {
                                if (res.status === 'eSite Updated') {
                                    setSaveOpen(true);
                                    setSeverity("success");
                                    setSaveMsg(site.site + " Record Updated");
                                } else {
                                    setSaveOpen(true);
                                    setSeverity("error");
                                    setSaveMsg("")
                                }

                                console.log(res);
                            });
                    }}
                    enableReinitialize
                >
                    <Form id="clientForm">


                        <div>
                            <Grid style={{ background: themeFile.palette.text.hint }} container xl={12} spacing={2} alignItems='center' alignContent="center" >

                                <Grid item xl={8} md={4} xs={12}>



                                    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
                                        <Link color="inherit" href="/" >
                                            Dashboard
                                        </Link>
                                        <Link color="inherit" href="/control" >
                                            Control Panel
                                        </Link>
                                        <Link color="inherit" href="/control/sites" >
                                            Site Settings
                                        </Link>
                                        <Typography color="secondary">{site.site}</Typography>
                                    </Breadcrumbs>


                                </Grid>

                                <Grid item xl={2} md={4} xs={6} >
                                    <Typography color='secondary'>{site.lasttrack}</Typography>
                                </Grid>


                                <Grid item xl={1} md={2} xs={6} >
                                    <Button type="submit" size='small' fullWidth variant="contained" color="secondary" >Save</Button>

                                </Grid>
                                <Grid item xl={1} md={2} xs={6}>

                                    <Button type="reset" size='small' fullWidth variant="contained" color="default" >Cancel</Button>
                                </Grid>


                                <AppBar position="static" style={{ background: themeFile.palette.primary.dark }} >
                                    <Tabs value={value} onChange={handleTabChange} aria-label="simple tabs example">
                                        <Tab label="General" {...a11yProps(0)} />
                                        <Tab label="Payload" {...a11yProps(1)} />
                                        <Tab label="Scripts" {...a11yProps(2)} />

                                    </Tabs>

                                </AppBar>

                            </Grid>
                            <Grid container xl={12} spacing={1} style={{ padding: theme.spacing(2) }} >

                                <Grid item xl={9} md={9} sm={8} xs={12}>

                                    <TabPanel value={value} index={0}>

                                        <Grid container spacing={2} >

                                            <Grid item xl={12} sm={12} xs={12} >

                                                <Grid item xl={6}>
                                                    <Field component={MyInput} variant="outlined" size="small" id="pkRecID" disabled name="pkRecID" label="Primary ID" fullWidth className={classes.inputs}
                                                    />
                                                </Grid>
                                                <Grid item xl={6}>
                                                    <Field component={MyInput} variant="outlined" size="small" id="site" name="site" label="Site Name" fullWidth className={classes.inputs}
                                                    />

                                                </Grid>

                                                <Grid container spacing={2}>


                                                    <Grid item xl={11}>

                                                        <Field component={MyInput} variant="outlined" size="small" id="link" name="link" label="Link to Documentation" fullWidth className={classes.inputs}
                                                        />
                                                    </Grid>
                                                    <Grid item xl={1}>
                                                        <Button variant="outlined" color="secondary" size="large" fullWidth target="_blank" >View</Button>
                                                    </Grid>
                                                </Grid>



                                                <Grid container spacing={2}>


                                                    <Grid item xl={11}>

                                                        <Field component={MyInput} variant="outlined" size="small" id="scriptName" name="scriptName" label="Script Name" fullWidth className={classes.inputs}
                                                        />
                                                    </Grid>
                                                    <Grid item xl={1}>
                                                        <Button variant="outlined" color="secondary" size="large" fullWidth target="_blank" >View</Button>
                                                    </Grid>
                                                </Grid>
                                                <FormControl fullWidth>

                                                    <Field component={myRevenueSelect} id="trackable" name="trackable" label="Is It Trackable" fullWidth className={classes.inputs}
                                                    />
                                                </FormControl>
                                                <FormControl fullWidth>

                                                    <Field component={MySelectDonationTypes} id="type" name="type" label="Site Type" fullWidth className={classes.inputs}
                                                    />
                                                </FormControl>

                                            </Grid>


                                        </Grid>



                                    </TabPanel>
                                    <TabPanel className={classes.TabPanel} value={value} index={1}>

                                        <Grid container xl={12} spacing={3}>
                                            <Grid item xl={12}>

                                                <Typography variant="h5" color="primary" style={{ borderBottom: '1px solid ' + themeFile.palette.primary.main, marginBottom: '1em', marginTop: 0 }}>Facebook API Payload</Typography>
                                                {Object.entries(payloadTemplate).map((text, index) => (
                                                    <Field component={MyInput} size="small" id={text[0]} name={text[0]} defaultValue={text[1]} label={text[0]} fullWidth variant="outlined" className={classes.inputs}
                                                        onChange={handlepayload}
                                                    />
                                                ))}


                                            </Grid>

                                        </Grid>

                                    </TabPanel>

                                    <TabPanel className={classes.TabPanel} value={value} index={2}>

                                        <Grid container xl={12} spacing={3}>
                                            <Grid item xl={6}>
                                                <Card className={classes.card} >
                                                    <CardHeader
                                                        titleTypographyProps={{ fontSize: "14px" }}
                                                        className={classes.cardHead}
                                                        title="Facebook"
                                                        avatar={
                                                            <Avatar aria-label="">
                                                                <img style={{ maxWidth: '35px' }} alt="" title="" src={FacebookLogo}></img>
                                                            </Avatar>
                                                        }
                                                        subheader="Conversion API Script"
                                                    />
                                                    <CardContent>
                                                        <pre>
                                                            <code className="language-html copy-script">
                                                                {'<!-- Facebook Server API --><script class="dwi-component-fbc" id="{{Facebook Server API KEY}}" src="https://conversions.digitalwerks.com/api/' + site.scriptName + '"></script><!-- Coding by DigitalWerks -->'}
                                                            </code>
                                                        </pre>
                                                    </CardContent>
                                                    <CardActionArea>
                                                        <CardActions>
                                                            <Grid item xl={1}>
                                                                <IconButton id="copy-script" variant="outlined" color="primary" className={classes.copylabel} data-clipboard-target=".copy-script" size="small"><FileCopy ></FileCopy> </IconButton>
                                                            </Grid>
                                                        </CardActions>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>


                                </Grid>



                                <Grid item xl={3} md={3} sm={4} xs={false}   >

                                    <Paper style={{ padding: '1em' }}>
                                        <img id="logo" style={{ width: '100%' }} alt={site.site} src={appVars.api_url + "/imageFiles/" + site.pkRecID + '.png'} />
                                        <FileUploads childToParent={childToParent} newid={site.pkRecID}></FileUploads>
                                        <div id="message"></div>
                                        <List component="nav">

                                            {(clientUsers).map((text, index) => (
                                                <ListItemLink target="_BLANK" href={"/subclientviewer/" + text['id']}>
                                                    <ListItemText primary={text['name']} />
                                                </ListItemLink>
                                            ))}

                                        </List>
                                    </Paper>
                                </Grid>


                            </Grid>

                        </div>

                    </Form>

                </Formik>

                : '')}


            <Snackbar open={hasError.status} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    Error: <br></br>
                    {hasError.msg} <br></br>
                    <Button fullWidth color="primary" onClick={window.location.reload}>
                        Refresh
                    </Button>
                </Alert>
            </Snackbar>

            <Snackbar open={saveOpen} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={saveSeverity}>
                    {saveMsg}
                </Alert>
            </Snackbar>


            <Snackbar
                open={copySuccessShow}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success">
                    {copySuccess}
                </Alert>
            </Snackbar>

        </div>
    );
};

Ecommerce.propTypes = {

};

export default Ecommerce;
