import { Card, CardContent, CardHeader, Grid, Typography, LinearProgress } from '@material-ui/core';
import { React, useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import themeFile from '../../theme.json';
import ChartCard from '../chart-card';
import { NumericFormat } from 'react-number-format';
import TableCard from '../table-card';
import { lightenHexColor } from '../../dwi-functions';
import moment from 'moment';

import PieChartCard from '../pie-chart';
import _ from 'lodash';

const theme = createTheme(themeFile);






const Meta = (props) => {
    const [creativeData, setCreativeData] = useState([]);
    const [ageData, setAgeData] = useState([]);
    const [genderData, setGenderData] = useState([]);
    const [data, setMetaData] = useState();
    const [campaignData, setCampaignData] = useState([{ "name": 'props.title' }]);

    //console.log(props);



    async function fetchReportData() {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "aid": props.advertiser_id,
            "sdate": props.sDate,
            "edate": props.eDate
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const res4 = await fetch("http://localhost:7385/meta/data", requestOptions)
        res4
            .json()
            .then((res4) => {
                setMetaData(res4);
            })
            .catch((err) => {
                console.log(err);
            });

        const campTable = await fetch("http://localhost:7385/meta/campaigns", requestOptions);
        //    console.log(props);
        campTable
            .json()
            .then((campaigns) => {
                setCampaignData(campaigns);

            })
            .catch((err) => {
                console.log(err);
            });

        const creativeTable = await fetch("http://localhost:7385/meta/creatives", requestOptions);
        //    console.log(props);
        creativeTable
            .json()
            .then((creatives) => {
                setCreativeData(creatives);

            })
            .catch((err) => {
                console.log(err);
            });

        const ageChart = await fetch("http://localhost:7385/meta/data/age", requestOptions);
        //    console.log(props);
        ageChart
            .json()
            .then((age) => {

                var sd = _(age) // Filter out items where CONVERSIONS is 0
                    .groupBy('AGE')
                    .map((groupedData, key) => ({ name: key, count: _.sumBy(groupedData, 'CLICKS') }))
                    .value();


                var rd = _(age) // Filter out items where CONVERSIONS is 0
                    .groupBy('GENDER')
                    .map((groupedData, key) => ({ name: key, count: _.sumBy(groupedData, 'CLICKS') }))
                    .value();
                console.log(rd);
                setGenderData(rd);
                setAgeData(sd);

                // setAgeData(_.map(age, ({ AGE, CLICKS }) => ({ name: AGE, count: CLICKS })));

            })
            .catch((err) => {
                console.log(err);
            });


    }





    useEffect(() => {
        // if(!data){
        fetchReportData();
        //console.log(data);
        //  }
    }, [props]);





    return (
        <div >
            <ThemeProvider theme={theme}>


                {(data ?
                    <div>
                        <Grid container spacing={1} alignContent="space-between">
                            <Grid style={{ display: 'none' }} item sm={6} xs={12}>
                                <Card >
                                    <CardHeader style={{ textAlign: 'center', color: "#fff", backgroundColor: lightenHexColor(props.themeColor, 0) }} title="Platform Expense"></CardHeader>
                                    <CardContent>
                                        <Typography align='center' variant="h4"><NumericFormat decimalScale={2} value={data.cost.count} thousandSeparator="," displayType="text" prefix="$" /></Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid style={{ display: 'none' }} item sm={6} xs={12}>
                                <Card style={{ marginTop: theme.spacing(0) }}>
                                    <CardHeader style={{ textAlign: 'center', color: "#fff", backgroundColor: lightenHexColor(props.themeColor, 0) }} title="Platform ROI"></CardHeader>
                                    <CardContent >
                                        <Typography align='center' variant="h4"><NumericFormat decimalScale={2} value={((data.revenue.count / data.cost.count) * 100)} displayType="text" suffix="%" /></Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Impressions"
                                    data={data.impressions}
                                    client={props.client}
                                    color={props.themeColor}
                                />
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Clicks"
                                    data={data.clicks}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="CTR"
                                    data={data.ctr}
                                    client={props.client}
                                    color={props.themeColor}
                                />
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="CPC"
                                    data={data.cpc}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                        </Grid>

                        <Grid container spacing={2} alignContent="space-between">

                            <Grid item xl={3} sm={3} xs={12} >
                                <ChartCard
                                    title="Conversions"
                                    data={data.conversions}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Revenue"
                                    data={data.revenue}
                                    client={props.client}
                                    color={props.themeColor}
                                />


                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Conversion Rate"
                                    data={data.convrate}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="COST"
                                    data={data.cost}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                        </Grid>




                        <Grid container spacing={2} alignContent="space-between">

                            <Grid item sm={12} xs={12} >
                                <TableCard
                                    title="Campaigns"
                                    data={campaignData}
                                    count={5}
                                    client={props.client}
                                    color={props.themeColor}
                                    columns={[
                                        { title: "Campaign", field: "CAMPAIGN", render: rowdata => <Typography style={{ minWidth: 350, fontSize: '13px', padding: 0, margin: 0 }}>{rowdata.CAMPAIGN}</Typography> },
                                        { title: "Impressions", field: "IMPRESSIONS", hidden: false },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 30)

                                            }, title: "Clicks", field: "CLICKS", hidden: false
                                        },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 30)

                                            }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100)} displayType="text" suffix="%" />
                                        },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 60)

                                            }, title: "Conversions", field: "CONVERSIONS", hidden: false
                                        },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 60)

                                            }, title: "Conv %", field: "CONV_RATE", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                        },
                                        { title: "Revenue", field: "REVENUE", type: 'currency' },
                                        { title: "Cost", field: "COST", type: 'currency' },
                                        { title: "CPC", field: "CPC", type: 'currency', render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.COST / rowdata.CLICKS))} displayType="text" prefix="$" /> }
                                    ]}
                                />


                            </Grid>



                            <Grid item sm={12} xs={12} >
                                <TableCard
                                    title="Creative"
                                    data={creativeData}
                                    count={5}
                                    client={props.client}
                                    color={props.themeColor}
                                    columns={[
                                        { title: "Ad Name", field: "CREATIVE", render: rowdata => <Typography style={{ minWidth: 350, fontSize: '13px', padding: 0, margin: 0 }}>{rowdata.CREATIVE}</Typography> },
                                        { title: "Impressions", field: "IMPRESSIONS", hidden: false },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 30)

                                            }, title: "Clicks", field: "CLICKS", hidden: false
                                        },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 30)

                                            }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100).toPrecision(2)} displayType="text" suffix="%" />
                                        },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 60)

                                            }, title: "Conversions", field: "CONVERSIONS", hidden: false
                                        },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 60)

                                            }, title: "Conv %", field: "CONV_RATE", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100).toPrecision(2)} displayType="text" suffix="%" />
                                        },
                                        { title: "Revenue", field: "REVENUE", type: 'currency' },
                                        { title: "Cost", field: "COST", type: 'currency' },
                                        { title: "CPC", field: "CPC", type: 'currency', render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.COST / rowdata.CLICKS))} displayType="text" prefix="$" /> }
                                    ]}
                                />


                            </Grid>

                            <Grid item sm={6}>

                                <PieChartCard
                                    title="Clicks - By Age"
                                    data={ageData}
                                    client={props.client}
                                    color={props.themeColor}
                                />
                            </Grid>

                            <Grid item sm={6}>
                                <PieChartCard
                                    title="Clicks - By Gender"
                                    data={genderData}
                                    client={props.client}
                                    color={props.themeColor}
                                />
                            </Grid>
                            <Grid ite sm={3}>

                            </Grid>

                        </Grid>


                    </div>
                    : <LinearProgress style={{ margin: theme.spacing(8) }}></LinearProgress>)}


            </ThemeProvider>




        </div>
    );
};

Meta.propTypes = {

};

export default Meta;
