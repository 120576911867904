import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { AppBar, Box, Breadcrumbs, Link, Tabs, Tab, TextField, Toolbar, Typography } from '@material-ui/core';
import appVars from '../../appVars.json';
import { useTheme } from '@material-ui/core/styles';
import UserTable from '../../UserTable';
import SiteTypes from "../SiteTypes";

const av = Object.entries(appVars);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const MyAppVars = ({ field, form, ...props }) => {
    return <div>
        {
            av.map((entry) => (
                <TextField label={entry[0]} type="text" shrink fullWidth value={entry[1]}></TextField>

            ))}

    </div>;
};

const ControlPanel = (props) => {


    const [websites, setWeb] = useState([]);
    const [ecom, seteCom] = useState([]);
    const [value, setValue] = React.useState(0);
    const theme = useTheme();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchData() {
        const res = await fetch(appVars.api_url + "/sites/website");
        res
            .json()
            .then((res) => {
                setWeb(res);
                //console.log(res);
            });

        const res2 = await fetch(appVars.api_url + "/sites/donation");
        res2
            .json()
            .then((res2) => {
                seteCom(res2);
                //console.log(res2);
            });


    }

    useEffect(() => {
        fetchData();
    }, []);



    return (
        <div>
            <AppBar position="static" color="primary">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Global Settings" {...a11yProps(0)} />
                    <Tab label="ECommerce Platforms" {...a11yProps(1)} />
                    <Tab label="Web Site Platforms" {...a11yProps(2)} />
                    <Tab label="Users" {...a11yProps(3)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <AppBar color="secondary" position="static">

                    <Toolbar>
                        <Breadcrumbs style={{ width: '100%' }} aria-label="breadcrumb">
                            <Link color="textPrimary" href="/" >
                                Dashboard
                            </Link>
                            <Link color="textPrimary" href={"/control/"} >
                                Settings
                            </Link>
                            <Typography color="primary" variant="subtitle1" style={{color:"#fff"}}  >Global Settings</Typography>
                        </Breadcrumbs>
                    </Toolbar>
                </AppBar>
                <div style={{ padding: theme.spacing(2) }}>
                    <MyAppVars></MyAppVars>
                </div>

            </TabPanel>
            <TabPanel value={value} index={1}>
                <AppBar color="secondary" position="static">

                    <Toolbar>
                        <Breadcrumbs style={{ width: '100%' }} aria-label="breadcrumb">
                            <Link color="textPrimary" href="/" >
                                Dashboard
                            </Link>
                            <Link color="textPrimary" href={"/control/"} >
                                Settings
                            </Link>
                            <Typography color="primary" style={{color:"#fff"}} variant="subtitle1" >eCommerce Platforms</Typography>
                        </Breadcrumbs>
                    </Toolbar>
                </AppBar>


                <div style={{ padding: theme.spacing(1) }}>
                    <SiteTypes type="ecommerce"></SiteTypes>
                </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <AppBar color="secondary" position="static">

                    <Toolbar>
                        <Breadcrumbs style={{ width: '100%' }} aria-label="breadcrumb">
                            <Link color="textPrimary" href="/" >
                                Dashboard
                            </Link>
                            <Link color="textPrimary" href={"/control/"} >
                                Settings
                            </Link>
                            <Typography color="primary" variant="subtitle1" style={{color:"#fff"}}  >Website Platforms</Typography>
                        </Breadcrumbs>
                    </Toolbar>
                </AppBar>

                <div style={{ padding: theme.spacing(1) }}>
                    <SiteTypes></SiteTypes>
                </div>
            </TabPanel>

            <TabPanel value={value} index={3}>
                <UserTable></UserTable>
            </TabPanel>

        </div>
    );
};

ControlPanel.propTypes = {

};

export default ControlPanel;
