import { Typography, Link, TextField, Button, Grid } from '@material-ui/core';
import MaterialTable from 'material-table';
import React, { useState } from 'react';
import { style } from './index.scss';





const EmailLinkValidator = (props) => {

    const [links, setLinks] = useState([{ "text": "", "url": "" }]);

    const HandleValidation = (e) => {
        var s = document.getElementById('pastedHtmlCopy');
        s.innerHTML = document.getElementById('pastedHtml').value;
        var doc = new DOMParser().parseFromString(document.getElementById('pastedHtml').value, "text/html");
        var l = doc.getElementsByTagName('a');
        var a = [];

        Array.from(l).forEach(function (element, f) {
            a.push({
                "text": (element.text !== "" ? element.text : element.children[0].title + ' [img]'),
                "url": (element.href !=="" ? element.href : "No Link"),
                "src": (element.text !== "" ? element.text : <img style={{maxWidth:"150px"}} alt={element.children[0].alt} title={element.children[0].title} src={element.children[0].src}/> ),
                "alt": (element.text !== "" ? "": element.children[0].alt)
            });
            console.log(element.children[0]);
        });
        setLinks(a);
    }


    return (
        <div className={style} class="grid"> <Typography variant='h3'>Email Link Validator</Typography>
            <Grid container spacing={3}>

                <Grid xl={6} item >
                    <Button color="secondary" variant="outlined" onClick={HandleValidation}>
                        Validate Links
                    </Button>


                    <TextField class="text" id="pastedHtml" type="text" placeholder='Paste HTML' variant="outlined" multiline={false} fullWidth />
                    <div id="pastedHtmlCopy"></div>

                </Grid>
                <Grid xl={6}>
                    <MaterialTable
                        options={{
                            showTitle: false,
                            pageSize: 30,
                            search:false
                        }}
                        data={links}
                        title="Links"
                        columns={[
                            { title: 'Text', field: 'text',render: rowData => <div><div style={{background:"#ccc",padding:'.25em',border:"1px dashed red",marginBottom:".25em"}}>{rowData.alt}</div><div>{(rowData.src )}</div></div> },
                            { title: 'URL', field: 'url'}
                        ]}
                    />


                </Grid>


                <Grid xl={6} item>

                </Grid>
            </Grid>







        </div>
    );
};

EmailLinkValidator.propTypes = {

};

export default EmailLinkValidator;
