import React, { useState, useEffect } from 'react';
import { Box, Grid, LinearProgress, Typography } from '@material-ui/core';
import ChartCard from '../../../reports/chart-card';
import { NumericFormat } from 'react-number-format';
import TableCard from '../../../reports/table-card';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { lightenHexColor, darkenHexColor } from '../../../dwi-functions';
import appVars from '../../../appVars.json';



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        //  maxWidth: 1600,
        margin: 'auto'
    },
    progress: {
        margin: theme.spacing(2)
    },
    data: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    content: {
        marginTop: 0,
        [theme.breakpoints.up('sm')]: {
            marginTop: 0,
        },

    },
    boxes: {
        padding: theme.spacing(1)
    }
}));


const Overview = (props) => {
    const [campaignGP, setcampaignGP] = useState();
    const theme = useTheme();
    const classes = useStyles();




    async function fetchData() {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "cid": props.clientid,
            "sdate": props.sdate,
            "edate": props.edate,
            "account": "Paid",
            "type": "SEARCH"
        });


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        var k = await fetch(appVars.api_url + "/platform-overview/get", requestOptions)
        k
            .json()
            .then(response => setcampaignGP(response))
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }





    useEffect(() => {

      //  fetchData();



        return () => {
            //        cleanup
        };
    }, [props]);




    return (

        <div>

            {(campaignGP ?


                <div>
                    <Grid container spacing={1} style={{ paddingBottom: theme.spacing(2) }} >
                        <Grid item sm={2}><Box style={{ padding: theme.spacing(1), backgroundColor: lightenHexColor(props.themeColor, 20) }}><Typography align='center' className={classes.boxes}>IMPRESSIONS</Typography><Typography variant='h6' align='center' ><NumericFormat displayType='text' decimalScale={2} thousandSeparator="," value={campaignGP[0][1]['IMPRESSIONS']} ></NumericFormat></Typography></Box></Grid>
                        <Grid item sm={2}><Box style={{ padding: theme.spacing(1), backgroundColor: lightenHexColor(props.themeColor, 20) }}><Typography align='center' className={classes.boxes}>CLICKS</Typography><Typography variant='h6' align='center' >{campaignGP[0][1]['CLICKS']}</Typography></Box></Grid>
                        <Grid item sm={2}><Box style={{ padding: theme.spacing(1), backgroundColor: lightenHexColor(props.themeColor, 20) }}><Typography align='center' className={classes.boxes}>CTR</Typography><Typography variant='h6' align='center' >{campaignGP[0][1]['CTR']}</Typography></Box></Grid>
                        <Grid item sm={2}><Box style={{ padding: theme.spacing(1), backgroundColor: lightenHexColor(props.themeColor, 20) }}><Typography align='center' className={classes.boxes}>CONVERSIONS</Typography><Typography variant='h6' align='center' >{campaignGP[0][1]['CONVERSIONS']}</Typography></Box></Grid>
                        <Grid item sm={2}><Box style={{ padding: theme.spacing(1), backgroundColor: lightenHexColor(props.themeColor, 20) }}><Typography align='center' className={classes.boxes}>REVENUE</Typography><Typography variant='h6' align='center' ><NumericFormat displayType="text" decimalScale={2} thousandSeparator=","  prefix='$' value={campaignGP[0][1]['REVENUE'].toFixed(2)} ></NumericFormat></Typography></Box></Grid>
                        <Grid item sm={2}><Box style={{ padding: theme.spacing(1), backgroundColor: lightenHexColor(props.themeColor, 20) }}><Typography align='center' className={classes.boxes}>COST</Typography><Typography variant='h6' align='center'><NumericFormat displayType="text" decimalScale={2} thousandSeparator=","  prefix='$' value={campaignGP[0][1]['COST'].toFixed(2)} ></NumericFormat></Typography></Box></Grid>

                    </Grid>




                    <TableCard
                        title="Google Paid Campaigns"
                        data={campaignGP[0][0]}
                        count={10}
                        options={{
                            rowStyle: { padding: 0, margin: 0 },
                            cellStyle: { border: '1px solid ' + lightenHexColor(props.themeColor, 0), textAlign: 'center' },
                            headerStyle: { textAlign: "center", backgroundColor: darkenHexColor(props.themeColor, 20), color: "#fff", border: '1px solid ' + props.themeColor, paddingTop: 5, paddingBottom: 5 },
                            grouping: false,
                            searchFieldVariant: "standard",
                            exportButton: true,
                            exportAllData: true,
                            search: false,
                            paging: false,
                            pageSize: 4,
                            pageSizeOptions: [10, 25, 50, 100],
                            showTitle: false,
                            toolbar: false,
                            sort: true
                        }}
                        color={props.themeColor}
                        columns={[
                            { title: "Date", field: "CAMPAIGN", render: rowdata => <Typography style={{ textAlign: 'left', padding: 0, margin: 0 }}>{rowdata.segments_date.value}</Typography> },
                            { title: "Impressions", field: "IMPRESSIONS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" /> },
                            {
                                cellStyle: {
                                    backgroundColor: lightenHexColor(props.themeColor, 30),
                                    textAlign: 'center'

                                }, title: "Clicks", field: "CLICKS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                            },
                            {
                                cellStyle: {
                                    backgroundColor: lightenHexColor(props.themeColor, 30),
                                    textAlign: 'center'

                                }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100)} displayType="text" suffix="%" />
                            },
                            {
                                cellStyle: {
                                    backgroundColor: lightenHexColor(props.themeColor, 60),
                                    textAlign: "center"

                                },
                                title: "Conversions", field: "CONVERSIONS", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                            },
                            {
                                cellStyle: {
                                    backgroundColor: lightenHexColor(props.themeColor, 60),
                                    textAlign: "center"
                                }, title: "Conv %", field: "ConversionRate", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                            },
                            { title: "Revenue", field: "REVENUE", type: 'currency' },
                            { title: "Cost", field: "COST", type: 'currency' },
                            { title: "CPC", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.COST / rowdata.CLICKS)).toFixed(2)} displayType="text" prefix="$" /> }
                        ]}
                    />  </div>
                : <LinearProgress color="secondary" className={classes.progress}></LinearProgress>)}

        </div>
    );
};

Overview.propTypes = {

};

export default Overview;
