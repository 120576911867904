import React, { useState, useEffect } from "react";

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, AppBar, Toolbar, } from '@material-ui/core';
import appVars from "../appVars.json";
import ClipboardJS from "clipboard";
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import themeFile from '../theme.json';
import GtmMain from "./gtm-main";
import { isMobile } from 'react-device-detect';
import { Code } from "@material-ui/icons";

const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
const drawerWidth = appVars.drawerWidth;


var clientID = getLastItem(window.location.pathname);

const menuItems = [
  ["Code View", "/gtm-install/" + clientID, <Code></Code>],

]


function downloadText(filename, text) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    background: themeFile.palette.appBar.tagmanager,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  logo: {
    maxWidth: appVars.drawerWidth - 20,
    display: (sessionStorage.getItem('sl') === 1 ? 'block' : 'none')
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    position: 'relative',
    top: '60px'
  },
}));

var btn = new ClipboardJS(".copy");
const GtmInstall = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Typography variant="h5" noWrap>
            Google Tag Manager Install Instructions
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.content}><GtmMain></GtmMain></div>



    </div>
  );
};

GtmInstall.propTypes = {

};

export default GtmInstall;
