import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from "@material-ui/core";
import appVars from "../../appVars.json";
import { CloudUpload } from '@material-ui/icons';


const MyInput = ({ field, form, ...props }) => {
    return <TextField {...field} {...props} />;
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    buttons: {
        position: "fixed",
        bottom: "calc(0px + 75px)",
        right: theme.spacing(2)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));



const FileUploads = (props) => {
    const classes = useStyles();
    const [images, setImages] = useState("");
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [openM, setOpenM] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
        setOpenM(false);
        setIsFilePicked(false);
        setSelectedFile("")
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const changeFileHandler = (event) => {

        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);

    };
    return (
        <div className={classes.root}>
            <form className={classes.root} noValidate autoComplete="off" action={appVars.api_url + '/upload'} method="POST">
                <Button className={classes.button} size="small" fullWidth variant="contained" component="label" color="secondary" onClick={handleClickOpen} startIcon={<CloudUpload></CloudUpload>}>Upload Image</Button>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title"><Typography variant='h5'>Image Uploader ID:{props.newid}</Typography></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <input component={MyInput} id="fileToUpload" type="file" onChange={changeFileHandler} color="secondary" />
                            {isFilePicked ? (
                                <div>
                                    <p>Filename: {selectedFile.name}</p>
                                    <p>Filetype: {selectedFile.type}</p>
                                    <p>Size in bytes: {selectedFile.size}</p>
                                    <p>
                                        lastModifiedDate:{' '}
                                        {selectedFile.lastModifiedDate.toLocaleDateString()}
                                    </p>
                                </div>
                            ) : (
                                <p>Select a file to show details</p>
                            )}
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button type="submit" color="primary" variant="contained"
                            onClick={async (e) => {
                                const formData = new FormData();
                                formData.append('filesToUpload', selectedFile);
                                formData.append('pkRecID', props.newid);

                                await fetch(appVars.api_url + "/upload/lightbox", {
                                    method: "POST",
                                    mode: "no-cors",
                                    body: formData,
                                    redirect: 'follow'
                                })
                                .then(response => response.text())
                                .then(result => handleClose())
                                .then(result =>  props.childToParent(result))
                                
                               

                                //images.imageSrc = appVars.api_url + "/imageFiles/" + props.newid + '.' + selectedFile.name.split('.')[1];

                            }}
                        >
                            Upload
                        </Button>
                    </DialogActions>
                </Dialog>
            </form>
        </div>
    );
};

FileUploads.propTypes = {

};

export default FileUploads;
