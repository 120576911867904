import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { style } from './index.scss';
import { Card, CardContent, CardHeader, Grid, CardActions, Button, Typography } from '@material-ui/core';
import appVars from '../../appVars.json';
import ClipboardJS from "clipboard";
import themeFile from '../../theme.json';
import Prism from "prismjs";
import Logo from '../../gtm.png';
import { FileCopy, OpenInBrowser } from "@material-ui/icons";


const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
var drawerWidth = appVars.drawerWidth;
var clientID = getLastItem(window.location.pathname);
var btn = new ClipboardJS(".copy");




const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',

    },
    media: {
        height: 145,
        backgroundPosition: 'top center'
    },
    appBar: {

        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    cardHeader: {
        fontSize: '1.25em',
        backgroundColor: "#cbc9ca",
        minHeight:'80px'
    },
    card: {
        marginTop: theme.spacing(0, 1),
    },
    instructions: {
        padding: '10px'
    },
    details: {
        background: themeFile.palette.appBar.tagmanager
    },
    h5: {
        paddingBottom: theme.spacing(3)
    },
    script: {
        color: 'red'
    },
    contentBG: {
        backgroundColor: '#000',
         minHeight:'135px'
         
    },
    logo: {
        maxWidth: "250px"
    }
}));


const GtmMain = (props) => {
    const classes = useStyles();
    const [hasError, setErrors] = useState(false);
    const [gtm, setGTM] = useState({
        name: 'Loading.....',
        script: ''
    })

    const [script, setScript] = useState({
        script: 'Loading....'
    })






    async function fetchData() {
        const res = await fetch(appVars.api_url + "/tagmanagers/" + clientID);
        res
            .json()
            .then((res) => {
                setGTM(res);
                appVars.gtmScript = appVars.gtmScript.replace('%%gtmid%%', res.tag);
                appVars.gtmImage = appVars.gtmImage.replace('%%gtmid%%', res.tag);
                //  console.log(res);
                setScript(
                    {
                        "script": appVars.gtmScript
                    });
                Prism.highlightAll();
            })
            .catch((err) => setErrors(true));
    }


    useEffect(() => {

        fetchData();


    }, []);


    return (
        <div className={style}>
            <div className={classes.content}>




                <Grid container justifyContent="center" spacing={4}>
                    <Grid item xl={10} style={{marginBottom:'25px'}} >
                        <img src={Logo} alt="" style={{ maxWidth: "250px", marginBottom: "1em", float: "right" }} />

                 
                        <Typography className={classes.instructions} variant="h4">ID: {gtm.tag}</Typography>

                        <Typography className={classes.instructions} variant="body1">Google Tag Manager (GTM) simplifies the process of managing various tracking codes, or "tags," on a website. The GTM container snippet, a concise piece of both JavaScript and non-JavaScript code, is integrated into your web pages. This snippet empowers Tag Manager to trigger specific tags dynamically.</Typography>
                        <Typography className={classes.instructions} variant="body1">By inserting the gtm.js script into the page or utilizing an iframe when JavaScript is unavailable, GTM provides a centralized platform for efficiently deploying and managing tags. These tags may include analytics scripts, conversion tracking, or other snippets essential for tracking user interactions and gathering valuable data.</Typography>
                        <Typography className={classes.instructions} variant="body1">In essence, Google Tag Manager streamlines the deployment and management of diverse tracking codes, offering a more organized and flexible approach to implementing and updating website tags. This not only simplifies the task of tracking various metrics but also enhances the overall efficiency and maintainability of your website's analytics infrastructure.</Typography>


                    </Grid>



                    <Grid item xl={5} >

                        <Typography className={classes.h5} variant="h5">Place this Script on ALL pages of your site AND <span>Donation platform.</span></Typography>
                        <Card className={classes.card}>
                            <CardHeader disableTypography={false} className={classes.cardHeader} subheader="Copy the following JavaScript and paste it as close to the opening <head> tag as possible on every page of your website.">
                            </CardHeader>
                            <CardContent className={classes.contentBG}>
                                <div id="script1" className="language-html">

                                    <code className="language-html">
                                        {script.script}

                                    </code>

                                </div>
                            </CardContent>
                            <CardActions>
                                <Button size="small" fullWidth variant="outlined" endIcon={<FileCopy></FileCopy>} color="primary" className="copy" data-clipboard-target="#script1">
                                    Copy to Clipboard</Button>
                                    <Button fullWidth variant="outlined" size="small" endIcon={<OpenInBrowser></OpenInBrowser>} color="primary" target="_BLANK" href={gtm.cms_link}>
                                    {gtm.cms_name} Instructions</Button>
                                    <Button variant="outlined" fullWidth size="small" endIcon={<OpenInBrowser></OpenInBrowser>} color="primary" target="_BLANK" href={gtm.link}>
                                    {gtm.site} Instructions</Button>
                            </CardActions>
                        </Card>
                    </Grid>

                    <Grid item xl={5} >
                        <Typography className={classes.h5} variant="h5">Place this image on ALL Pages Just after body tag</Typography>
                        <Card className={classes.card}>
                            <CardHeader disableTypography={false} className={classes.cardHeader} subheader="Copy the following snippet and paste it immediately after the opening <body> tag on every page of your website.">
                            </CardHeader>
                            <CardContent className={classes.contentBG}>
                                <div id="script2" >

                                    <code className="language-html">
                                        {appVars.gtmImage}

                                    </code>
                                </div>
                            </CardContent>
                            <CardActions>
                                <Button size="small" fullWidth variant="outlined" endIcon={<FileCopy></FileCopy>} color="primary" className="copy" data-clipboard-target="#script2">
                                    Copy to Clipboard</Button>
                                    <Button variant="outlined" fullWidth size="small" endIcon={<OpenInBrowser></OpenInBrowser>} color="primary" target="_BLANK" href={gtm.cms_link}>
                                    {gtm.cms_name} Instructions</Button>
                                    <Button variant="outlined" fullWidth size="small" endIcon={<OpenInBrowser></OpenInBrowser>} color="primary" target="_BLANK" href={gtm.link}>
                                    {gtm.site} Instructions</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>


            </div>
        </div>
    );
};

GtmMain.propTypes = {

};

export default GtmMain;
