import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import themeFile from '../../theme.json';
import logo from '../../logo.png';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Backdrop } from '@material-ui/core';
import appVars from '../../appVars.json';
import PasswwordReset from '../../email-templates/passwordReset/';
import { renderToString } from 'react-dom/server';
import images from '../../pexels-thisisengineering-3862132.jpg';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


function RenderEmail(props) {
    return (
        renderToString(<PasswwordReset id={props.id} username="Donnie"></PasswwordReset>)
    );
}



function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href={appVars.companyUrl}>
                {appVars.companyName}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}

        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        width: '100vw'
    },
    image: {
        backgroundImage: 'url(' + images + ')',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(10, 8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    heading: {
        margin: "15px",
        fontWeight: "100",
        fontSize: "24px"
    },
    logo: {
        maxWidth: '300px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    rightside: {
        background: themeFile.palette.appBar.backgroundSign
    }
}));




const ResetPassword = (props) => {
    const classes = useStyles();
    const theme = createMuiTheme(themeFile);
    const [error, setErrors] = useState({ show: false, "msg": '' });
    const [open, setOpen] = useState(false);

    async function postData(url, data) {
        // Default options are marked with *
        const response = await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            body: data, // body data type must match "Content-Type" header,
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.json(); // parses JSON response into native JavaScript objects
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setErrors({ show: false, "msg": '' });
    };

    return (
        <div className={classes.root}>
            <ThemeProvider theme={theme}>
                <Grid container component="main" className={classes.root}>
                    <CssBaseline />
                    <Grid item xs={false} sm={4} md={7} xl={7} className={classes.image} />
                    <Grid item xs={12} sm={8} md={5} xl={5} component={Paper} className={classes.rightside} elevation={6} square>
                        <div className={classes.paper}>
                            <img src={logo} className={classes.logo} alt="DigitalWerks Logo" />
                            <div id="form">
                                <Typography className={classes.heading} variant="h3">Get help signing into your account</Typography>
                                <Typography variant="body1">Enter your email address below.</Typography>

                                <form className={classes.form} noValidate>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        autoFocus
                                    />

                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        onClick={() => {
                                            var myHeaders = new Headers();
                                            myHeaders.append("Content-Type", "application/json");
                                            var raw = JSON.stringify({
                                                "email": document.getElementById('email').value
                                            });

                                            var requestOptions = {
                                                method: 'POST',
                                                headers: myHeaders,
                                                body: raw,
                                                redirect: 'follow'
                                            };

                                           // console.log(requestOptions);
                                            fetch(appVars.api_url + "/users/rs/", requestOptions)
                                                .then(response => response.json())
                                                .then((result) => {



                                                    var props = [];
                                                    props.id = result.userid;
                                                    var e = RenderEmail(props); //getFile('../../email-templates/passwordReset/template.html');
                                                    const formData = {
                                                        "sender": appVars.application,
                                                        "to": document.getElementById('email').value,
                                                        "subject": appVars.application + " - Password Reset",
                                                        "message": e

                                                    };

                                                    postData(appVars.api_url + '/send-email', JSON.stringify(formData))
                                                        .then(data => {
                                                            document.getElementById("form").innerHTML = data.status;
                                                            setErrors({ show: true, "msg": "Check your Email for instructions!" });
                                                            setTimeout(() => {
                                                                window.location.href = "/login";
                                                            }, 3500);


                                                        });
                                                })
                                                .catch((error) => {
                                                    setErrors({ show: true, "msg": error });
                                                });

                                            return false;


                                        }}
                                    >
                                        Reset Password
                                    </Button>

                                    <Grid container>
                                        <Grid item>
                                            <Link href="/register" variant="body2">
                                                {"Don't have an account? Sign Up"}
                                            </Link>
                                        </Grid>
                                    </Grid>
                                    <Box mt={5}>
                                        <Copyright />
                                    </Box>
                                </form>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Snackbar open={error.show} autoHideDuration={3000} onClose={handleClose}  >
                    <Alert onClose={handleClose} severity="error">
                        {error.msg}
                    </Alert>
                </Snackbar>

                <Backdrop open={open} className={classes.backdrop}>
                    <CircularProgress size={48} />
                </Backdrop>

            </ThemeProvider>
        </div>
    );
};

ResetPassword.propTypes = {

};

export default ResetPassword;
