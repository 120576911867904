import { Card, CardContent, CardHeader, Grid, Typography, LinearProgress, Toolbar, AppBar, TextField, CircularProgress } from '@material-ui/core';
import { React, useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import themeFile from '../../theme.json';
import ChartCard from '../chart-card';
import { NumericFormat } from 'react-number-format';
import TableCard from '../table-card';
import { lightenHexColor } from '../../dwi-functions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import Comments from '../../system/control/comments';
import appVars from '../../appVars.json';
const theme = createTheme(themeFile);
var CurrencyFormat = require('react-currency-format');

const fontSize = 'initial';


const StackAdapt = (props) => {
    const [creativeData, setCreativeData] = useState([]);
    const [campaignData, setCampaignData] = useState([{ "name": 'props.title' }]);
    const [prefilteredData, setPrefilteredData] = useState();
    const [data, setStackAdaptData] = useState();
    const [customersData, setCustomers] = useState([]);
    const [customerSelect, setCustomerSelect] = useState([]);
    const [typeSelect, setTypeSelect] = useState([]);
    const [accountData, setAccountData] = useState();
    const [types, setTypes] = useState([]);
    const [loading, setLoading] = useState(true);



    const fonts = {
        data: {
            fontSize: '2rem'
        }
    }

    async function fetchReportData() {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "aid": props.advertiser_id,
            "sdate": props.sDate,
            "edate": props.eDate
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const res4 = await fetch("http://localhost:7385/stackadapt/data", requestOptions);
        //    console.log(props);
        res4
            .json()
            .then((res4) => {
                setPrefilteredData(res4[0]);
                setStackAdaptData(res4[0]['data']);
                setAccountData(res4[0]['accounts']);
                setTypes([{ CAMPAIGN_TYPE: 'Native' }, { CAMPAIGN_TYPE: "Video" }, { CAMPAIGN_TYPE: "Audio" }, { CAMPAIGN_TYPE: "CTV" }]);
                setCustomers(res4[0]['accountnames']);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });

        const campTable = await fetch("http://localhost:7385/stackadapt/campaigns", requestOptions);
        //    console.log(props);
        campTable
            .json()
            .then((campaigns) => {
                setCampaignData(campaigns);

            })
            .catch((err) => {
                console.log(err);
            });

        const creativeTable = await fetch("http://localhost:7385/stackadapt/creatives", requestOptions);
        //    console.log(props);
        creativeTable
            .json()
            .then((creatives) => {
                setCreativeData(creatives);

            })
            .catch((err) => {
                console.log(err);
            });




    }

    useEffect(() => {
        // if(!data){
        fetchReportData();
        console.log(data);
        //  }
    }, [props]);





    return (
        <div >
            <ThemeProvider theme={theme}>


             
            {(!loading ?
                    <div>
                        <Grid container spacing={2} alignContent="space-between">
                        <Grid item xl={3} sm={12} xs={12}>
                                <Toolbar style={{ padding: 0 }}>
                                    <Autocomplete
                                        value={customerSelect}
                                        fullWidth
                                        multiple={true}
                                        options={customersData}
                                        getOptionLabel={(option) => option.ADVERTISER || 'Unknown Customer'}
                                        renderInput={(params) => <TextField {...params} label="Choose a Customer" variant='outlined' />}

                                        onChange={(e, newValue) => {
                                            e.stopPropagation();
                                            setCustomerSelect(newValue);
                                            // console.log(newValue[0]['ADVERTISER_ID']);
                                        }}
                                    />
                                </Toolbar>
                            </Grid>
                            <Grid item xl={3} sm={12} xs={12}>
                                <Toolbar style={{ padding: 0 }}>
                                    <Autocomplete
                                        value={typeSelect}
                                        fullWidth
                                        multiple={true}
                                        options={types}
                                        getOptionLabel={(option) => option.CAMPAIGN_TYPE || 'Unknown Type'}
                                        renderInput={(params) => <TextField {...params} label="Choose a Type" variant='outlined' />}
                                        onChange={(e, newValue) => {
                                            setLoading(true);
                                            // e.stopPropagation();
                                            setTypeSelect(newValue);
                                          //  fetchReportData();
                                            
                                        }}
                                    />
                                </Toolbar>



                            </Grid>
                            <Grid item xl={3} sm={12} xs={12}></Grid>
                            <Grid item xl={3} sm={12} xs={12}></Grid>
                            <Grid style={{ display: 'none' }} item sm={6} xs={12}>
                                <Card>
                                    <CardHeader style={{ textAlign: 'center', color: "#fff", backgroundColor: lightenHexColor(props.themeColor, 0) }} title="Platform Expense"></CardHeader>
                                    <CardContent>
                                        <Typography align='center' variant="h4"><NumericFormat value={data.cost.count.toFixed(2)} thousandSeparator="," displayType="text" prefix="$" /></Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid style={{ display: 'none' }} item sm={6} xs={12}>
                                <Card style={{ marginTop: theme.spacing(2) }}>
                                    <CardHeader style={{ textAlign: 'center', color: "#fff", backgroundColor: lightenHexColor(props.themeColor, 0) }} title="Platform ROI"></CardHeader>
                                    <CardContent >
                                        <Typography align='center' variant="h4"><NumericFormat value={((data.revenue.count / data.cost.count) * 100).toFixed(2)} displayType="text" suffix="%" /></Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Impressions"
                                    data={data.impressions}
                                    client={props.client}
                                    color={props.themeColor}
                                />
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Clicks"
                                    data={data.clicks}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="CTR"
                                    data={data.ctr}
                                    client={props.client}
                                    color={props.themeColor}
                                />
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="CPC"
                                    data={data.cpc}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                        </Grid>

                        <Grid container spacing={2} alignContent="space-between">

                            <Grid item xl={3} sm={3} xs={12} >
                                <ChartCard
                                    title="Conversions"
                                    data={data.conversions}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Revenue"
                                    data={data.revenue}
                                    client={props.client}
                                    color={props.themeColor}
                                />


                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Conversion Rate"
                                    data={data.convrate}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="COST"
                                    data={data.cost}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                        </Grid>




                        <Grid container spacing={2} alignContent="space-between">

                            <Grid item sm={12} xs={12} >
                                <TableCard
                                    title="Campaign"
                                    data={campaignData}
                                    count={5}
                                    client={props.client}
                                    color={props.themeColor}
                                    columns={[
                                        { title: "Campaign", field: "CAMPAIGN", render: rowdata => <Typography align='left' style={{ minWidth: 300, fontSize: '13px', padding: 0, margin: 0 }}>{rowdata.CAMPAIGN}</Typography> },
                                        { title: "Impressions", field: "IMPRESSIONS", hidden: false },
                                        {
                                            cellStyle: {
                                                textAlign:'center',
                                                backgroundColor: lightenHexColor(props.themeColor, 30)

                                            }, title: "Clicks", field: "CLICKS", hidden: false
                                        },
                                        {
                                            cellStyle: {
                                                textAlign:'center',
                                                backgroundColor: lightenHexColor(props.themeColor, 30),

                                            }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS)).toPrecision(2)} displayType="text" suffix="%" />
                                        },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 60),
                                                textAlign: "center"

                                            },
                                            title: "Conversions", field: "CONVERSIONS", hidden: false
                                        },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 60),
                                                textAlign: "center"
                                            }, title: "Conv %", field: "CONV_RATE", render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS)).toPrecision(2)} displayType="text" suffix="%" />
                                        },
                                        { title: "Revenue", field: "REVENUE", type: 'currency' },
                                        { title: "Cost", field: "COST", type: 'currency' },
                                        { title: "CPC", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.COST / rowdata.CLICKS)).toFixed(2)} displayType="text" prefix="$" /> }
                                    ]}
                                />


                            </Grid>



                            <Grid item sm={12} xs={12} >
                                <TableCard
                                    title="Ad Group"
                                    data={creativeData}
                                    count={5}
                                    client={props.client}
                                    color={props.themeColor}
                                    columns={[
                                        { title: "Ad Group", field: "CREATIVE", render: rowdata => <Typography align='left' style={{ minWidth: 200, fontSize: '13px', padding: 0, margin: 0 }}>{rowdata.CREATIVE}</Typography> },
                                        { title: "Impressions", field: "IMPRESSIONS", hidden: false },
                                        {
                                            cellStyle: {
                                                textAlign:'center',
                                                backgroundColor: lightenHexColor(props.themeColor, 30)

                                            }, title: "Clicks", field: "CLICKS", hidden: false
                                        },
                                        {
                                            cellStyle: {
                                                textAlign:'center',
                                                backgroundColor: lightenHexColor(props.themeColor, 30)

                                            }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS)).toPrecision(2)} displayType="text" suffix="%" />
                                        },
                                        {
                                            cellStyle: {
                                                textAlign:'center',
                                                backgroundColor: lightenHexColor(props.themeColor, 60)

                                            }, title: "Conversions", field: "CONVERSIONS", hidden: false
                                        },
                                        {
                                            cellStyle: {
                                                textAlign:'center',
                                                backgroundColor: lightenHexColor(props.themeColor, 60)

                                            }, title: "Conv Rate", field: "CONV_RATE", render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS)).toPrecision(2)} displayType="text" suffix="%" />
                                        },
                                        { title: "Revenue", field: "REVENUE", type: 'currency' },
                                        { title: "Cost", field: "COST", type: 'currency' },
                                        { title: "CPC", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.COST / rowdata.CLICKS)).toFixed(2)} displayType="text" prefix="$" /> }
                                    ]}
                                />


                            </Grid>


                        </Grid>


                    </div>
                    : <LinearProgress style={{ margin: theme.spacing(8) }}></LinearProgress>)}


            </ThemeProvider>




        </div>
    );
};

StackAdapt.propTypes = {

};

export default StackAdapt;
