import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { Grid, TextareaAutosize, Typography } from '@material-ui/core';
import { NumericFormat } from 'react-number-format';
import { Button,Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import _ from 'lodash';
import appVars from '../../../appVars.json';
const fontSize = 'initial';



const Comments = (props) => {
    //  console.log(props);
    const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
    var id = getLastItem(window.location.href);

    const [comments, setComments] = useState();
    const [comment, setComment] = useState({});
    const [open, setOpen] = useState(false);



    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {



        var k = {
            comment: document.getElementById('comment').value,
            type: props.type,
            owner: id
        }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "clientid": id,
            "comment": k,
            "createdAt": props.sdate
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(appVars.api_url + "/comments", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));

        setComment(k);
        //console.log(raw);



    };

    const handleChange = (e) => {





        var k = {
            comment: document.getElementById('comment').value,
            type: props.type,
            owner: id
        }
        setComment(k);
        setOpen(false);
    };





    async function fetchData(idi) {
        
        var raw = JSON.stringify({
            "idi":idi,
            "sdate": props.sdate,
            "edate": props.edate,
        });

        
        var requestOptions = {
            method: 'POST',
            body: raw,
            redirect: 'follow'
        };

        const res = await fetch(appVars.api_url + "/comments/get", requestOptions)
        res.json()
            .then(response => {

                let filteredData = _.filter(response, row => _.includes(row.comment, props.type));

                setComments(filteredData);
            })
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }



    useEffect(() => {
        fetchData((props.id ? props.id : id));

        //  console.log(comments)
    }, [props]);



    return (
        <div >

            <Grid container style={{margin:'1em auto'}}>
                <Grid item sm={12}>
                    <MaterialTable
                      actions={[
                        {
                          icon: 'add',
                          tooltip: 'Add Comment',
                          isFreeAction: true,
                          onClick: (event, rowData) => {
                          setOpen(true);
                          }
                        }
                      ]}
                    
                        data={comments}
                        title={'Notes'}
                        columns={[
                            { title: "Note", field: "comment", render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 400, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.comment.comment}</Typography> },
                            { title: "Date", field: "createdAt", type: 'datetime' },
                        ]}
                    /></Grid>

            </Grid>











            <Dialog style={{ minWidth: 1600 }} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add Comment</DialogTitle>
                <DialogContent>
                    <TextareaAutosize
                        margin="dense"
                        id="comment"
                        name="comment"
                        label="Comment"
                        minRows={20}

                        onChange={handleChange}


                    ></TextareaAutosize>



                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>




        </div>
    );
};

Comments.propTypes = {

};

export default Comments;
