import React, { useState, useEffect } from "react";

import { makeStyles, useTheme } from '@material-ui/core/styles';
import {  Typography,  AppBar, Toolbar, Drawer,  Box, Tab, Tabs } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import appVars from "../appVars.json";
import ClipboardJS from "clipboard";
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import themeFile from '../theme.json';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import GtmMain from "../gtm-install/gtm-main";
import FBmain from "../fb-install/gtm-main";
import SAmain from "../SA-install/gtm-main";
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
const drawerWidth = appVars.drawerWidth;



var clientID = getLastItem(window.location.pathname);

const menuItems = [


]




function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    minHeight: "64px",
    background: themeFile.palette.appBar.tagmanager,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  logo: {
    maxWidth: appVars.drawerWidth - 20,
    display: (sessionStorage.getItem('sl') === 1 ? 'block' : 'none')
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    position: 'relative',
    top: '60px'
  },
}));

var btn = new ClipboardJS(".copy");


const TagInstall = (props) => {


  const [value, setValue] = React.useState(0);



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const [gtm, setGTM] = useState({
    name: 'Loading.....'
  })
  const classes = useStyles();

  const [copySuccessShow, setCopySuccessShow] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [open, setOpen] = React.useState(true);





  const handleDrawerOpen = () => {
    setOpen(true);
  };

 
  async function fetchData() {
    const res = await fetch(appVars.api_url + "/tagmanagers/" + clientID);
    res
      .json()
      .then((res) => {
        setGTM(res);
        //console.log(res);
      })
      .catch((err) => setErrors(true));
  }



  useEffect(() => {

    fetchData();



    var parts = window.location.href.split('#');
    if (parts.length > 1) {


      switch (parts[1]) {
        case "meta":
          setValue(1);
          break;
        case "sa":
          setValue(2);
          break;
        case "gtm":
          setValue(0);
          break;
        default:
          break;
      }

      console.log(parts[1]);
    }

    menuItems.push(["Google Tag Manager", 0]);

    menuItems.push(["Meta Pixel", 1]);

    menuItems.push(["Stack Adapt Pixel", 2]);

    setGTM(props);
    if (isMobile) {
      setOpen(false);
    }

  }, []);



  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar
        color="primary"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5"> {gtm.name}</Typography>
        </Toolbar>

      </AppBar>




      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>

          <img alt={gtm.name} className={classes.logo} src={gtm.logo} />
        </div>
        <Divider />


        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" indicatorColor="secondary" orientation="vertical"
          variant="fullWidth">
          {menuItems.map((text, index) => (
            <Tab label={text[0]} {...a11yProps(text[1])} />
          ))}
        </Tabs>



      </Drawer>
      <div className={classes.content}>
        <TabPanel value={value} index={0}>
          <GtmMain></GtmMain>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <FBmain></FBmain>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <SAmain></SAmain>
        </TabPanel>



      </div>



    </div>
  );
};

TagInstall.propTypes = {

};

export default TagInstall;
