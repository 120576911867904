import React, { useState, useEffect } from 'react';
import { style } from './index.scss';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { Card, CardHeader, CardActions, CardActionArea, CardMedia, CardContent, Grid, Button, Dialog, DialogActions, DialogContent, Divider, DialogTitle, TextField, MenuItem, Breadcrumbs, Link, Typography, AppBar, Toolbar, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import appVars from '../appVars.json';
import { RiUser2Fill } from 'react-icons/ri';
import { useTheme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { Formik, Field, Form } from 'formik';
import { Edit, SaveOutlined } from "@material-ui/icons";
import FileUploads from '../settings/file-uploads';


const md5 = require('md5');


const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  table: {
    marginTop: '1em'
  },
  title: {
    borderBottom: '1px solid #000',
  },
  pos: {
    marginBottom: 12,
  },
  media: {
    height: 50,
    backgroundPosition: 'top top',
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    padding: theme.spacing(0, 2),
   
  },
  inputs: {
    marginTop: theme.spacing(2)
  }
}));







const UserTable = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const [users, setUsers] = useState(false);
  const [loading, setLoading] = useState([true]);
  const [open, setOpen] = useState(false);
  const [edit, setEditOpen] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [owner, setOwner] = useState('Not Set');
  const [message, setMessage] = useState({ show: false, message: '' });

  const [clientTypes, setClientType] = useState(
    [{
      value: 1,
      label: 'Not Set'
    }]
  );

  const childToParent = (childdata) => {
    var f = appVars.api_url + '/imageFiles/' + editUser.pkRecId + '.png';
    const img = document.getElementById('avatar');
    img.src = f; console.log(f);
  };



  const MyInput = ({ field, form, ...props }) => {
    return <TextField variant="outlined" shrink {...field} {...props} />;
  };

  const MySelectClients = ({ field, form, ...props }) => {
    return <TextField variant='outlined' shrink size="small" select
      onChange={(e) => {
        setOwner(e.target.value);
        //  console.log(e.target.value);
      }}
      {...field} {...props}
    >

      {
        clientTypes.map((entry) => (
          <MenuItem key={entry.clientId} value={entry.clientId}>{entry.clientname}</MenuItem>

        ))}

    </TextField>;
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setEditOpen(false);
    setMessage({ show: false, message: '', severity: 'success' });
  };


  const handleEdit = (e) => {
    console.log(JSON.parse(e.currentTarget.getAttribute('user')));
    setEditUser(JSON.parse(e.currentTarget.getAttribute('user')));
    setEditOpen(true);
  }


  async function fetchData() {

    var url = (props.clientid === undefined ? appVars.api_url + "/users" : appVars.api_url + "/users/c/" + props.clientid);
    const res = await fetch(url);
    res
      .json()
      .then(res => setUsers(res))
      .then(res => setLoading(false))
      .catch(err => setMessage({ show: true, message: err, severity: 'error' }));



    url = appVars.api_url + "/clients";
    const res2 = await fetch(url);
    res2
      .json()
      .then((res2) => {
        setClientType(res2);
        //console.log(v);
      })
      .catch((err) => {
        setMessage({ show: true, message: err, severity: 'error' });
        //console.log(err);
      });




  }


  useEffect(() => {
    if (!users) {
      fetchData();
    }

  });


  return (
    <div className={style}>


      <AppBar color="secondary"  position="static">

        <Toolbar>
          <Breadcrumbs style={{ width: '100%' }} aria-label="breadcrumb">
            <Link color="textPrimary" href="/" >
              Dashboard
            </Link>
            <Link color="textPrimary" href={"/control/"} >
              Settings
            </Link>
            <Typography color="primary" variant="subtitle1" style={{color:"#fff"}}  >Users</Typography>
          </Breadcrumbs>
        </Toolbar>
      </AppBar>


      <Grid container md={12} style={{ padding: theme.spacing(1) }}>
        <Grid item xl={1} md={2}>
          <Button size="small" fullWidth variant="outlined" color='primary' startIcon={<RiUser2Fill></RiUser2Fill>} onClick={(e) => {
            setOpen(true);
          }} >Add User</Button>
        </Grid>

      </Grid>
      <Divider style={{ marginBottom: theme.spacing(1), marginLeft: theme.spacing(1), marginRight: theme.spacing(1) }}
      ></Divider>




      <Grid container spacing={2} xl={12} style={{ paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) }}>

        {loading ? (



          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((card, index) => (
            <Grid item xl={2} lg={3} md={4} sm={6} xs={12} key={index}>
              <Card>
                <CardHeader
                  title={<Skeleton animation="wave" width="40%" component="h5" style={{ marginBottom: 6 }} />}
                >

                </CardHeader>
                <CardMedia>
                  <Skeleton animation="wave" variant="rect" />
                </CardMedia>
                <CardContent>
                  <Skeleton animation="wave" component="h1" width="65%" style={{ marginBottom: 6 }} />
                  <Skeleton animation="wave" style={{ marginBottom: 6 }} />
                  <Skeleton animation="wave" style={{ marginBottom: 6 }} />
                </CardContent>
              </Card>
            </Grid>
          ))

        ) : (

          <Grid container spacing={2} xl={12} style={{ paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1), paddingTop: theme.spacing(1) }}>
            {users.map((card, index) => (
              <Grid item xl={2} lg={3} md={4} sm={6} xs={12} key={index}>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar src={appVars.api_url + '/imageFiles/' + card.pkRecId + '.png'} aria-label="recipe" className={classes.avatar} />
                    }
                    title={<div>
                      <Typography variant="body2" color="textPrimary" component="p">{card.name}</Typography>

                    </div>} />
                  <CardActionArea className={classes.media}>
                    <Typography variant="body2" color="primary" component="p"><Link href={'mailto:' + card.email}>{card.email}</Link></Typography>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" user={JSON.stringify(card)} onClick={handleEdit}
                      variant="outlined" startIcon={<Edit></Edit>} color="secondary">Edit</Button>
                  </CardActions>


                </Card>
              </Grid>
            ))}
          </Grid>


        )}
      </Grid>








      <Dialog
        maxWidth="sm"
        aria-labelledby="confirmation-dialog-title"
        open={open}
      >
        <DialogTitle id="confirmation-dialog-title">Add New User</DialogTitle>
        <DialogContent dividers>

          <Grid container md={12} spacing={3}>



            <Grid item md={6} >

              <TextField
                autoFocus
                size='small'
                margin="dense"
                id="name"
                label="Name"
                type="text"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />


            </Grid>

            <Grid item md={6}>
              <TextField
                autoFocus
                size='small'
                margin="dense"
                id="email"
                label="Email Address"
                type="email"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={12}>


              <TextField
                select
                componem
                margin="dense"
                id="clientid"
                label="Select Client"
                type="password"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
              >
                {
                  clientTypes.map((entry) => (
                    <MenuItem key={entry.clientId} value={entry.clientId}>{entry.clientname}</MenuItem>

                  ))}

              </TextField>
            </Grid>
            <Grid item md={6}>


              <TextField
                margin="dense"
                id="passwd"
                label="Password"
                type="text"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>


            <Grid item md={6}>


              <TextField
                margin="dense"
                id="confirm"
                label="Confirm Password"
                type="text"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

          </Grid>





        </DialogContent>
        <DialogActions>
          <Button size="small" variant="outlined" color="primary" onClick={(e) => {
            setOpen(false);
          }} autoFocus >
            Cancel
          </Button>
          <Button size="small" startIcon={<RiUser2Fill></RiUser2Fill>} onClick={(e) => {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
              "name": document.getElementById('name').value,
              "email": document.getElementById('email').value,
              "passwd": document.getElementById('passwd').value,
              "w3login": 1,
              "pkRecId": "",
              "clientid": document.getElementById("clientid").value
            });

            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            };


            fetch(appVars.api_url2 + "/users/add", requestOptions)
              .then(response => response.text())
              .then((result) => {
                var c = JSON.parse(result);
                if (c.status === 'error') {
                  setMessage({ show: true, message: c.data, severity: 'error' });
                  return;
                }
                fetchData();
                setMessage({ show: true, message: 'User ' + c.status, severity: 'success' });
                setOpen(false);
              })
              .catch(error => console.log('error', error));


          }} color="secondary" variant="contained">
            Add User
          </Button>
        </DialogActions>
      </Dialog>



      <Dialog
        maxWidth="sm"
        aria-labelledby="confirmation-dialog-title"
        open={edit}
      >
        <DialogTitle id="confirmation-dialog-title">Edit - {editUser.name}</DialogTitle>
        <DialogContent dividers>
          <Formik
            enableReinitialize
            initialValues={editUser}
            onSubmit={async (values) => {
              var url = appVars.api_url + "/gtm/update/";
              if (values.pkRecId === "0") {
                url = appVars.api_url + "/gtm/add/";
                //url = "http://localhost:7325/gtm/add/";
              }   //var url = "http://localhost:7325/gtm/add/";

              console.log(JSON.stringify(values));
              /*
              const response = fetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                body: JSON.stringify(values), // body data type must match "Content-Type" header,
                headers: {
                  'Content-Type': 'application/json'
                }
              });
              response
                .then(users => {
                  // window.location.href='/clientview/'+clientData.client;
              
                  ////console.log(users.body);
                  fetchData();
                }).err(err => {
      
                })

              */


            }}>
            <Form id="userTable">
              <Grid container md={12} spacing={3} >
                <Grid item md={6} >
                  <Field size="small" id="name" name="name" placeholder="Display Name" label="Display Name" fullWidth component={MyInput} className={classes.inputs} helperText="Enter Display Name" />
                  <Field size="small" id="email" name="email" placeholder="Email Address" label="Email Address" fullWidth component={MyInput} className={classes.inputs} helperText="Enter Email Address" />
                  <Field size="small" id="passwd" name="passwd" placeholder="" label="Enter New Password" type="password" fullWidth component={MyInput} className={classes.inputs} helperText="Enter Password" />
                  <Field size="small" id="confirm" name="confirm" placeholder="" label="confirm New Password" type="password" fullWidth component={MyInput} className={classes.inputs} helperText="Re-Enter Password" />
                </Grid>
                <Grid item md={6} style={{ textAlign: "center", maxHeight: '200px' }} >
                  <img id="avatar" style={{ width: '100%', paddingTop: '0px' }} alt={editUser.name} title={editUser.name} onError={(e) => {
                    e.src = 'https://www.gravatar.com/avatar/' + md5(editUser.email);
                  }} src={appVars.api_url + '/imageFiles/' + editUser.pkRecId + '.png'} />
                  <FileUploads newid={editUser.pkRecId} childToParent={childToParent} ></FileUploads>
                </Grid>

                <Grid item md={12}>
                  <Field size="small" id="clientid" name="clientid" placeholder="" label="Assigned Client" fullWidth component={MySelectClients} className={classes.inputs} helperText="Enter Assigned Client" />
                </Grid>
                <Grid item md={6}>


                </Grid>
                <Grid item md={6}>

                </Grid>

                <Grid item md={6}>


                </Grid>

              </Grid>




            </Form>
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="outlined" color="primary" onClick={(e) => {
            setEditOpen(false);
          }} autoFocus >
            Cancel
          </Button>
          <Button className={classes.actionButton} startIcon={<SaveOutlined></SaveOutlined>} color="secondary" variant="contained" size="small" type="submit">
            Save
          </Button>
        </DialogActions>
      </Dialog>













      <Snackbar open={message.show} autoHideDuration={3000} onClose={handleClose}  >
        <Alert onClose={handleClose} severity={message.severity}>
          {message.message}
        </Alert>
      </Snackbar>

    </div>
  );
};

UserTable.propTypes = {

};

export default UserTable;
