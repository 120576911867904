import React, { useEffect } from 'react';
import { Avatar, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import appVars from '../../appVars.json';
import Card from '@material-ui/core/Card';
import { CardActionArea, CardContent, CardHeader, CardMedia, Link, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    content: {
        padding: theme.spacing(1),
    },
    media: {
        height: 'auto',
        maxWidth: 100,
        fontSize:12,
        padding: theme.spacing(0),
        filter: 'brightness(0) invert(1)'
    },
    pad: {
        padding: 1
    },
    logoArea: {
        background: theme.palette.primary.main
    }
}));

const PrimaryClient = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [clients, setClients] = useState();
    const [isLoading, setisLoading] = useState(true);



    async function fetchData() {

        fetch(appVars.api_url + '/clients')
            .then((response) => response.json())
            .then((res) => {
                setClients(res);
                console.log(clients);
                setisLoading(false);
            }).catch((err) => console.log(err))
    }




    useEffect(() => {
        fetchData();

    }, []);



    return (
        <div className={classes.content}>
            <Typography variant="h5" color='primary' style={{ borderBottom: '1px solid', borderBottomColor: theme.palette.primary.main }} gutterBottom>Agencies</Typography>
            <Grid container spacing={1}>
                {(isLoading === false ?
                    Object.keys(clients).map((k, i, all_items) => {
                        return (

                            <Grid xl={4} md={6} xs={12} item>
                                <Card variant="outlined" className={classes.root}>
                                <CardActionArea href={'/clientview/' + clients[k].clientId}>
                                    <CardHeader
                                    className={classes.logoArea}
                                    title={<img className={classes.media} src={appVars.api_url + '/imageFiles/' + clients[k].clientId + '.png'} alt={clients[k].clientname} ></img>}
                                  
                                    />
                                    </CardActionArea>
                                    <CardContent style={{ padding: theme.spacing(1) }}>
                                        <Grid container xl={12}>
                                            <Grid item xl={1}>
                                                <Typography variant="body2"><Link color="primary" href={'/clientview/' + clients[k].clientId}>Clients: {clients[k].clientCount}</Link></Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    })
                    :
                    [1, 2, 3, 4].map((card, index) => (
                        <Grid item xl={3} lg={3} md={4} sm={6} xs={12} key={index}>
                            <Card>
                                <CardHeader
                                    title={<Skeleton animation="wave" width="40%" component="h5" style={{ marginBottom: 6 }} />}
                                >

                                </CardHeader>
                                <CardMedia>
                                    <Skeleton animation="wave" variant="rect" />
                                </CardMedia>
                                <CardContent>
                                    <Skeleton animation="wave" component="h1" width="65%" style={{ marginBottom: 6 }} />
                                    <Skeleton animation="wave" style={{ marginBottom: 6 }} />
                                    <Skeleton animation="wave" style={{ marginBottom: 6 }} />
                                </CardContent>
                            </Card>
                        </Grid>
                    ))

                )


                }
            </Grid>






        </div>
    );
};

PrimaryClient.propTypes = {

};

export default PrimaryClient;
