import React, { useState, useEffect } from "react";
import { style } from "./index.scss";
import MaterialTable from "material-table";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Typography,
  Divider,
  Grid,
  Button,
  Paper,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Link from "@material-ui/core/Link";
import ClipboardJS from "clipboard";
import appVars from '../appVars.json';


const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  table: {
    marginTop: "1em",
  },
  title: {
    borderBottom: "1px solid #000",
  },
  pos: {
    marginBottom: 12,
  },
});

var btn = new ClipboardJS(".copy");

const TagManagers = (props) => {
  const classes = useStyles();
  const [hasError, setErrors] = useState(false);
  const [copySuccessShow, setCopySuccessShow] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState([true]);
  const [copySuccess, setCopySuccess] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrors(false);
    setCopySuccessShow(false);
  };

  async function fetchData() {
    const res = await fetch(appVars.api_url+"/tagmanager_detail/"+props.client);
    res
      .json()
      .then((res) => setUsers(res))
      .then((res) => setLoading(false))
      .catch((err) => setErrors(true));
  }

  useEffect(() => {
    fetchData();

      var clipboard = new ClipboardJS(".copy");
      clipboard.on("success", function (e) {
      setCopySuccess(e.action + ": " + e.text);
      setCopySuccessShow(true);
      e.clearSelection();
    });
  }, []);

  return (
    <div className={style}>
      <div className={classes.table}>
        <MaterialTable
          isLoading={loading}
          columns={[
            { title: "pkRecId", field: "pkRecId", hidden: true },
            { title: "siteUrl", field: "siteUrl", hidden: true },
            {
              title: "Client Name",
              field: "name",
              render: (rowData) => (
                <Link target="_BLANK" href={rowData.siteUrl}>
                  {rowData.name}
                </Link>
              ),
            },
            {
              title: "GTM ID",
              field: "tag",
              render: (rowData) => (
                <Button
                  variant="outlined"
                  color="secondary"
                  class="copy"
                  data-clipboard-text={rowData.tag}
                >
                  {rowData.tag}
                </Button>
              ),
            },
           
            { title: "Site Type", field: "site" },
            {
              title: "Instructions",
              field: "site",
              render: (rowData) => (
                <Button
                  variant="outlined"
                  color="secondary"
                  class="copy"
                  data-clipboard-text={
                    appVars.companyUrl+"gtm-install/" +
                    rowData.id
                  }
                >
                  Copy Instructions
                </Button>
              ),
            },
          ]}
          data={users}
          title="Google Tag Managers"
          options={{
            grouping: true,
            pageSize: 10,
            pageSizeOptions: [10, 25, 50, 75,100,200]
          }}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  /* setData([...data, newData]); */
                  resolve();
                }, 1000);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  resolve();
                }, 1000);
              }),
          }}
          detailPanel={(rowData) => {
            return (
              <iframe
                width="100%"
                height="908px"
                src={
                  "https://api.digitalwerksinnovations.com/gtm/google-tag-manager.php?VERIFY=1&id=" +
                  rowData.id
                }
                frameborder="0"
              />
            );
          }}
        />
      </div>
      <Snackbar open={hasError} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          The data can not be pulled at this time.
          <Button color="primary" onClick={window.location.reload}>
            Refresh
          </Button>
        </Alert>
      </Snackbar>

      <Snackbar
        open={copySuccessShow}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {copySuccess}
        </Alert>
      </Snackbar>
    </div>
  );
};

TagManagers.propTypes = {};

export default TagManagers;
