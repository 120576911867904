import React from 'react';
import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import { PieChart, Pie, Legend, Tooltip, Cell, ResponsiveContainer } from 'recharts';
import { lightenHexColor } from '../../dwi-functions';


const PieChartCard = (props) => {

   // console.log(props);


    return (
        <div>
            <Card>
                <CardHeader style={{ textAlign: 'center', color: "#fff", backgroundColor: lightenHexColor(props.color, 0) }} title={props.title}></CardHeader>
                <CardContent>
                    <ResponsiveContainer width="100%" height={300}>
                        <PieChart >
                            <Legend layout="vertical" verticalAlign="middle" align='right' />
                                   <Pie data={props.data} dataKey="count" outerRadius={100} >
                                {props.data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={lightenHexColor(props.color, Math.floor(Math.random() * (41) + 10))} />
                                ))}
                            </Pie>

                            <Tooltip />
                        </PieChart>
                    </ResponsiveContainer>
                </CardContent>
            </Card>
        </div>
    );
};

PieChartCard.propTypes = {

};

export default PieChartCard;
