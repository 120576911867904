import React, { createContext, useContext, useState, useEffect } from 'react';
import { getCookie } from '../../dwi-functions';

const TokenContext = createContext();

export function TokenProvider({ children }) {
    const [token, setToken] = useState(0);

    useEffect(() => {
        // Retrieve the token from storage when the app loads
        const storedToken = {
            user: getCookie('_au') ,
            token: Number(getCookie('_auta'))
        };

        if (storedToken) {
            setToken(storedToken);
        }

    }, []);

    return (
        <TokenContext.Provider value={{ token, setToken }}>
            {children}
        </TokenContext.Provider>
    );
}

// Custom hook to use the token context
export function useToken() {
    return useContext(TokenContext);
}
