import React, { useState, useEffect } from 'react';
import { Grid,Button, Typography } from '@material-ui/core';
import { style } from './index.scss';
import appVars from '../../appVars.json';
import MaterialTable from "material-table";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import _ from 'lodash';
import { alpha } from '@material-ui/core/styles';

const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
const id = getLastItem(window.location.href);


const tableOptions = {
    grouping: true,
    pageSize: 100,
    exportButton: true,
    pageSizeOptions: [10, 25, 50, 75, 100],
    showTitle: false,
    rowStyle: x => {
        if (x.tableData.id % 2) {
            return {backgroundColor: "#f2f2f2"}
        }
    }
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100vw",
    },
    table: {
        marginTop: "1em",
    },
    title: {
        borderBottom: "1px solid #000",
    },
    pos: {
        marginBottom: 12,
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        left: theme.spacing(2),
        zIndex: 9999
    },
}));

const Clientaudit = (props) => {
    const [client, setClientUsers] = useState([]);
    const [loading, setLoading] = useState([true]);
    const classes = useStyles();
    const [hasError, setErrors] = useState(false);

 

async function getSiteType(id,siteType='website'){
   var url="";

    if(siteType!=='website'){
        url = appVars.api_url + "/sites/donation";
    }else{
       url = appVars.api_url + "/sites/website";
    }

    const res3 = await fetch(url);
    res3
      .json()
      .then((res3) => {
       //setDonationType(res3);
        var k = _.find(res3, function(o) { return o.pkRecID === id; });
        
        //console.log(k.site);
        return k.site;
        //console.log(v);
      }).catch((err) => {
        setErrors(true);
        console.log(err);
      });

}



    async function fetchData() {
        const res = await fetch(appVars.api_url + "/tagmanager_detail/" + id);
        res
            .json()
            .then((res) => {
                setClientUsers(res);
                setLoading(false);
                //console.log(res);
            }).catch((err) => setErrors(true));

    }

    useEffect(() => {
        fetchData();
        console.log(client);
    }, []);








    return (
        <div className={classes.root}>

            <Grid container spacing={2} >
            <Grid item lg={12}>
                <MaterialTable
                data={client}
                title= {"Client Audit"}
                isLoading={loading}
                options={tableOptions}
                columns={[
                    { title: "id", field: "id", hidden: true },
                    { title: "", field: "clientname", hidden: true },
                    { title: "Client", field: "name" },
                    { title: "Registrar", field: "registrar" },
                    { title: "Website", field: "sitetype", render: rowdata=> <Typography variant="body">{rowdata.sitetype}</Typography> },
                    { title: "Donation Platform", field: "donationtype" , render: rowdata=> <Typography variant="body">{rowdata.donationtype}</Typography> },
                    { title: "GTM", field: "tag" },
                    { title: "MeasuremetnID", field: "measurementID" },
                    { title: "Google Analytics", field: "ua" },
                    { title: "Ecommerce?", field: "ecommerce" },
                    { title: "Facebook Pixel", field: "fbpixel" },
                    { title: "Adwords ID (grant)", field: "gaGrantId" },
                    { title: "Adwords Label (grant)", field: "gaGrantLabel" },
                    { title: "Adwords ID (paid)", field: "gaPaidId" },
                    { title: "Adwords Label (paid)", field: "gaPaidLabel" },
                    { title: "Bing ID", field: "bingId" },
                    { title: "StackAdapt Marketing", field: "stackadaptMarketing" },
                    { title: "StackAdpat Conversion", field: "stackadaptConversion" },

                  
                ]}



            />
                </Grid>
            </Grid>

        </div>
    );
};

Clientaudit.propTypes = {

};

export default Clientaudit;
