import { Card, CardContent, CardHeader, Grid, Typography, LinearProgress } from '@material-ui/core';
import { React, useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import themeFile from '../../theme.json';
import ChartCard from '../chart-card';
import { NumericFormat } from 'react-number-format';
import TableCard from '../table-card';
import { lightenHexColor } from '../../dwi-functions';
import _ from 'lodash';

const theme = createTheme(themeFile);
var CurrencyFormat = require('react-currency-format');



const fontSize = 'initial';




const Bing = (props) => {
    const [creativeData, setCreativeData] = useState([]);
    const [topConversionData, setTopConversionData] = useState([]);
    const [keywordData, setKeywordData] = useState([]);
    const [regionData, setRegionData] = useState([]);
    const [campaignData, setCampaignData] = useState([{ "name": 'props.title' }]);
    const [data, setData] = useState();

    async function fetchReportData() {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "aid": props.advertiser_id,
            "sdate": props.sDate,
            "edate": props.eDate
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const res4 = await fetch("http://localhost:7385/bing/data", requestOptions);
        //    console.log(props);
        res4
            .json()
            .then((res4) => {
                setData(res4);

            })
            .catch((err) => {
                console.log(err);
            });

        const campTable = await fetch("http://localhost:7385/bing/campaigns", requestOptions);
        //    console.log(props);
        campTable
            .json()
            .then((campaigns) => {
                setCampaignData(campaigns);
                console.log(campaigns);
            })
            .catch((err) => {
                console.log(err);
            });
        /*
                const creativeTable = await fetch("http://localhost:7385/bing/creatives", requestOptions);
                //    console.log(props);
                creativeTable
                    .json()
                    .then((creatives) => {
                        setCreativeData(creatives);
        
                    })
                    .catch((err) => {
                        console.log(err);
                    });
        */


        const keywordTable = await fetch("http://localhost:7385/bing/keyword", requestOptions);
        keywordTable
            .json()
            .then((keyword) => {


                var sd = _(keyword) // Filter out items where CONVERSIONS is 0
                    .groupBy('SEARCH')
                    .map((groupedData, key) => ({ SEARCH: key, CONVERSIONS: _.sumBy(groupedData, 'CONVERSIONS'), IMPRESSIONS: _.sumBy(groupedData, 'IMPRESSIONS'), CLICKS: _.sumBy(groupedData, 'CLICKS'), REVENUE: _.sumBy(groupedData, 'REVENUE'), COST: _.sumBy(groupedData, 'COST') }))
                    .value();
                setKeywordData(sd);

                var cv = _(keyword).filter(item => item.CONVERSIONS !== 0) // Filter out items where CONVERSIONS is 0
                    .groupBy('SEARCH')
                    .map((groupedData, key) => ({ SEARCH: key, CONVERSIONS: _.sumBy(groupedData, 'CONVERSIONS'), CLICKS: _.sumBy(groupedData, 'CLICKS') }))
                    .value();
                setTopConversionData(cv);
            })
            .catch((err) => {
                console.log(err);
            });


            const regionTable = await fetch("http://localhost:7385/bing/region", requestOptions);
            regionTable
                .json()
                .then((region) => {
                       setRegionData(region);
                   })
                .catch((err) => {
                    console.log(err);
                });




    }

    useEffect(() => {
        // if(!data){
        fetchReportData();
        // console.log(data);
        //  }
    }, [props]);




    return (
        <div >
            <ThemeProvider theme={theme}>


                {(data ?
                    <div>
                        <Grid container spacing={2} alignContent="space-between">

                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Impressions"
                                    data={data.impressions}
                                    client={props.client}
                                    color={props.themeColor}
                                />
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Clicks"
                                    data={data.clicks}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="CTR"
                                    data={data.ctr}
                                    client={props.client}
                                    color={props.themeColor}
                                />
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="CPC"
                                    data={data.cpc}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                        </Grid>

                        <Grid container spacing={2} alignContent="space-between">

                            <Grid item xl={3} sm={3} xs={12} >
                                <ChartCard
                                    title="Conversions"
                                    data={data.conversions}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Revenue"
                                    data={data.revenue}
                                    client={props.client}
                                    color={props.themeColor}
                                />


                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Conversion Rate"
                                    data={data.convrate}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="COST"
                                    data={data.cost}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                        </Grid>




                        <Grid container spacing={2} alignContent="space-between">

                            <Grid item sm={10} xs={12} >
                                <TableCard
                                    title="Campaign"
                                    data={campaignData}
                                    count={5}
                                    client={props.client}
                                    color={props.themeColor}
                                    columns={[
                                        { title: "Campaigns", field: "CAMPAIGN", render: rowdata => <Typography style={{ minWidth: 300, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.CAMPAIGN}</Typography> },
                                        { title: "Impressions", field: "IMPRESSIONS", hidden: false },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 30)

                                            }, title: "Clicks", field: "CLICKS", hidden: false
                                        },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 30)

                                            }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100).toPrecision(2)} displayType="text" suffix="%" />
                                        },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 60),
                                                textAlign: "center"

                                            },
                                            title: "Conversions", field: "CONVERSIONS", hidden: false
                                        },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 60),
                                                textAlign: "center"
                                            }, title: "Conv %", field: "CONV_RATE", render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100).toPrecision(2)} displayType="text" suffix="%" />
                                        },
                                        { title: "Revenue", field: "REVENUE", type: 'currency' },
                                        { title: "Cost", field: "COST", type: 'currency' },
                                        { title: "CPC", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.COST / rowdata.CLICKS)).toFixed(2)} displayType="text" prefix="$" /> }
                                    ]}
                                />




                            </Grid>
                            <Grid item sm={2} xs={12}>
                                <Card>
                                    <CardHeader style={{ textAlign: 'center', color: "#fff", backgroundColor: lightenHexColor(props.themeColor, 0) }} title="Platform Expense"></CardHeader>
                                    <CardContent>
                                        <Typography align='center' variant="h4"><NumericFormat value={data.cost.count.toFixed(2)} thousandSeparator="," displayType="text" prefix="$" /></Typography>
                                    </CardContent>
                                </Card>
                                <Card style={{ marginTop: theme.spacing(2) }}>
                                    <CardHeader style={{ textAlign: 'center', color: "#fff", backgroundColor: lightenHexColor(props.themeColor, 0) }} title="Platform ROI"></CardHeader>
                                    <CardContent >
                                        <Typography align='center' variant="h4"><NumericFormat value={((data.revenue.count / data.cost.count) * 100).toFixed(2)} displayType="text" suffix="%" /></Typography>
                                    </CardContent>
                                </Card>
                            </Grid>


                            <Grid item sm={10} xs={12} >

                                <TableCard
                                    title="Search"
                                    data={keywordData}
                                    count={5}
                                    client={props.client}
                                    color={props.themeColor}
                                    columns={[
                                        { title: "TERM", field: "SEARCH", render: rowdata => <Typography style={{ minWidth: 300, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.SEARCH}</Typography> },
                                        { title: "Impressions", field: "IMPRESSIONS", hidden: false },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 30)

                                            }, title: "Clicks", field: "CLICKS", hidden: false
                                        },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 30)

                                            }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100).toPrecision(2)} displayType="text" suffix="%" />
                                        },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 60),
                                                textAlign: "center"

                                            },
                                            title: "Conversions", field: "CONVERSIONS", hidden: false
                                        },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 60),
                                                textAlign: "center"
                                            }, title: "Conv %", field: "CONV_RATE", render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100).toPrecision(2)} displayType="text" suffix="%" />
                                        },
                                        { title: "Revenue", field: "REVENUE", type: 'currency' },
                                        { title: "Cost", field: "COST", type: 'currency' },
                                        { title: "CPC", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.COST / rowdata.CLICKS)).toFixed(2)} displayType="text" prefix="$" /> }
                                    ]}
                                />

                            </Grid>
                            <Grid item sm={2} xs={12}>

                            </Grid>
                            <Grid item sm={10} xs={12} >


                                <TableCard
                                    title="Top Cities"
                                    data={regionData}
                                    count={5}
                                    client={props.client}
                                    color={props.themeColor}
                                    columns={[
                                        { title: "Top Cities", field: "CITY", render: rowdata => <Typography style={{ minWidth: 200, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.CITY}</Typography> },
                                        { title: "Impressions", field: "IMPRESSIONS", hidden: false },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 30)

                                            }, title: "Clicks", field: "CLICKS", hidden: false
                                        },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 30)

                                            }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100).toPrecision(2)} displayType="text" suffix="%" />
                                        },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 60)

                                            }, title: "Conversions", field: "CONVERSIONS", hidden: false
                                        },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 60)

                                            }, title: "Conv Rate", field: "CONV_RATE", render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100).toPrecision(2)} displayType="text" suffix="%" />
                                        },
                                        { title: "Revenue", field: "REVENUE", type: 'currency' },
                                        { title: "Cost", field: "COST", type: 'currency' },
                                        { title: "CPC", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.COST / rowdata.CLICKS)).toFixed(2)} displayType="text" prefix="$" /> }
                                    ]}
                                />


                            </Grid>
                            <Grid item sm={2} xs={12}>
                                <TableCard
                                    title="Search"
                                    data={topConversionData}
                                    count={5}
                                    client={props.client}
                                    color={props.themeColor}
                                    columns={[
                                        { title: "TERM", field: "SEARCH", render: rowdata => <Typography style={{ minWidth: 200, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.SEARCH}</Typography> },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 60),
                                                textAlign: "center"

                                            },
                                            title: "Conversions", field: "CONVERSIONS", hidden: false
                                        },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 30)

                                            }, title: "Clicks", field: "CLICKS", hidden: false
                                        },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 60),
                                                textAlign: "center"
                                            }, title: "Conv %", field: "CONVRATE", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                        }
                                    ]}
                                />
                            </Grid>

                        </Grid>


                    </div>
                    : <LinearProgress style={{ margin: theme.spacing(8) }}></LinearProgress>)}


            </ThemeProvider>




        </div>
    );
};

Bing.propTypes = {

};

export default Bing;
