import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { style } from './index.scss';
import CssBaseline from '@material-ui/core/CssBaseline';
import appVars from "../../../appVars.json";
import themeFile from '../../../theme.json';
import { Grid, Typography, Snackbar, AppBar, Toolbar, Box, Divider, Paper, Link, Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import LightGrid from '../../../LightGrid';
import { FileCopy } from '@material-ui/icons';
import GoogleLogo from '../../../images/g1.0.png';
import FacebookLogo from '../../../images/Facebook-Logo-700x394.png';
import BingLogo from '../../../images/bing-logo.png';
import MaterialTable from 'material-table';

const drawerWidth = 0;

const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
const id = getLastItem(window.location.href);


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        background: themeFile.palette.appBar.tagmanager,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    logo: {
        maxWidth: appVars.drawerWidth - 20,
        display: (sessionStorage.getItem('sl') === 1 ? 'block' : 'none')
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    media: {
        height: 140,
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        position: 'relative',
        top: '65px',
        padding: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(1),
        background: themeFile.palette.appBar.profile,
        marginBottom: theme.spacing(2),
        border: '1px solid #cacaca'
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)

    },
    copy: {
        width: '100%'
    },
    values: {
        color: themeFile.palette.primary.light
    }
}));







const Profile = () => {


    const classes = useStyles();
    const [hasError, setErrors] = useState(false);
    const [copySuccessShow, setCopySuccessShow] = useState(false);
    const [loading, setLoading] = useState([true]);
    const [copySuccess, setCopySuccess] = useState("");
    const [client, setClient] = useState([]);
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(true);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    async function fetchData() {
        const res = await fetch(appVars.api_url + "/tagmanagers/" + id);
        res
            .json()
            .then(res => {
                setClient(res);
                setLoading(false);
                console.log(res);
            })
            .catch(err => setErrors(true));
    }


    useEffect(() => {
        fetchData();
    }, []);




    return (
        <div className={style}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>

                    <Typography variant="h5" noWrap>

                        {client.name}
                    </Typography>
                </Toolbar>

            </AppBar>
            <div className={classes.content}>



                <Typography variant="body2">
                    <Grid container spacing={2}>

                        <Grid className={classes.paper} item xl={3}> <Typography gutterBottom>Registrar:<br></br><span className={classes.values}>{client.registrar}</span></Typography></Grid>
                        <Grid className={classes.paper} item xl={3}><Typography gutterBottom>URL:<br></br><span className={classes.values}><Link target="_BLANK" href={client.siteUrl}>{client.siteUrl}</Link></span></Typography></Grid>
                        <Grid className={classes.paper} item xl={3}><Typography gutterBottom >Donation Type:<br></br><span className={classes.values}>{client.donationtype}</span></Typography></Grid>
                        <Grid className={classes.paper} item xl={3}><Typography gutterBottom >Site Type:<br></br><span className={classes.values}>{client.site}</span></Typography></Grid>


                        <Grid item xl={3}>
                            <Card>
                                <CardActionArea>
                                    <CardMedia
                                        className={classes.media}
                                        image={GoogleLogo}

                                    />
                                    <CardContent>
                                        <Typography gutterBottom>GTM Container ID: <span className={classes.values}><Link target="_BLANK" href={'/gtm-install/' + client.id}>{client.tag}</Link></span> </Typography>
                                        <Typography gutterBottom>UA ID: <span className={classes.values}>{client.ua}</span></Typography>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    <Button size="large" variant="outlined" id="googleCopy" className={classes.copy} color="primary">Copy GTM Instructions</Button>
                                </CardActions>
                            </Card>

                        </Grid>
                        <Grid item xl={3}>

                            <Card>
                                <CardActionArea>
                                    <CardMedia
                                        className={classes.media}
                                        image={FacebookLogo}

                                    />
                                    <CardContent>
                                        <Typography gutterBottom>Pixel: <span className={classes.values}>{client.fbpixel}</span></Typography>
                                        <Typography gutterBottom>Verify ID: <span className={classes.values}><Link target="_BLANK" href={"https://fbverify.digitalwerks.com/?id=" + client.id}>{client.fbVerify}</Link></span></Typography>

                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    <Button size="large" id="fbCopy" variant="outlined" color="primary" className={classes.copy}>Copy Domain Validation</Button>
                                </CardActions>
                            </Card>


                        </Grid>

                        <Grid item xl={3}>


                            <Card>
                                <CardActionArea>
                                    <CardMedia
                                        className={classes.media}
                                        image={BingLogo}

                                    />
                                    <CardContent>
                                        <Typography gutterBottom>Pixel: <span className={classes.values}>{client.fbpixel}</span></Typography>
                                        <Typography gutterBottom>Verify ID: <span className={classes.values}><Link target="_BLANK" href={"https://fbverify.digitalwerks.com/?id=" + client.id}>{client.fbVerify}</Link></span></Typography>

                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    <Button size="large" id="fbCopy" variant="outlined" color="primary" className={classes.copy}>Already Included</Button>
                                </CardActions>
                            </Card>


                        </Grid>





                    </Grid>
                </Typography>

            </div>



        </div>
    );
};

Profile.propTypes = {

};

export default Profile;
