import {  Grid, Typography,  Snackbar, Select, MenuItem, IconButton, Card, CardContent, CardActionArea, CardActions, TextField } from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import React, { useState, useEffect } from 'react';
import { style } from './index.scss';
import ClipboardJS from "clipboard";
import appVars from '../../appVars.json';
import FileCopy from '@material-ui/icons/FileCopy'
import { ColorLens, Save } from '@material-ui/icons';
import { load } from 'dotenv';
import ColorPicker from 'material-ui-color-picker';
import FontPicker from "font-picker-react";
import Prism from 'prismjs';
import { resizeFont } from '../../dwi-functions';
import { constant } from 'lodash';
import themeFile from '../../theme.json';


const ToCss = require('obj-to-css');
var singleStyle = { h1: { color: "#433a6a", "font-size": "calc(3.75rem + ((1vw - 3px) * 0))", "min-height": "0vw", "font-family": "'Roboto', sans-serif", margin: "0px 0px 0px 0px", padding: "0px 0px 0px 0px", "font-weight": "400" }, h2: { color: "#433a6a", "font-size": "calc(2.5rem + ((1vw - 3px) * 0));", "min-height": "0vw", "font-family": "'Roboto', sans-serif", margin: "0px 0px 0px 0px", padding: "0px 0px 0px 0px", "font-weight": "300" }, h3: { color: "#433a6a", "font-size": "calc(1.5rem + ((1vw - 3px) * 0))", "min-height": "0vw", "font-family": "'Roboto', sans-serif", margin: "0px 0px 0px 0px", padding: "0px 0px 0px 0px", "font-weight": "700" }, p: { color: "#63666A", "font-size": "calc(0.875rem + ((1vw - 3px) * 0));", "min-height": "0vw", "font-family": "'Roboto', sans-serif", margin: "0px 0px 0px 0px", padding: "0px 0px 0px 0px", "font-weight": "400" }, p2: { color: "#63666A", "font-size": "calc(1.125rem + ((1vw - 3px) * 0))", "min-height": "0vw", "font-family": "'Roboto', sans-serif", margin: "0px 0px 0px 0px", padding: "0px 0px 0px 0px", "font-weight": "400" }, ".primaryColor": { color: "#fff", "background-color": themeFile.palette.primary.dark }, ".accentColor": { color: "#fff", "background-color": themeFile.palette.secondary.dark } };



//console.log(themeFile);



const StyleGuides = (props) => {
    const [loadedStyles, setStyles] = useState({
        "h1": {
            "color": "#433a6a",
            "font-size": "calc(3.75rem + ((1vw - 3px) * 0))",
            "min-height": "0vw",
            "font-family": "'Roboto', sans-serif",
            "margin": "0px 0px 0px 0px",
            "padding": "0px 0px 0px 0px",
            "font-weight": "400"
        },
        "h2": {
            "color": "#433a6a",
            "font-size": "calc(2.5rem + ((1vw - 3px) * 0));",
            "min-height": "0vw",
            "font-family": "'Roboto', sans-serif",
            "margin": "0px 0px 0px 0px",
            "padding": "0px 0px 0px 0px",
            "font-weight": "300"
        },
        "h3": {
            "color": "#433a6a",
            "font-size": "calc(1.5rem + ((1vw - 3px) * 0))",
            "min-height": "0vw",
            "font-family": "'Roboto', sans-serif",
            "margin": "0px 0px 0px 0px",
            "padding": "0px 0px 0px 0px",
            "font-weight": "700"
        },
        "p": {
            "color": "#63666A",
            "font-size": "calc(0.875rem + ((1vw - 3px) * 0));",
            "min-height": "0vw",
            "font-family": "'Roboto', sans-serif",
            "margin": "0px 0px 0px 0px",
            "padding": "0px 0px 0px 0px",
            "font-weight": "400"
        },
        "p2": {
            "color": "#63666A",
            "font-size": "calc(1.125rem + ((1vw - 3px) * 0))",
            "min-height": "0vw",
            "font-family": "'Roboto', sans-serif",
            "margin": "0px 0px 0px 0px",
            "padding": "0px 0px 0px 0px",
            "font-weight": "400"
        },
        ".primaryColor": {
            "color": "#fff",
            "background-color": "#433a6a"
        },
        ".accentColor": {
            "color": "#fff",
            "background-color": "#3697db"
        }
    });

    const [copySuccessShow, setCopySuccessShow] = useState(false);
    const [copySuccess, setCopySuccess] = useState("");
    const [css, setCss] = useState("");
    const [open, setOpen] = React.useState(false);
    const [saveOpen, setSaveOpen] = useState(false);
    const [saveMsg, setSaveMsg] = useState("");
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setCopySuccessShow(false);
        setSaveOpen(false);

    };

    const id = props.clientID;
    //console.log(props);

    const ShowCSSFonts = ({ field, form, ...props }) => {
        var k = props['css'];
        //console.log(props['elementType']);
        return <Typography id={props.elementType}>

            @{props.elementType}-size: {k['font-size']};<br></br>
            @{props.elementType}-family: {k['font-family']};<br></br>
            @{props.elementType}-weight: {k['font-weight']};<br></br>
            @{props.elementType}-color: {k['color']};

        </Typography>;
    };


    const ShowCSSColors = ({ field, form, ...props }) => {
        var k = props['css'];
        // console.log(k);
        return <Typography>
            color: {k.color};<br></br>
            background: {k["background-color"]};
        </Typography>;
    };


    async function fetchData() {



        const res = await fetch(appVars.api_url + "/clients/styles/" + props.clientID);
        res
            .json()
            .then(res => {
                if (res.length !== 0) {
                    var r = JSON.parse(res[0]['styles']);
                    singleStyle = r;
                    setStyles(r);
                   // console.log(r);
                }
                setCss(ToCss(singleStyle));
                Prism.highlightAll();
                //console.log('css:', JSON.stringify(singleStyle));
            });

    }





    function refreshCSS() {
        setStyles(loadedStyles);
        setCss(ToCss(loadedStyles));

    }


    //Prism.highlightAll();
    useEffect(() => {

        if (props.clientID === '') {
            props.clientID = 0;
        }
        fetchData();




        var clipboard = new ClipboardJS("#copy,#h1copy,.copy");
        clipboard.on("success", function (e) {
            setCopySuccess(e.action + ": " + e.text);
            setCopySuccessShow(true);
            e.clearSelection();
        });


    }, []);

    return (

        <div className={style}>

            <Grid container xl={12} spacing={1}>
                <Grid xl={11}>
                  
                </Grid>
                <Grid xl={1} >
                    <IconButton style={{ float: 'right', display: props.display }} onClick={(e) => {
                        var url = appVars.api_url + "/clients/styles/u/" + id;
                        var values = {
                            "clientID": id,
                            "styles": JSON.stringify(loadedStyles),
                            "pkRecID": "0"
                        }
                        const response = fetch(url, {
                            method: 'PUT', // *GET, POST, PUT, DELETE, etc.
                            body: JSON.stringify(values), // body data type must match "Content-Type" header,
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });
                        response
                            .then(res => {
                                setSaveOpen(true);
                                setSaveMsg('CSS Updated');
                                Prism.highlightAll();
                                //                                console.log(res);
                            })
                    }}>
                        <Save style={{ display: props.display }} ></Save>
                    </IconButton>
                </Grid>
            </Grid>
        
            <Grid container xl={12} spacing={2}>

                <Grid item xl={6}>
                    <Typography variant='h4' gutterBottom>.primaryColor</Typography>  
                    <div class="bumper primaryColor">
                        <ShowCSSColors css={singleStyle[".primaryColor"]} ></ShowCSSColors>
                        <ColorPicker
                            name='primaryColor'
                            value={loadedStyles['.primaryColor']["background-color"]} //- for controlled component
                            component={ColorLens}
                            style={{ display: props.display }}
                            onChange={
                                (color) => {
                                    //console.log(color);
                                    // this.target.value=color;
                                    loadedStyles['.primaryColor']["background-color"] = color;
                                    refreshCSS();
                                }
                            }
                        ></ColorPicker>
                    </div>
                </Grid>
                <Grid item xl={6}>
                <Typography variant='h4' gutterBottom>.accentColor</Typography> 
                    <div class="bumper accentColor">
                        <ShowCSSColors css={singleStyle[".accentColor"]}></ShowCSSColors>
                        <ColorPicker
                            name='accentColor'
                            value={loadedStyles['.accentColor']["background-color"]} //- for controlled component
                            component={ColorLens}
                            style={{ display: props.display }}
                            onChange={
                                (color) => {
                                    //console.log(color);
                                    // this.target.value=color;
                                    loadedStyles['.accentColor']["background-color"] = color;
                                    refreshCSS();
                                }
                            }
                        ></ColorPicker>
                    </div>
                </Grid>


                <Grid item xl={4} >

                    <Card>
                        <CardActionArea style={{ display: props.display }}>
                            <CardActions>
                                <IconButton id="h1copy" data-clipboard-target="#h1" title='Copy h1 CSS'>
                                    <FileCopy></FileCopy>
                                </IconButton>
                                <Grid xl={1}>
                                    <ColorPicker
                                        name='h1'
                                        value={loadedStyles['h1']["color"]} //- for controlled component
                                        component={ColorLens}
                                        onChange={
                                            (color) => {
                                                //console.log(color);
                                                // this.target.value=color;
                                                loadedStyles['h1']["color"] = color;
                                                refreshCSS();
                                            }
                                        }
                                    ></ColorPicker>
                                </Grid>
                                <Grid xl={5}>
                                    <TextField type="number" placeholder="Font Size(px)" defaultValue={singleStyle.h1['font-size']} onChange={(e) => {
                                        loadedStyles.h1['font-size'] = resizeFont(e.target.value.replace('px', ''));
                                        refreshCSS();
                                    }}></TextField>
                                </Grid>

                                <Grid xl={2}>
                                    <Select defaultValue={singleStyle.h1['font-weight']} onChange={(e) => {
                                        //console.log(e.target.value);
                                        loadedStyles['h1']["font-weight"] = e.target.value;
                                        refreshCSS();
                                    }}>
                                        <MenuItem value={100}>100</MenuItem>
                                        <MenuItem value={200}>200</MenuItem>
                                        <MenuItem value={300}>300</MenuItem>
                                        <MenuItem value={400}>400</MenuItem>
                                        <MenuItem value={500}>500</MenuItem>
                                        <MenuItem value={600}>600</MenuItem>
                                        <MenuItem value={700}>700</MenuItem>
                                        <MenuItem value={800}>800</MenuItem>
                                        <MenuItem value={900}>900</MenuItem>
                                    </Select>
                                </Grid>



                                <Grid xl={5}>
                                    <FontPicker
                                       
                                        apiKey={appVars.fontKey}
                                        onChange={(nextFont) => {
                                            loadedStyles.h1['font-family'] = "'" + nextFont.family + "' , sans-serif";
                                            refreshCSS();

                                        }
                                        }
                                    />
                                </Grid>


                            </CardActions>
                        </CardActionArea>

                        <CardContent>
                            <h1>Heading 1</h1>
                            <hr></hr>
                            <ShowCSSFonts css={singleStyle.h1} elementType="h1" ></ShowCSSFonts>
                        </CardContent>

                    </Card>
                </Grid>
                <Grid item xl={4}>

                    <Card>
                        <CardActionArea style={{ display: props.display }}>
                            <CardActions>
                                <IconButton className="copy" data-clipboard-target="#h2" title='Copy h2 CSS'>
                                    <FileCopy></FileCopy>
                                </IconButton>
                                <Grid xl={1}>
                                    <ColorPicker
                                        name='h2'
                                        value={loadedStyles['h2']["color"]} //- for controlled component
                                        component={ColorLens}
                                        onChange={
                                            (color) => {
                                                //console.log(color);
                                                // this.target.value=color;
                                                loadedStyles['h2']["color"] = color;
                                                refreshCSS();
                                            }
                                        }
                                    ></ColorPicker>
                                </Grid>
                                <Grid xl={5}>
                                    <TextField type="number" placeholder="Font Size(px)" defaultValue={singleStyle.h2['font-size']} onChange={(e) => {
                                        loadedStyles.h2['font-size'] = resizeFont(e.target.value.replace('px', ''));
                                        refreshCSS();
                                    }}></TextField>
                                </Grid>

                                <Grid xl={1}>
                                    <Select defaultValue={singleStyle.h2['font-weight']} onChange={(e) => {
                                        //console.log(e.target.value);
                                        loadedStyles['h2']["font-weight"] = e.target.value;
                                        refreshCSS();
                                    }}>
                                        <MenuItem value={100}>100</MenuItem>
                                        <MenuItem value={200}>200</MenuItem>
                                        <MenuItem value={300}>300</MenuItem>
                                        <MenuItem value={400}>400</MenuItem>
                                        <MenuItem value={500}>500</MenuItem>
                                        <MenuItem value={600}>600</MenuItem>
                                        <MenuItem value={700}>700</MenuItem>
                                        <MenuItem value={800}>800</MenuItem>
                                        <MenuItem value={900}>900</MenuItem>
                                    </Select>
                                </Grid>



                                <Grid xl={5}>
                                    <FontPicker
                                        apiKey={appVars.fontKey}
                                        onChange={(nextFont) => {
                                            loadedStyles.h2['font-family'] = "'" + nextFont.family + "' , sans-serif";
                                            refreshCSS();

                                        }
                                        }
                                    />
                                </Grid>


                            </CardActions>
                        </CardActionArea>
                        <CardContent>
                            <h2>Heading 2</h2>
                            <hr></hr>
                            <ShowCSSFonts css={singleStyle.h2} elementType="h2"></ShowCSSFonts>
                        </CardContent>
                    </Card>


                </Grid>
                <Grid item xl={4}>
                    <Card>
                        <CardActionArea style={{ display: props.display }}>
                            <CardActions>
                                <IconButton id="h3copy" data-clipboard-target="#h3" title='Copy h3 CSS'>
                                    <FileCopy></FileCopy>
                                </IconButton>
                                <Grid xl={1}>
                                    <ColorPicker
                                        name='h3'
                                        value={loadedStyles['h3']["color"]} //- for controlled component
                                        component={ColorLens}
                                        onChange={
                                            (color) => {
                                                //console.log(color);
                                                // this.target.value=color;
                                                loadedStyles['h3']["color"] = color;
                                                refreshCSS();
                                            }
                                        }
                                    ></ColorPicker>
                                </Grid>
                                <Grid xl={5}>
                                    <TextField type="number" placeholder="Font Size(px)" defaultValue={singleStyle.h1['font-size']} onChange={(e) => {
                                        loadedStyles.h3['font-size'] = resizeFont(e.target.value.replace('px', ''));
                                        refreshCSS();
                                    }}></TextField>
                                </Grid>

                                <Grid xl={2}>
                                    <Select defaultValue={singleStyle.h3['font-weight']} onChange={(e) => {
                                        //console.log(e.target.value);
                                        loadedStyles['h3']["font-weight"] = e.target.value;
                                        refreshCSS();
                                    }}>
                                        <MenuItem value={100}>100</MenuItem>
                                        <MenuItem value={200}>200</MenuItem>
                                        <MenuItem value={300}>300</MenuItem>
                                        <MenuItem value={400}>400</MenuItem>
                                        <MenuItem value={500}>500</MenuItem>
                                        <MenuItem value={600}>600</MenuItem>
                                        <MenuItem value={700}>700</MenuItem>
                                        <MenuItem value={800}>800</MenuItem>
                                        <MenuItem value={900}>900</MenuItem>
                                    </Select>
                                </Grid>



                                <Grid xl={5}>
                                    <FontPicker
                                        apiKey={appVars.fontKey}
                                        onChange={(nextFont) => {
                                            loadedStyles.h3['font-family'] = "'" + nextFont.family + "' , sans-serif";
                                            refreshCSS();

                                        }
                                        }
                                    />
                                </Grid>


                            </CardActions>
                        </CardActionArea>
                        <CardContent>
                            <h3>Heading 3</h3>
                            <hr></hr>
                            <ShowCSSFonts css={singleStyle.h3} elementType="h3"></ShowCSSFonts>
                        </CardContent>
                    </Card>

                </Grid>
                <Grid item xl={6}>

                    <Card>
                        <CardActionArea style={{ display: props.display }}>
                            <CardActions>
                                <IconButton className="copy" data-clipboard-target="#p" title='Copy p CSS'>
                                    <FileCopy></FileCopy>
                                </IconButton>
                                <Grid xl={1}>
                                    <ColorPicker
                                        name='p'
                                        value={loadedStyles['p']["color"]} //- for controlled component
                                        component={ColorLens}
                                        onChange={
                                            (color) => {
                                                //console.log(color);
                                                // this.target.vßlue=color;
                                                loadedStyles['p']["color"] = color;
                                                refreshCSS();
                                            }
                                        }
                                    ></ColorPicker>
                                </Grid>
                                <Grid xl={5}>
                                    <TextField type="number" placeholder="Font Size(px)" defaultValue={singleStyle.p['font-size']} onChange={(e) => {
                                        loadedStyles.p['font-size'] = resizeFont(e.target.value.replace('px', ''));
                                        refreshCSS();
                                    }}></TextField>
                                </Grid>

                                <Grid xl={2}>
                                    <Select defaultValue={singleStyle.p['font-weight']} onChange={(e) => {
                                        //console.log(e.target.value);
                                        loadedStyles['p']["font-weight"] = e.target.value;
                                        refreshCSS();
                                    }}>
                                        <MenuItem value={100}>100</MenuItem>
                                        <MenuItem value={200}>200</MenuItem>
                                        <MenuItem value={300}>300</MenuItem>
                                        <MenuItem value={400}>400</MenuItem>
                                        <MenuItem value={500}>500</MenuItem>
                                        <MenuItem value={600}>600</MenuItem>
                                        <MenuItem value={700}>700</MenuItem>
                                        <MenuItem value={800}>800</MenuItem>
                                        <MenuItem value={900}>900</MenuItem>
                                    </Select>
                                </Grid>



                                <Grid xl={5}>
                                    <FontPicker
                                        apiKey={appVars.fontKey}
                                        onChange={(nextFont) => {
                                            loadedStyles.p['font-family'] = "'" + nextFont.family + "' , sans-serif";
                                            refreshCSS();
                                        }
                                        }
                                    />
                                </Grid>


                            </CardActions>
                        </CardActionArea>
                        <CardContent>
                            <h3>Body Text 1</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam at orci neque. Vivamus ullamcorper, est eu maximus molestie, est est tincidunt eros, nec rutrum eros felis elementum ex. Etiam vehicula id ipsum non imperdiet. Nullam est neque, commodo a quam ac, molestie interdum nisi. Vivamus ornare congue orci quis hendrerit. Maecenas at hendrerit ante. Morbi cursus ligula sed nunc elementum gravida. Quisque id nisi porta ex tristique convallis sed vel enim. Nulla facilisi. Etiam quis ornare turpis.</p>
                            <hr></hr>
                            <ShowCSSFonts css={singleStyle.p} elementType="p"></ShowCSSFonts>
                        </CardContent>

                    </Card>
                </Grid>
                <Grid item xl={6}>

                    <Card>
                        <CardActionArea style={{ display: props.display }}>
                            <CardActions>
                                <IconButton className="copy" data-clipboard-target="#p2" title='Copy p2 CSS'>
                                    <FileCopy></FileCopy>
                                </IconButton>
                                <Grid xl={1}>
                                    <ColorPicker
                                        name='p2'
                                        value={loadedStyles['p2']["color"]} //- for controlled component
                                        component={ColorLens}
                                        onChange={
                                            (color) => {
                                                //console.log(color);
                                                // this.target.vßlue=color;
                                                loadedStyles['p2']["color"] = color;
                                                refreshCSS();
                                            }
                                        }
                                    ></ColorPicker>
                                </Grid>
                                <Grid xl={5}>
                                    <TextField type="number" placeholder="Font Size(px)" defaultValue={singleStyle.p2['font-size']} onChange={(e) => {
                                        loadedStyles.p2['font-size'] = resizeFont(e.target.value.replace('px', ''));
                                        refreshCSS();
                                    }}></TextField>
                                </Grid>

                                <Grid xl={2}>
                                    <Select defaultValue={singleStyle.p2['font-weight']} onChange={(e) => {
                                        //console.log(e.target.value);
                                        loadedStyles['p']["font-weight"] = e.target.value;
                                        refreshCSS();
                                    }}>
                                        <MenuItem value={100}>100</MenuItem>
                                        <MenuItem value={200}>200</MenuItem>
                                        <MenuItem value={300}>300</MenuItem>
                                        <MenuItem value={400}>400</MenuItem>
                                        <MenuItem value={500}>500</MenuItem>
                                        <MenuItem value={600}>600</MenuItem>
                                        <MenuItem value={700}>700</MenuItem>
                                        <MenuItem value={800}>800</MenuItem>
                                        <MenuItem value={900}>900</MenuItem>
                                    </Select>
                                </Grid>



                                <Grid xl={5}>
                                    <FontPicker
                                        apiKey={appVars.fontKey}
                                        onChange={(nextFont) => {
                                            loadedStyles.p2['font-family'] = "'" + nextFont.family + "' , sans-serif";
                                            refreshCSS();
                                        }
                                        }
                                    />
                                </Grid>


                            </CardActions>
                        </CardActionArea>
                        <CardContent>
                            <h3>Body Text 2</h3>
                            <p2 className='body1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam at orci neque. Vivamus ullamcorper, est eu maximus molestie, est est tincidunt eros, nec rutrum eros felis elementum ex. Etiam vehicula id ipsum non imperdiet. Nullam est neque, commodo a quam ac, molestie interdum nisi. Vivamus ornare congue orci quis hendrerit. Maecenas at hendrerit ante. Morbi cursus ligula sed nunc elementum gravida. Quisque id nisi porta ex tristique convallis sed vel enim. Nulla facilisi. Etiam quis ornare turpis.</p2>
                            <hr></hr>
                            <ShowCSSFonts css={singleStyle.p2} elementType="p2"></ShowCSSFonts>
                        </CardContent>

                    </Card>
                </Grid>
                <Grid item xl={12} style={{ display: props.display }}>

                    <Card>
                        <CardContent>
                            <code id="css" className="language-css">
                                {css}
                            </code>
                        </CardContent>
                        <CardActionArea>
                            <CardActions>
                                <IconButton className="copy" data-clipboard-target="#css" title='Copy starter CSS'>
                                    <FileCopy></FileCopy>
                                </IconButton>
                            </CardActions>
                        </CardActionArea>
                    </Card>
                </Grid>
                <style>
                    {css + '.primaryColor p{color:' + singleStyle['.primaryColor'].color + '}.accentColor p{color:' + singleStyle['.accentColor'].color + '}'}


                </style>

            </Grid>


            <Snackbar open={saveOpen} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose}>
                    {saveMsg}
                </Alert>
            </Snackbar>



            <Snackbar
                open={copySuccessShow}
                autoHideDuration={1500}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success">
                    {copySuccess}
                </Alert>
            </Snackbar>
        </div>
    );
};

StyleGuides.propTypes = {

};

export default StyleGuides;
