import React, { useState,useEffect } from 'react';
import { Button } from '@material-ui/core';
import { lightenHexColor } from "../../../dwi-functions";
import { DateRangePicker } from 'materialui-daterange-picker';
import { CalendarTodayTwoTone } from '@material-ui/icons';
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider, useTheme, makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    cal: {
        position: 'absolute',
        right: theme.spacing(1),
        paddingTop: theme.spacing(0.5)
    },
}));

const DateRangeSelector = (props) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const sdate = searchParams.get('sdate');
    const edate = searchParams.get('edate');
    var _d = sdate ? new Date(sdate) : new Date(props.sDate);
    var _e = edate ? new Date(edate) : new Date(props.eDate);

    /*
    console.log('Start Date:', _d);
    console.log('End Date:', _e);
    console.log('Tab:', tab);
    */

    const [selectedDateS, setSelectedDateS] = useState(_d.toISOString().split('T')[0]);
    const [selectedDateE, setSelectedDateE] = useState(_e.toISOString().split('T')[0]);

    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);
    const theme = useTheme();
    const classes = useStyles();


    useEffect(() => {
        
        return () => {
            
        };
    }, [props]);



    return (
        <div>
            <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>

                    <Button
                        fullWidth
                        type="text"
                        color='primary'
                        size="small"
                        endIcon={<CalendarTodayTwoTone></CalendarTodayTwoTone>}
                        variant="contained"
                        margin="dense"
                        style={{ background: lightenHexColor(theme.palette.primary.main, 20), marginBottom: theme.spacing(2), fontWeight: 'bold' }}
                        onClick={toggle}

                    >{new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric', timeZone: "UTC" }).format(new Date(selectedDateS))} - {new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric', timeZone: "UTC" }).format(new Date(selectedDateE))}
                    </Button>
                    <div className={classes.cal}>
                        <DateRangePicker

                            open={open}
                            initialDateRange={{
                                startDate: _d,
                                endDate: new Date()
                            }}
                            position="top"
                            toggle={toggle}
                            onChange={(e)=>{
                                props.childToParent(e);
                                toggle();
                            }}
                            showOutsideDays
                            fixedWeeks
                        /></div>


                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </div>
    );
};

DateRangeSelector.propTypes = {

};

export default DateRangeSelector;
