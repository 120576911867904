import React, { useState, useEffect } from 'react';
import { makeStyles, createTheme, ThemeProvider } from "@material-ui/core/styles";
import themeFile from '../../theme.json';
import {Avatar, TextField, MenuItem, Box } from '@material-ui/core'
import { Typography, Grid, Snackbar, Paper, Link } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import appVars from "../../appVars.json";
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Search } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import { lightenHexColor } from '../../dwi-functions';
import PlatformSearch from '../controls/platform-search';


const theme = createTheme(themeFile);





function getSteps() {
    return [
        <span> Get Started</span>,
        <span> Domain Scan</span>,
        <span> Google Settings</span>,
        <span> META Settings</span>,
        <span> Confirm Settings</span>];
}




const Newclient = (props) => {

    const location = useLocation();
    var id = location.pathname.split("/")[2];

    const searchParams = new URLSearchParams(location.search);

    const clientid = id;
    const ownerid = props.admintoken.user;

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const [screenshot, setscreenshot] = useState('');
    const [clientData, setClient] = useState({
        ga4Array: [],
        fbpixelArray: [],
        ga_paid_idArray: [{ id: '', label: '' }, { id: '', label: '' }],
        ga_grant_idArray: [{ id: '', label: '' }, { id: '', label: '' }],
        donationUrl: []
    })
    const [owner, setOwner] = useState((!ownerid ? '' : ownerid));
    const [scan, setScan] = useState(true);
    const [clientID, setClientID] = useState((!clientid ? '' : clientid));
    const [disabled, setDisable] = useState((!clientid ? false : false));
    const [disabledO, setODisable] = useState((!ownerid ? false : false));
    const [loadingData, setLoading] = useState(true);
    const [fbid, setfbID] = useState("");
    const [clientTypes, setClientType] = useState(
        [{
            value: 1,
            label: 'Not Set'
        }]
    );
    const [userTypes, setUserType] = useState(
        [{
            value: 1,
            label: 'Not Set'
        }]
    );
    const [errors, setErrors] = useState({ status: false, "msg": 'No Error' });
    const [load, setLoad] = useState(false);


    const useStyles = makeStyles((theme) => ({
        root: {
            margin: theme.spacing(0),
            marginTop: theme.spacing(0),
        },
        divider: {
            marginBottom: theme.spacing(2),
        },
        text: {
            width: "100%",
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2)
        },
        button: {
            marginTop: theme.spacing(2),
            marginRight: theme.spacing(1)
        },
        dense: {
            float: "right",
        },
        text30: {
            width: "48%",
        },
        margin: {
            margin: theme.spacing(2),
        },
        padding: {
            padding: theme.spacing(0, 3),
        },
        appBar: {
            backgroundColor: (props.themeColor ? props.themeColor.primary : theme.palette.primary.main )
        },
        menu: {
            padding: theme.spacing(0, 0),
            minHeight: '100%',


        },
        paper: {
            padding: theme.spacing(2, 3)
        },
        thumbnail: {
            width: '100%',
            //maxWidth:300,
            border: "1px solid " +  (props.themeColor ? props.themeColor.primary : theme.palette.primary.main ),
            boxShadow: "1px 1px 7px 1px " +  (props.themeColor ? props.themeColor.primary : theme.palette.primary.main )
        }
    }));






    const classes = useStyles();
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleChange = (event) => {

        clientData[event.target.id] = event.target.value;
        setClient(clientData);
        console.log(clientData);
    };



    const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
        const hex = x.toString(16)
        return hex.length === 1 ? '0' + hex : hex
    }).join('');




    async function snagTheDomain() {

        setLoading(false);
        var d = document.getElementById('domain').value.replace('https://', '');

        var url = appVars.api_url + "/check-domain/" + btoa(d);


        const res = await fetch(url,
            {
                method: 'GET'
            }
        );
        res
            .json()
            .then((domainres) => {

                // domainres.name = d;
                domainres.client = owner;
                console.log(domainres);
                setClient(domainres);
                setLoading(true);

                setLoad(true);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setscreenshot(domainres.screenshot);
            }).catch((err) => {
                setErrors({ status: true, "msg": err });
                //console.log(err);
            });

    }


    async function fetchStats(id) {

        const res = await fetch(appVars.api_url + "/clients");
        res
            .json()
            .then((res) => {


                setClientType(res);
                //console.log(clientTypes);
                //console.log(v);
            }).catch((err) => {
                setErrors(true);
                console.log(err);
            });
        var url = (clientid ? appVars.api_url + "/users" : appVars.api_url + "/users/c/" + clientid);
        // console.log(url);
        const ures = await fetch(url);
        ures
            .json()
            .then((users) => {
                setUserType(users);
                //console.log(clientTypes);
                //console.log(v);
            }).catch((err) => {
                setErrors(true);
                console.log(err);
            });





    }

    useEffect(() => {
        fetchStats();
    }, []);



    return (

        <div style={{padding:theme.spacing(1)}} >

            <Grid container spacing={1}>

                <Grid item xs={2} >
                    <Paper variant="outlined" className={classes.menu}>
                        <Stepper variant="outlined" style={{ border: 'none' }} activeStep={activeStep} orientation="vertical" >
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel><Typography variant="body">{label}</Typography></StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Paper>


                </Grid>

                <Grid item xs={10}>
                    <LinearProgress style={{ margin: theme.spacing(5) }} hidden={loadingData} color="primary" ></LinearProgress>
                    <form>
                        <Paper variant="outlined" className={classes.paper}>
                            <div id="step1" style={{ display: (activeStep === 0 ? 'block' : 'none') }} >
                                <TextField
                                    type="text"
                                    className={classes.text}
                                    variant="outlined"
                                    helperText="Client Display Name"
                                    label="Client Name"
                                    name="name"
                                    id="name"
                                    size='small'
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onBlur={(e) => {

                                        //  console.log(e.target.value)
                                    }}
                                ></TextField>
                                <div style={{ display: (disabled ? 'none' : 'block') }}>
                                    <TextField
                                        type="hidden"
                                        select
                                        value={clientID}
                                        disabled={disabled}
                                        className={classes.text}
                                        variant="outlined"
                                        size='small'
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        label="Please select client"
                                        helperText={(!clientID ? "What Client shoud we assign this too" : "Adding for client " + clientID)}
                                        name="client"
                                        id="client"
                                        onChange={(e) => {
                                            setClientID(e.target.value);
                                            console.log(e.target.value)
                                        }}

                                    >
                                        {clientTypes.map((entry) => (
                                            <MenuItem key={entry.clientId} value={entry.clientId}>{entry.clientname}</MenuItem>

                                        ))}
                                    </TextField>
                                </div>
                                <div style={{ display: (disabledO ? 'none' : 'block') }}>
                                    <TextField
                                        select
                                        value={owner}
                                        disabled={disabledO}
                                        className={classes.text}
                                        variant="outlined"
                                        size='small'
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        label="Please select Account Owner"
                                        helperText={(!owner ? "What OWNER shoud we assign this too" : "Adding for OWNER " + owner)}
                                        name="owner"
                                        id="owner"
                                        onChange={(e) => {
                                            setOwner(e.target.value);

                                        }}

                                    >
                                        {userTypes.map((entry) => (
                                            <MenuItem key={entry.pkRecId} value={entry.pkRecId}>{entry.name}</MenuItem>

                                        ))}
                                    </TextField>
                                </div>

                                <TextField
                                    type="text"
                                    size='small'
                                    className={classes.text}
                                    helperText="What domain do you want to start tracking?"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    label="Domain name: example.com"
                                    name="domain"
                                    id="domain"
                                    //onChange={snagTheDomain}
                                    onBlur={(e) => {
                                        if (e.currentTarget.value !== "") {
                                            setScan(false);
                                        }

                                    }}
                                ></TextField>
                                <Button disabled={scan} size='small' className={classes.button} startIcon={<Search></Search>} variant="contained" color="primary" onClick={snagTheDomain}>Scan Domain</Button>
                            </div>
                            <div id="step2" style={{ display: (activeStep === 1 ? 'block' : 'none') }} >
                                <Grid container spacing={2}>
                                    <Grid item xl={4}>




                                        <TextField
                                            type="text"
                                            className={classes.text}
                                            variant="outlined"
                                            value={clientData.siteUrl}
                                            label="Website URL"
                                            name="site_url"
                                            id="siteUrl"
                                            size='small'
                                            onChange={(e) => {
                                                clientData.siteUrl = e.target.value;
                                                setClient(clientData);
                                            }}

                                        ></TextField>
                                        <TextField
                                            type="text"
                                            className={classes.text}
                                            variant="outlined"
                                            value={clientData.donationUrl[0]}
                                            label="Donation URL"
                                            name="donationUrl"
                                            id="donationUrl"
                                            size='small'
                                            onChange={handleChange}
                                        ></TextField>
                                        <PlatformSearch sitetype='website' className={classes.text} fulleWidth value={clientData.sitetype} title="Select Website Platform"
                                             onChange={(e, ne) => {
                                                clientData.sitetype = ne.pkRecID 
                                                setClient(clientData);
                                            }}
                                        />
                                        <PlatformSearch sitetype='donation'  className={classes.text} value={clientData.donationtype} title="Select Donation Platform"
                                           onChange={(e, ne) => {
                                                clientData.donationtype = ne.pkRecID;
                                                setClient(clientData);
                                            }}
                                        />
                                        <TextField
                                            type="text"
                                            className={classes.text}
                                            variant="outlined"
                                            value={clientData.registrar}
                                            label="Domain Registrar"
                                            name="registrar"
                                            size='small'
                                            id="registrar"
                                            onBlur={(e) => {
                                                clientData.registrar = e.target.value;
                                            }}
                                        //onChange={snagTheDomain}
                                        ></TextField>
                                    </Grid>
                                    <Grid item xl={8}>

                                        {(clientData.siteUrl ? <img className={classes.thumbnail} src={screenshot} alt="Screenshot" onError={(e)=>{ setscreenshot('https://via.placeholder.com/300x300.png/d3d3d3?text=Not+Available');}}  /> : '')}
                                    </Grid>
                                    <Grid item xl={4}>
                                        {

                                            (clientData.colors ?
                                                clientData.colors.map((entry, i) => (
                                                    <Grid item xl={12} ><Typography style={{ padding: '5px', margin: '3px', background: rgbToHex(entry[0], entry[1], entry[2]) }} key={i} value={i}>{rgbToHex(entry[0], entry[1], entry[2])}</Typography></Grid>
                                                ))
                                                : '')
                                        }


                                    </Grid>
                                </Grid>
                                <Button size='small' className={classes.button} hidden={load} variant="contained" color="primary" onClick={handleBack} >Back</Button>
                                <Button size='small' className={classes.button} hidden={load} variant="contained" color="primary" onClick={handleNext} >Next</Button>
                            </div>

                            <div id="step3" style={{ display: (activeStep === 2 ? 'block' : 'none') }} >


                                {
                                    (clientData.tagArray ?

                                        <TextField
                                            select
                                            type="text"
                                            helperText="This is your Google Tag Manager ID"
                                            className={classes.text}
                                            variant="outlined"
                                           
                                            label="GTM Account"
                                            name="tag"
                                            size='small'
                                            id="tag"
                                            onChange={(e) => {
                                                clientData.tag = e.target.value;
                                                setClient(clientData);
                                            }}
                                        //onChange={snagTheDomain}

                                        >
                                            {clientData.tagArray.map((entry) => (
                                                <MenuItem key={entry.tag} value={entry.tag}>{entry.tag}</MenuItem>
                                            ))}


                                        </TextField>
                                        : '')
                                }

                                {
                                    (clientData.ga4Array ?

                                        <TextField
                                            select
                                            type="text"
                                            size='small'
                                            helperText="This is your Google Analytics 4 Measurement ID"
                                            className={classes.text}
                                            variant="outlined"
                                            value={clientData.measurementID}
                                            label="Analytics 4 Measurement ID"
                                            name="measurementID"
                                            id="measurementID"
                                            onChange={(e) => {
                                                clientData.measurementID = e.target.value;
                                                setClient(clientData);
                                            }}
                                        //onChange={snagTheDomain}

                                        >

                                            {clientData.ga4Array.map((entry) => (
                                                <MenuItem key={entry} value={entry}>{entry}</MenuItem>

                                            ))}



                                        </TextField> : '')
                                }


                                {
                                    (clientData.ga_paid_idArray ?
                                        <TextField

                                            select
                                            type="text"
                                            size='small'
                                            helperText="This is your Adwords paid account"
                                            className={classes.text}
                                            variant="outlined"
                                            
                                            label="Adwords Paid Account"
                                            name="awp"
                                            id="awp"
                                            onChange={(e) => {
                                                clientData.ga_paid_id = e.target.value;
                                                setClient(clientData);
                                            }}
                                        //onChange={snagTheDomain}

                                        >

                                            {clientData.ga_paid_idArray.map((entry) => {

                                                return (
                                                    <MenuItem key={entry} value={entry.id}>{entry.id}  -  {entry.label}</MenuItem>

                                                )
                                            })}



                                        </TextField> : '')
                                }

                                {
                                    (clientData.ga_paid_idArray ? <TextField

                                        select
                                        type="text"
                                        size='small'
                                        helperText="This is your Adwords grant account"
                                        className={classes.text}
                                        variant="outlined"

                                        label="Adwords Grant Account"
                                        name="awp"
                                        id="awp"
                                        onChange={(e) => {
                                            clientData.ga_grant_id = e.target.value;
                                            setClient(clientData);
                                        }}
                                    //onChange={snagTheDomain}

                                    >

                                        {clientData.ga_paid_idArray.map((entry) => {
                                            return (
                                                <MenuItem key={entry} value={entry.id}>{entry.id}</MenuItem>

                                            )
                                        })}



                                    </TextField> : '')
                                }

                                <Button size='small' className={classes.button} hidden={load} variant="contained" color="primary" onClick={handleBack} >Back</Button>
                                <Button size='small' className={classes.button} hidden={load} variant="contained" color="primary" onClick={handleNext} >Next</Button>
                            </div>
                            <div id="step4" style={{ display: (activeStep === 3 ? 'block' : 'none') }} >


                                {
                                    (clientData.fbpixelArray ?

                                        <TextField
                                            select
                                            type="text"
                                            size='small'
                                            className={classes.text}
                                            helperText="This is your Meta Pixel ID"
                                            variant="outlined"
                                            value={clientData.fbpixel}
                                            label="Meta Pixel ID:"
                                            name="fbPixel"
                                            id="fbPixel"
                                            onChange={(e) => {
                                                clientData.fbpixel = e.target.value;
                                            }}
                                        //onChange={snagTheDomain}

                                        >

                                            {clientData.fbpixelArray.map((entry) => (
                                                <MenuItem key={entry} value={entry}>{entry}</MenuItem>

                                            ))}


                                        </TextField>

                                        : '')
                                }

                                <TextField type="text"
                                    size='small'
                                    className={classes.text}
                                    variant="outlined"
                                    helperText={<Typography>This is for META's Domain Verification Link <Link color="secondary" href='https://url.digitalwerks.com/metatoken' target='_BLANK' >Find My Verification ID</Link></Typography>}
                                    label="Facebook Verify ID"
                                    name="fbid"
                                    id="fbid"
                                    onBlur={(e) => {
                                        setfbID(e.target.value);
                                    }}>
                                </TextField>


                                <TextField type="text"
                                    size='small'
                                    className={classes.text}
                                    variant="outlined"
                                    helperText={<Typography>This is META's API Token <Link color="secondary" href='https://url.digitalwerks.com/metatoken' target='_BLANK' >Find My Token</Link></Typography>}
                                    label="Facebook API Token"
                                    name="token"
                                    id="token"
                                    onBlur={(e) => {
                                        setfbID(e.target.value);
                                    }}>
                                </TextField>
                                <Button size='small' className={classes.button} hidden={load} variant="contained" color="primary" onClick={handleBack} >Back</Button>
                                <Button size='small' className={classes.button} hidden={load} variant="contained" color="primary" onClick={handleNext} >Next</Button>
                            </div>
                            <div id="step5" style={{ display: (activeStep === 4 ? 'block' : 'none') }} >


                                <Grid container spacing={3} direction="row-reverse">

                                    <Grid item xl={6}>

                                        <Grid container xl={12} spacing={1}>
                                            {(clientData.siteUrl ? <img className={classes.thumbnail} src={screenshot} onError={(e)=>{ setscreenshot('https://via.placeholder.com/300x300.png/d3d3d3?text=Not+Available');}} alt="Screenshot" /> : '')}
                                            {

                                                (clientData.colors ?
                                                    clientData.colors.map((entry, i) => (
                                                        <Grid item xl={12} ><Typography style={{ padding: '5px', margin: '3px', background: rgbToHex(entry[0], entry[1], entry[2]) }} key={i} value={i}>{rgbToHex(entry[0], entry[1], entry[2])}</Typography></Grid>
                                                    ))
                                                    : '')
                                            }

                                        </Grid>
                                    </Grid>


                                    <Grid item xl={6}>
                                        <Box p={2} mb={2} border={1} borderColor={lightenHexColor(theme.palette.primary.main, 0)}>
                                            <Typography color='primary' style={{ borderBottom: "1px solid", display: 'block' }} gutterBottom variant='h6'>Google Settings</Typography>
                                            <Typography>Container ID: <span style={{ color: "#399cdb" }}>{clientData.tag}</span></Typography>
                                            <Typography>GA4: <span style={{ color: "#399cdb" }}>{clientData.ga4Array[0]}</span></Typography>
                                        </Box>
                                        <Box p={2} mb={2} border={1} borderColor={lightenHexColor(theme.palette.primary.main, 0)}>
                                            <Typography color='primary' style={{ borderBottom: "1px solid", display: 'block' }} gutterBottom variant='h6'>Meta Settings</Typography>
                                            <Typography>Meta Pixel ID: <span style={{ color: "#399cdb" }}>{clientData.fbpixelArray[0]}</span></Typography>
                                            <Typography>Bing UET ID: <span style={{ color: "#399cdb" }}>{clientData.bingId}</span></Typography>
                                        </Box>
                                        <Box p={2} mb={2} border={1} borderColor={lightenHexColor(theme.palette.primary.main, 0)}>
                                            <Typography color='primary' style={{ borderBottom: "1px solid", display: 'block' }} gutterBottom variant='h6'>Owner Info</Typography>
                                            <Typography>Client ID: <br></br><img style={{width:'100%',maxWidth:100}} src={appVars.api_url + '/imageFiles/' + clientID + '.png'} alt='' /></Typography>
                                            <Typography>Owner ID:    <Avatar alt={owner} variant="circular" title={owner} src={appVars.api_url + '/imageFiles/' + owner + '.png'} /></Typography>
                                        </Box>
                                    </Grid>

                                </Grid>


                                <Button size='small' className={classes.button} hidden={load} variant="contained" color="primary" onClick={handleBack} >Back</Button>
                                <Button size='small' className={classes.button} color="primary" variant="contained" type="button"
                                    onClick={(e) => {


                                        var url = appVars.api_url + "/gtm/add/";
                                        //  console.log('Adding Client');

                                        var data = {
                                            "tag": clientData.tag,
                                            "ua": clientData.ua,
                                            "fbpixel": clientData.fbpixel,
                                            "registrar": clientData.registrar,
                                            "siteUrl": clientData.siteUrl,
                                            "donationUrl": clientData.donationUrl[0],
                                            "sitetype": clientData.sitetype,
                                            "donationtype": clientData.donationtype,
                                            "name": document.getElementById('name').value,
                                            "client": clientID,
                                            "fbtoken": document.getElementById('fbtoken'),
                                            "owner": owner,
                                            "fbVerification": fbid,
                                            "pkRecId": "1234",
                                            "measurementID": clientData.measurementID,
                                            "status": 1
                                        };

                                        const response = fetch(url, {
                                            method: 'POST', // *GET, POST, PUT, DELETE, etc.
                                            body: JSON.stringify(data), // body data type must match "Content-Type" header,
                                            headers: {
                                                'Content-Type': 'application/json'
                                            }
                                        });
                                        response
                                            .then(users => {
                                               window.location.href = '/clientview/' + clientID;
                                                //console.log(users);
                                            })
                                        //console.log(clientData);
                                        //console.log(JSON.stringify(clientData));

                                    }}
                                >
                                    Finish
                                </Button>
                                <Button size='small' href={'/clientview/' + clientid} className={classes.button} color="default" variant="contained">
                                    cancel
                                </Button>

                            </div>
                        </Paper>
                    </form>



                </Grid>



            </Grid>


            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={errors.status}
                autoHideDuration={1500}
                onClose={handleClose}
                message={errors.msg.toString()}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />


        </div>
    );
};

Newclient.propTypes = {

};

export default Newclient;
