import { Card, CardActionArea, CardActions, CardContent, CardHeader, Typography } from '@material-ui/core';
import React from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import themeFile from '../../theme.json';
import { AreaChart, Area, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { ArrowDownwardOutlined, ArrowUpwardOutlined } from '@material-ui/icons';
import { PatternFormat } from 'react-number-format';
import { NumericFormat } from 'react-number-format';
import { lightenHexColor, darkenHexColor } from '../../dwi-functions';
var CurrencyFormat = require('react-currency-format');



const theme = createTheme(themeFile);






const ChartCard = (props) => {

    const CustomTooltip = ({ active, payload, label }) => {

        if (active && payload && payload.length) {

            return (
                <div style={{ border: "1px solid " + props.color, padding: theme.spacing(1), background: lightenHexColor(props.color, 40) }}>
                    <Typography variant='h6'>{`${(payload[0].payload.name.value ? payload[0].payload.name.value : payload[0].payload.name)}`}</Typography>
                    <Typography variant='body2'>
                        <span>{`${payload[0].value}`}</span><br></br></Typography>
                </div>
            );
        }

        return null;
    };



    const CustomPercetageTooltip = ({ active, payload, label }) => {

        if (active && payload && payload.length) {
            return (
                <div style={{ padding: theme.spacing(1), background: lightenHexColor(props.color, 40) }}>
                    <Typography variant='h6'>{`${(payload[0].payload.name.value ? payload[0].payload.name.value : payload[0].payload.name)}`}</Typography>
                    <Typography variant='body2'>
                        <CurrencyFormat decimalScale={2} fixedDecimalScale={true} value={(payload[0].value !== null ? (payload[0].value).toFixed(2) : 0)} displayType="text" suffix="%" />
                    </Typography>
                </div>
            );
        }

        return null;
    };



    const CustomCurrencyTooltip = ({ active, payload, label }) => {

        if (active && payload && payload.length) {
            return (
                <div style={{ padding: theme.spacing(1), background: lightenHexColor(props.color, 40) }}>
                    <Typography variant='h6'>{`${(payload[0].payload.name.value ? payload[0].payload.name.value : payload[0].payload.name)}`}</Typography>
                    <Typography variant='body2'>
                        <NumericFormat value={(payload[0].value !== null ? (payload[0].value).toFixed(2) : 0)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                    </Typography>
                </div>
            );
        }

        return null;
    };




    //  console.log(props);
    var countWrite = null;
    var ToolTip = <CustomTooltip />;

    switch (props.title) {
        case 'COST':
            countWrite = <CurrencyFormat decimalScale={2} fixedDecimalScale={true} value={(props.data.count !== null ? props.data.count : 0)} displayType={'text'} thousandSeparator={true} prefix={'$'} />;
            ToolTip = <CustomCurrencyTooltip />;
            break;
        case 'Revenue':
            countWrite = <CurrencyFormat decimalScale={2} fixedDecimalScale={true} value={(props.data.count !== null ? props.data.count : 0)} displayType={'text'} thousandSeparator={true} prefix={'$'} />;
            ToolTip = <CustomCurrencyTooltip />;
            break;
        case 'CTR':
            countWrite = <NumericFormat value={(props.data.count !== null ? (props.data.count).toFixed(2) : 0)} displayType="text" suffix="%" />
            ToolTip = <CustomPercetageTooltip />;
            break;
        case 'Conversion Rate':
            countWrite = <NumericFormat value={(props.data.count !== null ? (props.data.count).toFixed(2) : 0)} displayType="text" suffix="%" />
            ToolTip = <CustomPercetageTooltip />;
            break;
        case 'CPC':
            countWrite = <CurrencyFormat decimalScale={2} fixedDecimalScale={true} value={(props.data.count !== null ? props.data.count : 0)} displayType={'text'} thousandSeparator={true} prefix={'$'} />;
            ToolTip = <CustomCurrencyTooltip />;
            break;
        default:
            countWrite = <NumericFormat decimalScale={0} value={(props.data.count !== null ? props.data.count : 0)} displayType={'text'} thousandSeparator={true} />
            ToolTip = <CustomTooltip />;
            break;
    }






    return (
        <div>
            <ThemeProvider theme={theme}>

                {(props ?
                    <Card>
                        <CardHeader
                            subheader={props.title}
                            title={countWrite}
                            subheaderTypographyProps={{ style: { color: lightenHexColor(props.color, 40), marginLeft: theme.spacing(1) } }}
                            style={{ padding: theme.spacing(1), backgroundColor: lightenHexColor(props.color, 0), color: "#fff" }}
                        ></CardHeader>

                        <CardContent

                        >
                            <ResponsiveContainer width="100%" height={150}>
                                <AreaChart
                                    width={(1500 / 4) - theme.spacing(1)}
                                    height={200}
                                    data={props.data.series}
                                >
                                    <Tooltip content={ToolTip} />
                                    <Area type="monotone" dataKey="count" stroke={props.color} fill={lightenHexColor(props.color, 40)} />


                                </AreaChart>
                            </ResponsiveContainer>
                        </CardContent>
                        <CardActionArea>
                            <CardActions>
                                <div><NumericFormat value={(props.data.delta !== undefined && props.data.delta !== null ? (props.data.delta).toFixed(2) : 0)} displayType="text" suffix="%" /> {(props.data.delta < 0 ? <ArrowDownwardOutlined color="error" ></ArrowDownwardOutlined> : <ArrowUpwardOutlined style={{ color: "green" }} ></ArrowUpwardOutlined>)}</div>


                            </CardActions>
                        </CardActionArea>
                    </Card>
                    : '')}
            </ThemeProvider>
        </div>
    );
};

ChartCard.propTypes = {

};

export default ChartCard;
