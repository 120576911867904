import { AppBar, Button, Divider, Grid, TextField, Paper, Typography, LinearProgress, Tab, Tabs } from '@material-ui/core';
import { React, useEffect, useState } from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import appVars from '../appVars.json';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FileUploads from '../settings/file-uploads';

const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
var id = getLastItem(window.location.href);


function darkenHexColor(hex, percent) {

    if (!hex) {
        return "#b8b8b8"
    }


    // Remove the '#' if present
    hex = hex.replace(/#/g, '');

    // Convert hex to RGB
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Calculate the darkened RGB values
    const delta = 255 * (percent / 100);
    const newR = Math.max(0, r - delta);
    const newG = Math.max(0, g - delta);
    const newB = Math.max(0, b - delta);

    // Convert darkened RGB back to hex
    const darkenedHex = `#${Math.round(newR).toString(16).padStart(2, '0')}${Math.round(newG).toString(16).padStart(2, '0')}${Math.round(newB).toString(16).padStart(2, '0')}`;

    return darkenedHex;
}

function lightenHexColor(hex, percent) {

    if (!hex) {
        return "#b8b8b8"
    }


    // Remove the '#' if present
    hex = hex.replace(/#/g, '');

    // Convert hex to RGB
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Calculate the lightened RGB values
    const delta = 255 * (percent / 100);
    const newR = Math.min(255, r + delta);
    const newG = Math.min(255, g + delta);
    const newB = Math.min(255, b + delta);

    // Convert lightened RGB back to hex
    const lightenedHex = `#${Math.round(newR).toString(16).padStart(2, '0')}${Math.round(newG).toString(16).padStart(2, '0')}${Math.round(newB).toString(16).padStart(2, '0')}`;

    return lightenedHex;
}

const reports = [
    {
        "name": "Monthly Report",
        "url": "/monthly-report/" + id
    },
    {
        "name": "Global Report",
        "url": "/global-report/" + id
    }
];

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}





const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

        maxWidth: 1600,
        margin: 'auto'
    },
    data: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    content: {
        marginTop: 0,
        [theme.breakpoints.up('sm')]: {
            marginTop: 0,
        },
    }
}));




const Reports = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [tabs, setTabs] = useState({});
    const [hasError, setErrors] = useState(false);
    const [apiStats, setApiStats] = useState({
        "client_id": "",
        "total_records": 0,
        "name_percentage": 0,
        "city_percentage": 0,
        "state_percentage": 0,
        "zip_percentage": 0,
        "phone_percentage": 0,
        "email_percentage": 0,
        "fb_percentage": 0,
        "fbc_percentage": 0,
        "ga_percentage": 0,
        "sa_percentage": 0,
        "adwords_percentage": 0,
        "bing_percentage": 0
    });
    const [settings, setSettings] = useState({});
    const [profile, setProfile] = useState({});
    const [adsets, setAdsets] = useState({});
    const [saveMsg, setSaveMsg] = useState("");
    const [saveSeverity, setSeverity] = useState("success");
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [tabsettingsOpen, setTabSettingsOpen] = useState(false);
    const MyInput = ({ field, form, ...props }) => {
        return <TextField shrink {...field} {...props} />;
    };



    const handleSettingsOpen = () => {
        setSettingsOpen(true);
    };

    const handleTabSettingsOpen = () => {
        setTabSettingsOpen(true);
    };

    const handleClose = () => {
        setSettingsOpen(false);
        setTabSettingsOpen(false);
    };

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {

        console.log(profile);

        settings.ad_sets[event.currentTarget.id].id = event.currentTarget.value;
        profile.settings = settings;
        setProfile(profile);
        setSettings(settings);

    };

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleColorChange = (event, newValue) => {
        profile.color = event.currentTarget.value;
        profile.settings = settings;
        setProfile(profile);
        // console.log(profile);
    };


    const childToParent = (childdata) => {
        var f = appVars.api_url + '/imageFiles/' + id + '.png';
        const img = document.getElementById('avatar');
        img.src = f; console.log(f);
    };


    async function fetchData() {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "cid": props.client_id
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };




        const res2 = await fetch(appVars.api_url + "/apistats", requestOptions)
        res2
            .json()
            .then((res2) => {
                (res2 === null ? setApiStats({}) : setApiStats(res2))

            })
            .catch((err) => setErrors(true));






        requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };


        const rp = await fetch(appVars.api_url + "/reportprofile/" + id, requestOptions);
        rp
            .json()
            .then((res3) => {

                if (res3 !== null) {



                    setProfile(res3);
                    setSettings(JSON.parse(res3.settings));
                    setAdsets(JSON.parse(res3.settings));
                    setTabs(JSON.parse(res3.tabs))
                    // console.log(settings);

                } else {
                    profile.color = "#51704b";
                    profile.clientid = id;
                    profile.client = props.client
                    profile.settings = { "ad_sets": { "google_paid": { "id": "0" }, "google_grant": { "id": "0" }, "meta": { "id": "0" }, "bing": { "id": "0" }, "stackadapt": { "id": "0" } } }
                    profile.tabs = {"tabs":{"google_paid":{"label":"Google Paid","charts":{"impressions":1,"clicks":1,"ctr":1,"cpc":1,"conversions":1,"revenue":1,"conv_rate":1,"cost_conversion":1,"searchTerms":1,"region":0,"comment":1}},"google_grant":{"label":"Google Grant","charts":{"impressions":1,"clicks":1,"ctr":1,"cpc":1,"conversions":1,"revenue":1,"conv_rate":1,"cost_conversion":1,"searchTerms":1,"region":0,"comment":1}},"meta":{"label":"Meta","charts":{"impressions":1,"clicks":1,"ctr":1,"cpc":1,"conversions":1,"revenue":1,"conv_rate":1,"cost_conversion":1,"searchTerms":1,"region":0,"comment":1}},"bing":{"label":"Bing","charts":{"impressions":1,"clicks":1,"ctr":1,"cpc":1,"conversions":1,"revenue":1,"conv_rate":1,"cost_conversion":1,"searchTerms":1,"region":0,"comment":1}},"stackadapt":{"label":"Stackadapt","charts":{"impressions":1,"clicks":1,"ctr":1,"cpc":1,"conversions":1,"revenue":1,"conv_rate":1,"cost_conversion":1,"searchTerms":1,"region":0,"comment":1}}}}
                    setTabs( profile.tabs);


                    setProfile(profile);
                    setSettings(profile.settings);
                    setSettingsOpen(true);
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });










    }


    useEffect(() => {


        if (loading) {
            fetchData();
        }



    });
    return (
        <div style={{ marginLeft: theme.spacing(1) }}>


            {(profile ?
                <Grid container spacing={2}>
                    <Grid item sm={6}>
                        <Button onClick={handleSettingsOpen} fullWidth style={{ color: theme.palette.primary.contrastText, backgroundColor: profile.color, marginBottom: theme.spacing(1) }} variant="outlined">Ad Settings</Button>


                    </Grid>

                    <Grid item sm={6}>
                        <Button onClick={handleTabSettingsOpen} fullWidth style={{ color: theme.palette.primary.contrastText, backgroundColor: profile.color }} variant="outlined">Report Tabs</Button>


                    </Grid>

                    <Grid item sm={12} >
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography variant="h5" gutterBottom style={{ color: profile.color, borderBottom: "1px solid #000" }}>API Stats</Typography>
                                <Grid container spacing={3}>


                                    <Grid item xl={2} sm={3}>
                                        <Paper style={{ color: "#fff", backgroundColor: lightenHexColor(profile.color, 30) }} className={classes.paper}>
                                            <Typography align="center" gutterBottom variant="body1">Meta Users:</Typography>
                                            <Typography align="center" gutterBottom variant="body2">{parseFloat(apiStats.fb_percentage).toFixed(2)}%</Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xl={2} sm={3}>
                                        <Paper style={{ color: "#fff", backgroundColor: lightenHexColor(profile.color, 30) }} className={classes.paper}>
                                            <Typography align="center" gutterBottom variant="body1">Meta Clickers:</Typography>
                                            <Typography align="center" gutterBottom variant="body2">{parseFloat(apiStats.fbc_percentage).toFixed(2)}%</Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xl={2} sm={3}>
                                        <Paper style={{ color: "#fff", backgroundColor: lightenHexColor(profile.color, 30) }} className={classes.paper}>
                                            <Typography align="center" gutterBottom variant="body1">Google Users:</Typography>
                                            <Typography align="center" gutterBottom variant="body2">{parseFloat(apiStats.ga_percentage).toFixed(2)}%</Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xl={2} sm={3}>
                                        <Paper style={{ color: "#fff", backgroundColor: lightenHexColor(profile.color, 30) }} className={classes.paper}>
                                            <Typography align="center" gutterBottom variant="body1">Google Clickers:</Typography>
                                            <Typography align="center" gutterBottom variant="body2">{parseFloat(apiStats.adwords_percentage).toFixed(2)}%</Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xl={2} sm={3}>
                                        <Paper style={{ color: "#fff", backgroundColor: lightenHexColor(profile.color, 30) }} className={classes.paper}>
                                            <Typography align="center" gutterBottom variant="body1">Stackadapt Users:</Typography>
                                            <Typography align="center" gutterBottom variant="body2">{parseFloat(apiStats.sa_percentage).toFixed(2)}%</Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xl={2} sm={3}>
                                        <Paper style={{ color: "#fff", backgroundColor: lightenHexColor(profile.color, 30) }} className={classes.paper}>
                                            <Typography align="center" gutterBottom variant="body1">Bing Users:</Typography>
                                            <Typography align="center" gutterBottom variant="body2">{parseFloat(apiStats.bing_percentage).toFixed(2)}%</Typography>
                                        </Paper>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item sm={12} >
                                <Typography variant="h5" gutterBottom style={{ color: profile.color, borderBottom: "1px solid #000" }}>Reports</Typography>
                                <Grid container spacing={3}>

                                    {reports?.map(option => {
                                        return (
                                            <Grid item xl={2} sm={4}><Button style={{ color: "#fff", background: profile.color }} variant="contained" target='_BLANK' fullWidth href={option.url}>{option.name}</Button></Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>

                        </Grid>

                    </Grid>
                </Grid>
                : <LinearProgress style={{ margin: theme.spacing(8) }}></LinearProgress>)}


            <Dialog
                open={settingsOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle style={{ background: lightenHexColor(profile.color, 10) }} id="alert-dialog-title">{<Typography variant="h5" gutterBottom>Ad Settings</Typography>}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <img id="avatar" src="" alt='' />
                        <FileUploads newid={id} childToParent={childToParent} ></FileUploads>
                        <MyInput size="small" onChange={handleColorChange} style={{ marginBottom: theme.spacing(1) }} id="color" name="color" defaultValue={profile.color} placeholder="Brand Color" label="Brand Color" fullWidth />
                        <MyInput onChange={handleChange} size="small" style={{ marginBottom: theme.spacing(1) }} id="meta" name="meta" placeholder="" label="META Id" defaultValue={(settings.ad_sets ? settings.ad_sets.meta.id : '')} fullWidth />
                        <MyInput onChange={handleChange} size="small" style={{ marginBottom: theme.spacing(1) }} id="stackadapt" name="stackadapt" placeholder="" label="StackAdpat Id" defaultValue={(settings.ad_sets ? settings.ad_sets.stackadapt.id : '')} fullWidth />
                        <MyInput onChange={handleChange} size="small" style={{ marginBottom: theme.spacing(1) }} id="bing" name="bing" placeholder="" label="Bing Id" defaultValue={(settings.ad_sets ? settings.ad_sets.bing.id : '')} fullWidth />
                        <MyInput onChange={handleChange} size="small" style={{ marginBottom: theme.spacing(1) }} id="google_paid" name="google_paid" placeholder="" label="Google Paid Id" defaultValue={(settings.ad_sets ? settings.ad_sets.google_paid.id : '')} fullWidth />
                        <MyInput onChange={handleChange} size="small" style={{ marginBottom: theme.spacing(1) }} id="google_grant" name="google_grant" placeholder="" label="Google Grant Id" defaultValue={(settings.ad_sets ? settings.ad_sets.google_grant.id : '')} fullWidth />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        style={{ color: theme.palette.primary.contrastText, backgroundColor: darkenHexColor(profile.color, 20) }}
                        onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        style={{ color: theme.palette.primary.contrastText, backgroundColor: profile.color }}
                        onClick={(e) => {

                            //console.log(JSON.stringify(profile));

                            var data = profile;

                            delete data.updatedAt;
                            delete data.createdAt;
                            data.settings = JSON.stringify(profile.settings);
                            data.tabs = JSON.stringify(profile.tabs);

                            //setProfile(profile);
                            var myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/json");
                            var raw = JSON.stringify(data);

                            var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: raw,
                                redirect: 'follow'
                            };

                            fetch(appVars.api_url + "/reportprofile/", requestOptions)
                                .then(response => response.text())
                                .then(result => setSettingsOpen(false))
                                // .then(result =>window.location.reload()) 
                                .catch(error => console.log('error', error));







                        }}

                        variant="outlined">Save</Button>
                </DialogActions>
            </Dialog>




            <Dialog
                open={tabsettingsOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle style={{ background: lightenHexColor(profile.color, 10) }} id="alert-dialog-title">{<Typography variant="h5" gutterBottom>Tab Settings</Typography>}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        <AppBar position="relative" variant="outlined" >
                            {(tabs.tabs ?
                                <Tabs orientation="horizontal" variant="scrollable" value={value} onChange={handleTabChange} aria-label="simple tabs example">
                                    {Object.entries(tabs.tabs).map((tab, index) => {
                                        // console.log(tab);
                                        return (
                                            <Tab label={tab[1]['label']} {...a11yProps(index)} />
                                        )
                                    })}


                                </Tabs>
                                : <LinearProgress></LinearProgress>
                            )}



                        </AppBar>


                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        style={{ color: theme.palette.primary.contrastText, backgroundColor: darkenHexColor(profile.color, 20) }}
                        onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        style={{ backgroundColor: profile.color }}
                        onClick={(e) => {
                            console.log(profile);
                        }}

                        variant="outlined">Save</Button>
                </DialogActions>
            </Dialog>








        </div>
    );
};

Reports.propTypes = {

};

export default Reports;