import { Grid, TextField, Typography } from '@material-ui/core';
import { React, useState, useEffect } from 'react';
import { style } from './index.scss';
import appVars from "../../appVars.json";

const Add = (props) => {
    const [projects, setProjects] = useState({});

    async function fetchData() {

        var url = "https://accounts.zoho.com/oauth/v2/token?refresh_token=" + appVars.zoho.refresh + "&client_id=" + appVars.zoho.clientID + "&client_secret=" + appVars.zoho.secret + "&grant_type=refresh_token"

        var requestOptions = {
            method: 'POST',
          //  mode: "no-cors",
        };

        const res = await fetch(url, requestOptions)
            .then(response => {
               console.log(response);
            })
            .then(result => console.log(result))
            .catch(error => console.log('error', error));



    }

    useEffect(() => {


        fetchData();

    }, []);



    return (
        <div style={{ marginTop: '1em', marginLeft: '1em' }} className={style}>

            <Typography gutterBottom variant="h2" color="textPrimary">Add New Project</Typography>

            <Grid container spacing={4} xl={12}>
                <Grid item xl={4}>
                    <TextField fullWidth type="text" label='Use this template'></TextField>
                </Grid>
                <Grid item xl={4}>
                    <TextField type="text" fullWidth label='Image Location'></TextField>

                </Grid>
                <Grid item xl={4}>
                    <TextField type="text" fullWidth label='File location'></TextField>

                </Grid>
            </Grid>



        </div>
    );
};

Add.propTypes = {

};

export default Add;
