import React, { useState, useEffect } from 'react';
import { style } from './index.scss';
import { Grid, Tab, Tabs, Link, Box, Typography, Snackbar, Paper, Button, TextField, MenuItem, Badge } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import MainMenu from '../DashBoard/MainMenu';
import appVars from '../appVars.json';
import { makeStyles } from '@material-ui/core';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import MaterialTable from 'material-table';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import 'date-fns';
import { useTheme } from '@material-ui/core/styles';
import themeFile from '../theme.json';
import PropTypes from 'prop-types';
import PrimaryClient from './primary-client';
import { Check, Error, Money, ViewAgendaOutlined } from '@material-ui/icons';
import ErrorView from '../ErrorView';

var _ = require('lodash');


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}





function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        margintop: theme.spacing(2)
    },
    h4: {
        marginBottom: theme.spacing(2)
    },
    paper: {
        padding: '1em',
        margin: '1em 0',
        background: "transparent",
        textAlign: "center",
        color: "white"
    },
    paperError: {
        background: themeFile.palette.feedback.error.background,
        textAlign: "center",
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        paddingTop: ".5em"
    },
    paperSuccess: {
        background: themeFile.palette.feedback.success.background,
        textAlign: "center",
        marginBottom: "1em",
        padding: "0em"
    },
    revenue: {
        backgroundColor: themeFile.palette.secondary.main,
        padding: "1em",
        color: "white",
        borderRadius: "7px"
    },
    appBar: {
        background: themeFile.palette.primary.main,
        color: '#fff',
        padding: '0!important'
    },
}));

const DashBoard = (props) => {

    //const classes = useStyles();
    const classes = useStyles();
    const [error, setErrors] = useState({ status: !1, msg: "No Error!", severity: "error" });
    const [users, setUsers] = useState([]);
    const [errorlog, setErrorLog] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorcount, setErrorCount] = useState(0);
    const [conversions, setConverions] = useState([]);
    const [last5, setLast5] = useState();
    const [refreshInterval, setRefreshInterval] = useState(10000); // Adjust the interval as needed (in milliseconds)
    const [conversionsCount, setConverionsCount] = useState([]);
    const [conversionsFailed, setConverionsFailed] = useState({ count: 0 });
    const [selectedDateS, setSelectedDateS] = React.useState(new Date().toISOString().split('T')[0]);
    const [selectedDateE, setSelectedDateE] = React.useState(new Date().toISOString().split('T')[0]);
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    const handleDateChangeS = (date) => {
        setSelectedDateS(date.toISOString().split('T')[0]);
        fetchData();
    };


    const handleDateChangeE = (date) => {
        setSelectedDateE(date.toISOString().split('T')[0]);
        fetchData();
    };


    const tableOptions = {
        showTitle: false,
        filtering: false,
        pageSize: 50,
        pageSizeOptions: [50, 100]

    }


    async function fetchData() {
        const res = await fetch(appVars.api_url + "/clients");
        res
            .json()
            .then((res) => {
                setUsers(res);
                // console.log(res);
            })
            //  .then(res => setLoading(false))
            .catch(err => setErrors({
                status: true,
                msg: err.message,
                severity: "info"
            }));

        const res2 = await fetch(appVars.api_url + "/convstats");
        res2
            .json()
            .then((res2) => {
                var d = _.filter(res2, function (o) {
                    var e = new Date(o.dates).toISOString().split('T')[0];
                    e = o.dates;
                    if (e >= selectedDateS && e <= selectedDateE) {
                        return o.dates;
                    }
                });
                setConverions(d);
                setConverionsCount(d.length)

            })
            .then(res => setLoading(false))
            .catch(err => setErrors({
                status: true,
                msg: err.message,
                severity: "error"
            }));



        const res3 = await fetch(appVars.api_url + "/conversions/failed");
        res3
            .json()
            .then((res2) => {
                setConverionsFailed(res2);
                //  console.log(res2);
                // setLoading(false);
            })
            .catch((err) => setErrors(true));







    }

    async function fetchStats() {
        const res4 = await fetch(appVars.api_url + "/apiStatus");
        res4
            .json()
            .then((res4) => {
                setLast5(res4);
                // console.log(res4);
                setLoading(false);
            })
            .catch((err) => setErrors(true));
    }






    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className={classes.revenue}>
                    <Typography variant='h6'>{`${label}`}</Typography>
                    <Typography variant='body2'>
                        <span>{`Conversions: ${payload[0].value}`}</span><br></br>
                        <span>{`Revenue: $${payload[0].payload['revenue']}`}</span></Typography>
                </div>
            );
        }

        return null;
    };


    useEffect(() => {
        //   fetchData();
        document.title = "Main Dashboard";
        //   const intervalId = setInterval(fetchStats, refreshInterval);
        //  return () => clearInterval(intervalId);
    }, [refreshInterval]);




    return (
        <div className={style} >
            <div className={classes.root}>

                <Grid container xs={12} spacing={0} direction="row-reverse" >
                    <Grid item xl={2} md={3} xs={12} style={{ marginTop: theme.spacing(1), paddingLeft: 7, paddingRight: 7, borderLeft: "1px solid #e4e4e4", height: "calc(100vh - 70px)" }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">
                                <DatePicker
                                    fullWidth
                                    inputVariant="outlined"
                                    variant="inline"
                                    format="yyyy-MM-dd"
                                    margin="dense"
                                    id="date-picker-inline"
                                    label="Start Report Date"
                                    value={selectedDateS}
                                    onChange={handleDateChangeS}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />

                            </Grid>
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">
                                <DatePicker
                                    fullWidth
                                    inputVariant="outlined"
                                    variant="inline"
                                    format="yyyy-MM-dd"
                                    margin="dense"
                                    id="edate-picker-inline"
                                    label="Report End Date"
                                    value={selectedDateE}
                                    onChange={handleDateChangeE}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />

                            </Grid>
                        </MuiPickersUtilsProvider>

                        <TextField select
                            type="number"
                            size='small'
                            fullWidth
                            variant="outlined"
                            label="Refresh Interval"
                            style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
                            value={refreshInterval}
                            onChange={(e) => {
                                console.log(Number(e.target.value));
                                setRefreshInterval(Number(e.target.value));
                            }}
                        >
                            <MenuItem value={1000}>1s</MenuItem>
                            <MenuItem value={5000}>5s</MenuItem>
                            <MenuItem value={10000}>10s</MenuItem>
                            <MenuItem value={30000}>30s</MenuItem>
                            <MenuItem value={60000}>1 min</MenuItem>
                        </TextField>


                    </Grid>
                    <Grid item xl={10} md={9} xs={12}>


                        <Grid item xs={12} md={12} >
                            <Box sx={{ marginTop: "0em", marginBottom: theme.spacing(0), borderBottom: 1, borderColor: 'divider' }} className={classes.appBar}>
                                <Tabs value={value} onChange={handleChange} variant="scrollable" aria-label="basic tabs example" >
                                    <Tab icon={<ViewAgendaOutlined></ViewAgendaOutlined>} label="Overview" {...a11yProps(0)} />
                                    <Tab icon={<Money></Money>} label="Revenue" {...a11yProps(1)} />
                                    <Tab icon={<Check></Check>} label="Conversions" {...a11yProps(2)} />
                                    <Tab icon={<Error></Error>} label={<Badge badgeContent={errorcount} color="secondary">Errors</Badge>} {...a11yProps(3)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <Grid container >
                                    <Grid item xl={6} xs={12}>
                                        <PrimaryClient></PrimaryClient>

                                    </Grid>
                                    <Grid item xl={6} xs={12} style={{ padding: theme.spacing(1) }}>
                                        <Typography variant="h5" color='primary' style={{ borderBottom: '1px solid', borderBottomColor: theme.palette.primary.main }} gutterBottom>Status</Typography>
                                        {(last5 ?
                                            <MaterialTable
                                                data={last5}
                                                isLoading={loading}
                                                style={{ boxShadow: 'none', border: '1px solid #ccc' }}
                                                options={{ header: false, search: false, showTitle: false, paging: false, toolbar: false, loadingType: "linear" }}
                                                columns={[
                                                    {
                                                        title: "Name", field: "pkRecId", hidden: false, cellStyle: { padding: "3px 10px", width: '90%' }
                                                        , render: rowData => <div>
                                                            <Link style={{ fontSize: '12px' }} title={rowData.createdAt} href={'subclientviewer/' + rowData.id}>{rowData.name}</Link>
                                                        </div>
                                                    },
                                                    {
                                                        title: "Client Name", hidden: true, field: "clientname", cellStyle: { padding: "3px 10px" }
                                                    },
                                                    {
                                                        title: "$$", field: "revenue", type: "currency", cellStyle: { fontSize: '12px', padding: "3px 10px", width: '10%' }
                                                    },
                                                    {
                                                        title: "Time", field: "createdAt", hidden: true, defaultSort: 'desc', type: "datetime", cellStyle: { padding: "3px 10px" }
                                                    }
                                                ]}
                                            />
                                            : '')}
                                    </Grid>


                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Grid item xs={12}>

                                    <BarChart
                                        style={{ background: "transparent" }}
                                        width={900}
                                        height={600}
                                        data={conversions}
                                        margin={{
                                            top: 25,
                                            right: 25,
                                            left: 25,
                                            bottom: 25,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis domain={[0, 7500]} />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Bar dataKey="revenue" label fill={themeFile.palette.secondary.main} />
                                    </BarChart>

                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={2}>

                                <Grid container xs={12} spacing={1}>
                                    <Grid item xs={12}>



                                    </Grid>
                                </Grid>
                            </TabPanel>

                            <TabPanel value={value} index={3}>
                                <Grid container xs={12} spacing={1}>
                                    <Grid item xs={12}>
                                        <ErrorView></ErrorView>
                                    </Grid>
                                </Grid>
                            </TabPanel>


                        </Grid>

                    </Grid>

                </Grid>
                <Snackbar open={error.status} autoHideDuration={3500} >
                    <Alert severity={error.severity}>
                        {error.msg}
                    </Alert>
                </Snackbar>
                <MainMenu></MainMenu>
            </div>
        </div>
    );
};

DashBoard.propTypes = {

};

export default DashBoard;
