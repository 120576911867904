import React, { useState, useEffect } from "react";
import {getUrlParameter} from '../../dwi-functions';
import appVars from '../../appVars.json';

const { createCanvas, loadImage } = require('canvas');
const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1);
var debug =getUrlParameter('debug');
var id = window.location.pathname.split('/')[2];
var msg = getUrlParameter('msg');











const Igen = (props) => {

const [image,setImage] = useState({
    rgbColor: '[255,255,255]'
});


   /*
    var timer = timers[0];

    loadImage('./uploads/' + timer.imageName).then(image => {
        const width = image.width
        const height = image.height

        const canvas = createCanvas(width, height)
        const context = canvas.getContext('2d')

        context.drawImage(image, 0, 0, width, height)
        context.font = 'regular ' + timer.fontSize + 'pt Menlo'
        context.textAlign = 'left'
        context.textBaseline = 'top'
        //  context.fillStyle = '#3574d4'

        const r = JSON.parse(timer.rgbColor);
       // console.log(r);
        const text = req.params.msg

        const textWidth = context.measureText(text).width
        // context.fillRect(600 - textWidth / 2 - 10, 170 - 5, textWidth + 20, 120)
        context.fillStyle = '#' + rgbtohex(r[0], r[1], r[2])
        context.fillText(text, (width / 2) - textWidth / 2, timer.yOffset)
        context.font = 'bold ' + timer.fontSize + 'pt Menlo'
        const buffer = canvas.toBuffer('image/png')
        res.set('Content-Type', 'image/png');
        res.send(buffer);
    })
*/

function rgbtohex(r, g, b) {
    //RGB Code validation/ conversion
    const rgbToHex = function (arg) {
        let x = arg;
        //validate rgb values
        if (x < 0) {
            x = 0;
        } else if (x > 255) {
            x = 255;
        }
        //turn each rgb value into a hex value
        let hex = Number(x).toString(16);
        //ensure there are 2 digits in each hex value
        if (hex.length < 2) {
            hex = "0" + hex;
        }
        //return hexcode
       // console.log(hex);
        return hex;
    };

    let red = rgbToHex(r);
    let green = rgbToHex(g);
    let blue = rgbToHex(b);

    let hexColor = red + green + blue;
   // console.log(hexColor.toUpperCase())
    return hexColor.toUpperCase();
};

async function fetchData() {
    const res = await fetch(appVars.api_url+"/igen/"+id);
    res
      .json()
    
      .then((res) => {
          setImage(res[0]);
          console.log(image);
    })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    fetchData();
  }, []);

  const r = JSON.parse(image.rgbColor);

    return (

        (debug==='1'  
            ? 
        <div>
         RGB Color: #{rgbtohex(r[0],r[1],r[2])}<br></br>
         X Offset: {image.xOffset} <br></br>Y Offset: {image.yOffset}
        </div>
         :
         <div>
        </div>
         )
    );
};

Igen.propTypes = {

};

export default Igen;
