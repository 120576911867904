import { Grid, Typography, Button, Snackbar, AppBar, Tab, Tabs, Box, Breadcrumbs, Link, Toolbar, Paper } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { style } from './index.scss';
import appVars from '../../appVars.json';
import Alert from "@material-ui/lab/Alert";
import ClipboardJS from "clipboard";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import themeFile from '../../theme.json';
import SubClientList from '../sub-client-list';
import { CancelOutlined, Save, Settings, SupervisedUserCircleSharp } from '@material-ui/icons';
import { BsPrinter } from 'react-icons/bs';
import { Formik, Form, Field } from 'formik';
import { TextField } from '@material-ui/core/';
import Users from '../../UserTable';
import { RiUser2Fill } from 'react-icons/ri';
import FileUploads from '../../settings/file-uploads';
import { useTheme } from '@material-ui/core/styles';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};





const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  table: {
    marginTop: "1em",
  },
  divider: {
    marginTop: "1em"
  },
  title: {
    borderBottom: "1px solid #000",
  },
  pos: {
    marginBottom: 12,
  },
  clientLogo: {
    maxWidth: "100%",
    marginTop: "1em",
    marginBottom: "1em"
  },
  address: {
    marginTop: "1em",
    marginBottom: "1em"
  },
  appBar: {
    background: themeFile.palette.primary.dark
  },
  paper: {
    padding: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  paperSide: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  media: {
    height: "auto",
    maxWidth: "100px",
    margin: theme.spacing(.5),
    backgroundSize: "auto"
  },
  icon: {
    margin: '0px!important',
  },
  tab: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputs: {
    marginBottom: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    minWidth: 120,
      width: 'calc(100%)',
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(0),
        paddingRight: theme.spacing(0),
      }
    
  },
  button: {
    marginRight: theme.spacing(1),
    paddingRight: theme.spacing(0),
    minWidth: 120,
      width: 'calc(10%)',
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100%)',
        marginBottom: theme.spacing(0),
        paddingRight: theme.spacing(0),
      }
    
  },
  state:{
    marginBottom: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    width: 'calc(45%)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: theme.spacing(0),
      paddingRight: theme.spacing(0),
    }
  },
  zip: {
    width: 'calc(8%)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  toolbar: {
    margin: '0 0',
    background: theme.palette.text.hint,
    padding: '0 15px',
    color: theme.palette.primary.contrastText
  }

}));

const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
const id = getLastItem(window.location.href);
//console.log(id);

const SingleClient = (props) => {
  const classes = useStyles();
  const [hasError, setErrors] = useState(false);
  const [copySuccessShow, setCopySuccessShow] = useState(false);
  const theme = useTheme();
  const [client, setClient] = useState([{
    "clientname": 'Default Name'
  }]);
  const [clientData, setClientData] = useState([{
    "clientname": 'Default Name'
  }]);

  const [value, setValue] = React.useState(0);

  const MyInput = ({ field, form, ...props }) => {
    return <TextField size='small' variant="outlined" shrink {...field} {...props} />;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrors(false);
    setCopySuccessShow(false);
  };



  const childToParent = (childdata) => {
    var f = appVars.api_url + '/imageFiles/' + id + '.png';
    const img = document.getElementById('avatar');
    img.src = f; console.log(f);
  };


  async function fetchData() {
    const res = await fetch(appVars.api_url + "/clients/" + id);
    res
      .json()
      .then(res => {
        setClient(res);
        setClientData(res[0]);
        //        console.log(res);
      })
      .catch(err => setErrors(true));
  }


  useEffect(() => {
    fetchData();
  }, []);




  return (
    <div className={style}>






      <AppBar color="primary" position="static" fullWidth>

        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" orientation="horizontal" variant="scrollable" indicatorColor="secondary" >

          <Tab
            className={classes.tab}
            icon={<SupervisedUserCircleSharp className={classes.icon} ></SupervisedUserCircleSharp>}
            label="Active Client List" />
          <Tab
            className={classes.tab}
            icon={<SupervisedUserCircleSharp className={classes.icon} ></SupervisedUserCircleSharp>}
            label="in-active Client List" />
          <Tab
            className={classes.tab}
            icon={<RiUser2Fill className={classes.icon}></RiUser2Fill>}
            label="Account Team" />
          <Tab
            className={classes.tab}
            icon={<Settings className={classes.icon} ></Settings>}
            label="Settings" />



        </Tabs>

      </AppBar>

      <TabPanel value={value} index={0}>
        <Typography variant="body2">
          <Grid container spacing={0}>

            <Grid item xs={12}>
              <AppBar color="secondary" position="static" fullWidth>
                <Toolbar variant="regular" fullWidth>
                  <Breadcrumbs style={{ width: '100%', padding: '7px' }} aria-label="breadcrumb">
                    <Link color="textPrimary" href="/" >
                      Dashboard
                    </Link>
                    <Link color="textPrimary" href={"/clientview/" + client[0].clientId} >
                      {client[0].clientname}
                    </Link>
                    <Typography style={{ color: "#fff" }} >
                      Active Clients
                    </Typography>
                  </Breadcrumbs>
                </Toolbar>
              </AppBar>
              <SubClientList client={client[0].id} logo={client[0].logo} status={true}></SubClientList>

            </Grid>

          </Grid>
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Typography variant="body2">
          <Grid container xl={12} md={12} spacing={0}>

            <Grid item xs={12}>
              <Toolbar className={classes.toolbar} variant="regular" fullWidth>
                <Breadcrumbs style={{ width: '100%', padding: '7px' }} aria-label="breadcrumb">
                  <Link color="textPrimary" href="/" >
                    Dashboard
                  </Link>
                  <Link color="textPrimary" href={"/clientview/" + client[0].clientId} >
                    {client[0].clientname}
                  </Link>
                  <Typography color="primary">
                    In-Active Clients
                  </Typography>
                </Breadcrumbs>
              </Toolbar>
              <SubClientList client={client[0].id} logo={client[0].logo} status={false}></SubClientList>
            </Grid>

          </Grid>
        </Typography>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Typography variant="body2">
          <Grid container spacing={0}>
            <Grid item xs={12}>

              <Users clientid={client[0].id}></Users>
            </Grid>

          </Grid>
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Typography variant="body2">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <AppBar color="secondary" position="static" fullWidth>

                <Toolbar variant="regular" fullWidth>
                  <Breadcrumbs style={{ width: '100%', padding: '7px' }} aria-label="breadcrumb">
                    <Link color="textPrimary" href="/" >
                      Dashboard
                    </Link>
                    <Link color="textPrimary" href={"/clientview/" + client[0].clientId} >
                      {client[0].clientname}
                    </Link>
                    <Typography style={{ color: "#fff" }}
                    >
                      Settings
                    </Typography>
                  </Breadcrumbs>
                </Toolbar>
              </AppBar>
            </Grid>
          </Grid>
        </Typography>
        <Grid container spacing={1}>
          <Grid item xl={10} md={10}>

            <Paper className={classes.paper}>

              <Formik enableReinitialize
                initialValues={clientData}
                className={classes.root}
                onSubmit={async (values) => {
                  //console.log(values);
                  // return false;
                  await new Promise((r) => setTimeout(r, 500));
                  var url = appVars.api_url + "/gtm/update/";
                  if (values.pkRecId === "0") {
                    url = appVars.api_url + "/gtm/add/";
                    //url = "http://localhost:7325/gtm/add/";
                  }   //var url = "http://localhost:7325/gtm/add/";


                  const response = fetch(url, {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    body: JSON.stringify(values), // body data type must match "Content-Type" header,
                    headers: {
                      'Content-Type': 'application/json'
                    }
                  });
                  response
                    .then(users => {
                      // window.location.href='/clientview/'+clientData.client;

                      ////console.log(users.body);
                      fetchData();
                    }).err(err => {

                    })




                }}>
                <Form>
                  <div>
                    <Field component={MyInput} type="text" id="clientname" label="Display Name" fullWidth name="clientname" className={classes.inputs} helperText="Enter Display Name" />
                  </div>
                  <div>

                    <Field component={MyInput} type="text" id="address" label="Address" fullWidth name="address" className={classes.inputs} helperText="Enter street address" />
                  </div>
                  <div>

                    <Field component={MyInput} type="text" id="city" label="City"  name="city" className={classes.state} helperText="Enter City" />


                    <Field component={MyInput} type="text" id="state" label="State" name="state" className={classes.state} helperText="Enter State" />


                    <Field component={MyInput} type="text" id="zip" label="Zip" name="zip" className={classes.zip} helperText="Enter Zip" />
                  </div>

                  <div>

                    <Field component={MyInput} type="text" id="email" label="Email" name="email" className={classes.state} helperText="Enter Email" />


                    <Field component={MyInput} type="text" id="phone" label="Phone"  name="phone" className={classes.state} helperText="Enter Phone" />
                  </div>

                  <div>
                    <Button variant="contained" className={classes.button}  style={{ marginBottom: '7px' }}  size="medium" color="secondary" href={"/client/new/?clientid=" + id} startIcon={<Save></Save>}>Save</Button>
                    <Button variant="contained" className={classes.button}  style={{ marginBottom: '7px' }} size="medium" color="primary" startIcon={<BsPrinter ></BsPrinter>}>Print</Button>
                    <Button variant="contained" className={classes.button}  style={{ marginBottom: '7px' }} size="medium" color="default" startIcon={<CancelOutlined></CancelOutlined>}>Cancel</Button>
                  </div>
                </Form>
              </Formik>

            </Paper>
          </Grid>

          <Grid item xl={2} md={2}>
            <Paper className={classes.paperSide} >
              <img id='avatar' src={client[0].logo} className={classes.media} alt={client[0].clientId} />
              <FileUploads newid={id} childToParent={childToParent} ></FileUploads>
            </Paper>
          </Grid>

        </Grid>


      </TabPanel >




      <Snackbar open={hasError} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          The data can not be pulled at this time.
          <Button color="primary" onClick={window.location.reload}>
            Refresh
          </Button>
        </Alert>
      </Snackbar>


    </div >
  );
};

SingleClient.propTypes = {

};

export default SingleClient;
