import React from 'react';
import { style } from './index.scss';
import appVars from '../../appVars.json';
import themeFile from '../../theme.json';

const classes = {
    back: {
        background: "#fff",
        padding: "7px 15px",
        margin: "0"
    },
    foo: {
        border: "1px solid #c4c4c4"
    },
    button: {
        background: themeFile.palette.primary.light,
        color: "white",
        textDecoration: 'none',
        padding: "15px 30px",
        textAlign: "center",
        display: "block",
        margin: "0"
    },
    header: {
        background: themeFile.palette.secondary.light,
        padding: '7px 15px',
        textAlign: "center",
        margin: "0"
    }
}

const PasswordReset = (props) => {

    return (

        <div style={classes.foo}>
            <h1 style={classes.header}>LightWerks Login</h1>
            <div style={classes.back}>
                <h3>Having Trouble Signing in?</h3>
                <p>Resetting your password is easy. Just press the button below and follow the instructions. We'll have you up and running in no time.</p>
            </div>
            <a style={classes.button} href={appVars.lightwerksUrl + "reset-password/" + props.id}>Reset Password</a>
        </div>
    );
};

PasswordReset.propTypes = {

};

export default PasswordReset;
