import { Card, CardContent, CardHeader, Grid, Typography, LinearProgress, Toolbar } from '@material-ui/core';
import { React, useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import themeFile from '../../theme.json';
import ChartCard from '../chart-card';
import { NumericFormat } from 'react-number-format';
import TableCard from '../table-card';
import { lightenHexColor } from '../../dwi-functions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import appVars from '../../appVars.json';

import _ from 'lodash';
import Comments from '../../system/control/comments';



const theme = createTheme(themeFile);
var CurrencyFormat = require('react-currency-format');


const fontSize = 'initial';





const Google = (props) => {
    const [creativeData, setCreativeData] = useState([]);
    const [topConversionData, setTopConversionData] = useState([]);
    const [adNetworkData, setAdNetworkData] = useState([]);
    const [searchData, setsearchData] = useState([]);
    const [keywordData, setkeywordData] = useState([]);
    const [campaignData, setCampaignData] = useState([{ "name": 'props.title' }]);
    const [data, setData] = useState();
    const [dataType, setDataType] = useState([{ CAMPAIGN_TYPE: "SEARCH" }]);
    const [loading, setloading] = useState(true);
    //console.log(props);

    async function fetchReportData() {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "aid": props.advertiser_id,
            "sdate": props.sDate,
            "edate": props.eDate,
            "account": props.which,
            "type": dataType
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };


        setAdNetworkData([{ CAMPAIGN_TYPE: "SEARCH" }, { CAMPAIGN_TYPE: "YOUTUBE" }]);


        const res4 = await fetch(appVars.api_url + "/google/paid/data", requestOptions);
        //    console.log(props);
        res4
            .json()
            .then((res4) => {
                setData(res4);

            })
            .catch((err) => {
                console.log(err);
            });

        const campTable = await fetch(appVars.api_url + "/google/paid/campaigns", requestOptions);
        //    console.log(props);
        campTable
            .json()
            .then((campaigns) => {
                setCampaignData(campaigns);

            })
            .catch((err) => {
                console.log(err);
            });


        const keywordTable = await fetch(appVars.api_url + "/google/paid/keywords", requestOptions);
        //    console.log(props);
        keywordTable
            .json()
            .then((keywords) => {
                setkeywordData(keywords);

            })
            .catch((err) => {
                console.log(err);
            });



        const searchTable = await fetch(appVars.api_url + "/google/paid/search", requestOptions);
        //    console.log(props);
        searchTable
            .json()
            .then((search) => {


                var sd = _(search) // Filter out items where CONVERSIONS is 0
                    .groupBy('SEARCH')
                    .map((groupedData, key) => ({ SEARCH: key, CONVERSIONS: _.sumBy(groupedData, 'CONVERSIONS'), IMPRESSIONS: _.sumBy(groupedData, 'IMPRESSIONS'), CLICKS: _.sumBy(groupedData, 'CLICKS'), REVENUE: _.sumBy(groupedData, 'REVENUE'), COST: _.sumBy(groupedData, 'COST') }))
                    .value();
                setsearchData(sd);

                var cv = _(search).filter(item => item.CONVERSIONS !== 0) // Filter out items where CONVERSIONS is 0
                    .groupBy('SEARCH')
                    .map((groupedData, key) => ({ SEARCH: key, CONVERSIONS: _.sumBy(groupedData, 'CONVERSIONS'), CLICKS: _.sumBy(groupedData, 'CLICKS') }))
                    .value();
                setTopConversionData(cv);
            })
            .catch((err) => {
                console.log(err);
            });




    }

    useEffect(() => {
        // if(!data){
        fetchReportData();
        // console.log(data);
        //  }
    }, [props, dataType]);




    return (
        <div >
            <ThemeProvider theme={theme}>


                {(data ?
                    <div>
                        <Grid container spacing={2} alignContent="space-between">

                            <Grid item xl={3} sm={3} xs={12}>
                                <Toolbar style={{ padding: 0 }}>
                                <Autocomplete
                                        options={adNetworkData}
                                        value={dataType}
                                        multiple={true}
                                        fullWidth
                                        getOptionLabel={(option) => option.CAMPAIGN_TYPE} // Defines how options are rendered in the menu and what values are searched.
                                        renderInput={(params) => <TextField {...params} label="Choose an Ad Network" variant="outlined" />}
                                        // style={{ width: '100%' }} // Style the Autocomplete component.
                                        onChange={(e, newValue) => {
                                            e.stopPropagation();
                                            console.log(newValue);
                                            setDataType((newValue.length>0 ? newValue : [{ CAMPAIGN_TYPE: "SEARCH" }]));
                                            //fetchReportData(newValue);
                                        }}
                                    />
                                </Toolbar>
                            </Grid>

                            <Grid item xl={3} sm={3} xs={12}>
                                <Toolbar style={{ padding: 0 }}>

                                </Toolbar>
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}></Grid>
                            <Grid item xl={3} sm={3} xs={12}></Grid>

                            <Grid style={{ display: 'none' }} item sm={6} xs={12}>
                                <Card>
                                    <CardHeader style={{ textAlign: 'center', color: "#fff", backgroundColor: lightenHexColor(props.themeColor, 0) }} title="Platform Expense"></CardHeader>
                                    <CardContent>
                                        <Typography align='center' variant="h4"><NumericFormat value={data.cost.count.toFixed(2)} thousandSeparator="," displayType="text" prefix="$" /></Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid style={{ display: 'none' }} item sm={6} xs={12}>
                                <Card style={{ marginTop: theme.spacing(0) }}>
                                    <CardHeader style={{ textAlign: 'center', color: "#fff", backgroundColor: lightenHexColor(props.themeColor, 0) }} title="Platform ROI"></CardHeader>
                                    <CardContent >
                                        <Typography align='center' variant="h4"><NumericFormat value={((data.revenue.count / data.cost.count) * 100).toFixed(2)} displayType="text" suffix="%" /></Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Impressions"
                                    data={data.impressions}
                                    client={props.client}
                                    color={props.themeColor}
                                />
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Clicks"
                                    data={data.clicks}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="CTR"
                                    data={data.ctr}
                                    client={props.client}
                                    color={props.themeColor}
                                />
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="CPC"
                                    data={data.cpc}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                        </Grid>

                        <Grid container spacing={2} alignContent="space-between">

                            <Grid item xl={3} sm={3} xs={12} >
                                <ChartCard
                                    title="Conversions"
                                    data={data.conversions}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Revenue"
                                    data={data.revenue}
                                    client={props.client}
                                    color={props.themeColor}
                                />


                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="Conversion Rate"
                                    data={data.convrate}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <ChartCard
                                    title="COST"
                                    data={data.cost}
                                    client={props.client}
                                    color={props.themeColor}
                                />

                            </Grid>
                        </Grid>




                        <Grid container spacing={2} alignContent="space-between">
                            <Grid item sm={12} xs={12} >
                                {(campaignData.length > 0 ?
                                    <TableCard
                                        title="Campaigns"
                                        data={campaignData}s
                                        count={10}
                                        client={props.client}
                                        color={props.themeColor}
                                        columns={[
                                            { title: "Campaign Name", field: "CAMPAIGN", render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 400, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.CAMPAIGN}</Typography> },
                                            { title: "Impressions", field: "IMPRESSIONS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" /> },
                                            {
                                                cellStyle: {
                                                    backgroundColor: lightenHexColor(props.themeColor, 30),
                                                    textAlign: 'center'

                                                }, title: "Clicks", field: "CLICKS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                            },
                                            {
                                                cellStyle: {
                                                    backgroundColor: lightenHexColor(props.themeColor, 30),
                                                    textAlign: 'center'

                                                }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100)} displayType="text" suffix="%" />
                                            },
                                            {
                                                cellStyle: {
                                                    backgroundColor: lightenHexColor(props.themeColor, 60),
                                                    textAlign: "center"

                                                },
                                                title: "Conversions", field: "CONVERSIONS", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                            },
                                            {
                                                cellStyle: {
                                                    backgroundColor: lightenHexColor(props.themeColor, 60),
                                                    textAlign: "center"
                                                }, title: "Conv %", field: "ConversionRate", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                            },
                                            { title: "Revenue", field: "REVENUE", type: 'currency' },
                                            { title: "Cost", field: "COST", type: 'currency' },
                                            { title: "CPC", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.COST / rowdata.CLICKS)).toFixed(2)} displayType="text" prefix="$" /> }
                                        ]}
                                    />
                                    : '')}

                            </Grid>
                            <Grid item sm={12} xs={12} >
                                <TableCard
                                    title="Search"
                                    data={searchData}
                                    count={5}
                                    client={props.client}
                                    color={props.themeColor}
                                    columns={[
                                        { title: "Search Term", field: "SEARCH", render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 400, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.SEARCH}</Typography> },
                                        { title: "Impressions", field: "IMPRESSIONS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" /> },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 30),
                                                textAlign: 'center'

                                            }, title: "Clicks", field: "CLICKS", render: rowdata => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                        },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 30),
                                                textAlign: 'center'

                                            }, title: "CTR", field: "CTR", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CLICKS / rowdata.IMPRESSIONS) * 100).toPrecision(2)} displayType="text" suffix="%" />
                                        },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 60),
                                                textAlign: "center"

                                            },
                                            title: "Conversions", field: "CONVERSIONS", render: rowdata => <NumericFormat decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                        },
                                        {
                                            cellStyle: {
                                                backgroundColor: lightenHexColor(props.themeColor, 60),
                                                textAlign: "center"
                                            }, title: "Conv %", field: "ConversionRate", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                        },
                                        { title: "Revenue", field: "REVENUE", type: 'currency' },
                                        { title: "Cost", field: "COST", type: 'currency' },
                                        { title: "CPC", field: "CPC", type: 'currency', render: rowdata => <NumericFormat value={(rowdata.CLICKS === 0 ? 0 : (rowdata.COST / rowdata.CLICKS)).toFixed(2)} displayType="text" prefix="$" /> }
                                    ]}
                                />


                            </Grid>



                            <Grid item sm={12} xs={12} >

                            <Comments></Comments>

                            </Grid>
                            <Grid item sm={6} xs={12}>
                                {(keywordData.length > 0 ?
                                    <TableCard
                                        title="Keyword"
                                        data={keywordData}
                                        count={10}
                                        client={props.client}
                                        color={props.themeColor}
                                        columns={[
                                            { title: "TERM", field: "SEARCH", render: rowdata => <Typography style={{ textAlign: 'left', minWidth: 400, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.KEYWORD}</Typography> },
                                            {
                                                cellStyle: {
                                                    backgroundColor: lightenHexColor(props.themeColor, 60),
                                                    textAlign: "center"

                                                },
                                                title: "Conversions", field: "CONVERSIONS", hidden: false
                                            },
                                            {
                                                cellStyle: {
                                                    backgroundColor: lightenHexColor(props.themeColor, 30)

                                                }, title: "Clicks", field: "CLICKS", hidden: false
                                            },
                                            {
                                                cellStyle: {
                                                    backgroundColor: lightenHexColor(props.themeColor, 60),
                                                    textAlign: "center"
                                                }, title: "Conv %", field: "CONVRATE", render: rowdata => <NumericFormat decimalScale={2} value={(rowdata.CLICKS === 0 ? 0 : (rowdata.CONVERSIONS / rowdata.CLICKS) * 100)} displayType="text" suffix="%" />
                                            }
                                        ]}
                                    />
                                    : '')}



                            </Grid>

                        </Grid>


                    </div>
                    : <LinearProgress style={{ margin: theme.spacing(8) }}></LinearProgress>)}


            </ThemeProvider>




        </div>
    );
};

Google.propTypes = {

};

export default Google;
