import { Grid, Typography, Avatar, AppBar, Button, Paper, Box, Link, TextField, Tab, Tabs } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { style } from './index.scss';
import { useEthers, useEtherBalance, useTransactions, useTokenList } from "@usedapp/core";
import { formatEther } from "@ethersproject/units";
import { getCookie } from '../../dwi-functions';
import PropTypes from 'prop-types';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import MaterialTable from 'material-table'
import appVars from '../../appVars.json';
import Wallet from '../../Login/dappLogin';
import { makeStyles,useTheme } from "@material-ui/core/styles";
import { fi } from 'date-fns/locale';
import { Add, CancelOutlined, Save, Settings, SupervisedUserCircleSharp } from '@material-ui/icons';
import { BsPrinter } from 'react-icons/bs';
import { Formik, Form, Field } from 'formik';
import themeFile from '../../theme.json';
import FileUploads from '../../settings/file-uploads';
const md5 = require('md5');

const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
var id = getLastItem(window.location.href);

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
    },
    table: {
        marginTop: "1em",
    },
    divider: {
        marginTop: "1em"
    },
    title: {
        borderBottom: "1px solid #000",
    },
    pos: {
        marginBottom: 12,
    },
    clientLogo: {
        maxWidth: "100%",
        marginTop: "1em",
        marginBottom: "1em"
    },
    address: {
        marginTop: "1em",
        marginBottom: "1em"
    },
    appBar: {
        background: themeFile.palette.primary.dark
    },
    paper: {
        padding: "2em"
    },
    paperSide: {
        padding: "1em"
    },
    media: {
        height: "auto",
        maxWidth: "150px",
        position: "absolute",
        right: 0,
        margin: "1em",
        backgroundSize: "auto"
    },
    icon: {
        margin: '0px!important',
    },
    tab: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    inputs: {
        marginBottom: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
        minWidth: 120,
    },
    toolbar: {
        margin: '0 0',
        background: theme.palette.primary.main,
        padding: '0 15px',
        color: theme.palette.primary.contrastText
    }

}));


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}




const Account = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { activateBrowserWallet, account, deactivate } = useEthers();
    const [errors, setErrors] = useState(false);
    const [opensea, setOPENsea] = useState([]);
    const etherBalance = useEtherBalance(account);
    const [value, setValue] = React.useState(0);
    const [user, setUsers] = useState({
        "name": "Default Name",
        "email": "default@sample.com"
    })
    const childToParent = (childdata) => {
        var f = appVars.api_url + '/imageFiles/' + user.pkRecId+'.png';
        const img = document.getElementById('avatar');
        img.src = f; console.log(f);
};

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const MyInput = ({ field, form, ...props }) => {
        return <TextField size='small' variant="outlined" shrink {...field} {...props} />;
    };


    async function fetch_ethStats() {

        const res = await fetch('https://api.opensea.io/api/v1/assets?owner=' + account);
        res
            .json()
            .then((res) => {
                setOPENsea(res.assets);
                // console.log(res.assets);
                //  setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setErrors(true);
            });




    }


    async function fetchUser() {
        const res2 = await fetch(appVars.api_url + '/users/g/' + id);
        res2
            .json()
            .then((res2) => {
                setUsers(res2[0]);
                console.log(res2[0]);
                //  setLoading(false); 
            })
            .catch((err) => {
                console.log(err);
                setErrors(true);
            });
    }





    useEffect(() => {


        fetchUser();
        document.getElementById('avatar').onerror = function(){
            this.src = 'https://www.gravatar.com/avatar/' + md5(user.email)
        }
        // setBalance(formatEther(etherBalance));
    }, []);





    // console.log(tokens);
    return (
        <div className={style} >

            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="General Settings" {...a11yProps(0)} />
                    <Tab label="Client List" {...a11yProps(1)} />
                    <Tab label="web3 Settings" {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Grid container spacing={2}  style={{padding:theme.spacing(2)}}>

                    <Grid item xl={10} md={12}>

                        <Paper className={classes.paper}>

                            <Formik enableReinitialize
                                initialValues={user}
                                className={classes.root}
                                onSubmit={async (values) => {
                                    //console.log(values);
                                    // return false;
                                    await new Promise((r) => setTimeout(r, 500));
                                    var url = appVars.api_url + "/gtm/update/";
                                    if (values.pkRecId === "0") {
                                        url = appVars.api_url + "/gtm/add/";
                                        //url = "http://localhost:7325/gtm/add/";
                                    }   //var url = "http://localhost:7325/gtm/add/";


                                    const response = fetch(url, {
                                        method: 'POST', // *GET, POST, PUT, DELETE, etc.
                                        body: JSON.stringify(values), // body data type must match "Content-Type" header,
                                        headers: {
                                            'Content-Type': 'application/json'
                                        }
                                    });
                                    response
                                        .then(users => {
                                            // window.location.href='/clientview/'+clientData.client;

                                            ////console.log(users.body);
                                            // fetchData();
                                        }).err(err => {

                                        })




                                }}>
                                <Form>
                                    <div>
                                        <Field component={MyInput} type="text" id="name" label="Display Name" fullWidth name="name" className={classes.inputs} helperText="Enter Display Name" />
                                    </div>
                                    <div>

                                        <Field component={MyInput} type="text" id="address" label="Address" fullWidth name="address" className={classes.inputs} helperText="Enter street address" />
                                    </div>
                                    <div>

                                        <Field component={MyInput} type="text" id="city" label="City" style={{ width: 'calc(45%)' }} name="city" className={classes.inputs} helperText="Enter City" />


                                        <Field component={MyInput} type="text" id="state" label="State" style={{ width: 'calc(45%)' }} name="state" className={classes.inputs} helperText="Enter State" />


                                        <Field component={MyInput} type="text" id="zip" label="Zip" style={{ width: 'calc(10%)' }} name="zip" className={classes.inputs} helperText="Enter Zip" />
                                    </div>

                                    <div>

                                        <Field component={MyInput} type="text" id="email" label="Email" style={{ width: 'calc(100% / 2)' }} name="email" className={classes.inputs} helperText="Enter Email" />


                                        <Field component={MyInput} type="text" id="phone" label="Phone" style={{ width: 'calc(100% / 2)' }} name="phone" className={classes.inputs} helperText="Enter Phone" />
                                    </div>

                                    <div>
                                        <Button variant="contained" style={{ marginRight: '7px' }} size="small" color="primary" href={"/client/new/?clientid=" + id} startIcon={<Save></Save>}>Save</Button>
                                        <Button variant="contained" style={{ marginRight: '7px' }} size="small" color="secondary" startIcon={<BsPrinter ></BsPrinter>}>Print</Button>
                                        <Button variant="contained" size="small" color="default" startIcon={<CancelOutlined></CancelOutlined>}>Cancel</Button>
                                    </div>
                                </Form>
                            </Formik>
                        </Paper>

                    </Grid>

                    <Grid item xl={2}>
                        <Paper style={{ padding: '1em', textAlign: 'center' }}>
                            <Typography variant="h5">{user.name}</Typography>
                            <img id="avatar" style={{width:'100%',paddingTop:'15px'}} alt={account} title={account} onError={(e)=>{
                                e.src='https://www.gravatar.com/avatar/' + md5(user.email);
                            }} src={appVars.api_url+'/imageFiles/'+user.pkRecId+'.png'} />
                            <FileUploads newid={user.pkRecId} childToParent={childToParent} ></FileUploads>
                        </Paper>
                    </Grid>

                </Grid>

            </TabPanel>
            <TabPanel value={value} index={1}>
                Item Two
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Grid container gutterBottom xl={12} spacing={2}  style={{padding:theme.spacing(2)}} >

                    <Grid item xl={10}>

                        <MaterialTable
                            columns={[
                                { title: 'Thumbnail', field: 'name', render: rowData => <img alt="" src={rowData.image_preview_url} style={{ width: 50, borderRadius: '50%' }} /> },
                                { title: 'Name', field: 'name' },
                                {
                                    title: 'Token', field: 'token_id', render: rowData => <Typography>          {rowData.token_id &&
                                        `${rowData.token_id.slice(0, 6)}...${rowData.token_id.slice(
                                            rowData.token_id.length - 4,
                                            rowData.token_id.length
                                        )}`}</Typography>
                                },
                                { title: 'View', field: 'surname', render: rowData => <Link target="_BLANK" href={rowData.permalink}>Open</Link> },
                            ]}
                            data={opensea}
                            title="Opensea NFT's"
                        />
                    </Grid>
                    <Grid item xl={2}>

                        <Wallet></Wallet>
                        <Button variant="outlined" onClick={fetch_ethStats} style={{ marginTop: "1em" }} fullWidth color="primary">Load NFT's</Button>
                    </Grid>

                </Grid>
            </TabPanel>







        </div>
    );
};

Account.propTypes = {

};

export default Account;
