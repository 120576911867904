import React, { useEffect, useState } from 'react';
import { style } from './index.scss';
import appVars from '../../../appVars.json';
import { CardHeader, Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Loading from '../../../no-screenshot.png';
import { Edit, Facebook } from '@material-ui/icons';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 500,

    },
    media: {
        width: '100%'
    },
    pad: {
        padding: '0 1em'
    },
    avatar: {
        background: theme.palette.primary.dark
    }
}));

const Screenshot = (props) => {
    const classes = useStyles();
    const [imageName, setimageName] = useState(appVars.api_url + '/imageFiles/' + props.item.id + '.jpeg');
    const handleImageError = () => {
        // Image failed to load, call your function here
        setimageName(Loading);
    };
    const [hideHeader, setshowHeader] = useState(props.hideHeader);
    const [hideFooter, setshowFooter] = useState(props.hideFooter);



    //  console.log(props.item);
    function FetchData() {

        fetch(appVars.api_url + '/screenshot?d=' + props.item.siteUrl + '&id=' + props.item.id)
            .then(response => response.json())
            .then((response) => {
                //    console.log(response.file);
                setimageName(response.file);
            }).catch((err) => {
                console.log('Error');
            });



    }
    useEffect(() => {
        setshowHeader(props.hideHeader);
        setshowFooter(props.hideFooter);
        return () => {
            // console.log('Loaded')
        };
    }, [props.hideHeader, props.hideFooter]);



    return (

        <div className={style}>


            <Card variant="outlined" className={classes.root}>
                <CardHeader avatar={
                     <Avatar variant="circle" src={appVars.api_url+'/imageFiles/' + props.item.owner + '.png'} title={props.item.owner} ></Avatar>
                }
                    action={

                        <IconButton color="primary" href={'/subclientviewer/' + props.item.id}><Edit  ></Edit></IconButton>

                    } style={{ display: (hideHeader === true ? 'none' : 'flex') }}
                    title={<Typography className={classes.pad} variant="body2" >{props.item.name}</Typography>}
                />
                <CardActionArea onClick={FetchData}>
                    <CardMedia
                        title={props.clientname}
                    >
                        <img className={classes.media} src={imageName} alt='' onError={handleImageError}></img>
                    </CardMedia>
                    <CardContent style={{ background: '#f3f3f3', display: (hideHeader === true ? 'none' : 'flex') }}>

                        <Grid container xl={12} sm={12} xs={12} spacing={1} style={{ display: (hideFooter === true ? 'none' : 'flex') }}>
                            <Grid item xl={4} sm={4} xs={12}>
                                <Typography gutterBottom variant="body2" component="p">Start<hr />
                                    {new Date(props.item.updatedAt).toLocaleString('en-US', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                        hour12: true,
                                    })}
                                </Typography>
                            </Grid>
                            <Grid item xl={3} sm={3} xs={12}>
                                <Typography variant="body2" color="primary">Conversion<hr />{props.item.fbconversions}</Typography>
                            </Grid>
                            <Grid item xl={4} sm={4} xs={12}>
                                <Typography variant="body2" color="secondary"> Revenue<hr />{parseFloat(props.item.revenue).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}</Typography>
                            </Grid>
                            <Grid item xl={1} sm={1} xs={12} >
                                {(props.item.fbapitoken !== null
                                    ? <IconButton color="primary"><Facebook></Facebook></IconButton>
                                    : ''
                                )}
                            </Grid>
                        </Grid>

                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
    );
};

Screenshot.propTypes = {
    data: PropTypes.array
};

export default Screenshot;
