import React from 'react';
import {style} from './index.scss';

const GrapeEditor = ({}) => {

    return (
        <div className={style}>
            
        </div>
    );
};

GrapeEditor.propTypes = {

};

export default GrapeEditor;
