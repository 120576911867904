import React, { useState, useEffect } from 'react';
import { Grid, LinearProgress, Typography, makeStyles } from '@material-ui/core';
import moment from 'moment';
import appVars from '../../appVars.json';
import LightGrid from '../../LightGrid/index';

var _ = require('lodash');


const useStyles = makeStyles((theme) => ({
    root: {
        margin: (theme.spacing(1), theme.spacing(1))
    }
}));

const TableView = (props) => {
    // eslint-disable-next-line
    const [hasError, setErrors] = useState(false);
    const [cards, setCards] = useState([]);
    const [incards, setinCards] = useState([]);
    const [upcards, setupCards] = useState([]);
    const [loading, setLoading] = useState(true);
    const classes = useStyles();

    async function fetchData(id) {

        // console.log('ID:' + id);
        var url = appVars.api_url + "/lightboxes/" + id;
        if (id === 'lightbox') {
            url = appVars.api_url + "/lightboxes/all";

        }

        const res = await fetch(url);
        // console.log(res);
        res
            .json()
            .then(res => {
                //  console.log(res);
                var d = _.filter(res, function (o) {

                    if (moment().isBetween(o.startTime, o.endTime)) {
                        return o;
                    }


                });

                var e = _.filter(res, function (o) {

                    if (moment().isAfter(o.endTime)) {
                        return o;
                    }


                });

                var f = _.filter(res, function (o) {

                    if (moment().isBefore(o.endTime) && moment().isBefore(o.startTime)) {
                        return o;
                    }


                });
                //   console.log(f);
                setinCards(e);
                setupCards(_.reverse(f))
                setCards(_.reverse(d));
            })
            .then(res => setLoading(false))
            .catch(err => setErrors(true));

        //  console.log(upcards);
    }


    useEffect(() => {
        const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1);
        const id = getLastItem(window.location.href);
        fetchData(id);


    }, []);



    return (
        <div className={classes.root} >
            {(!loading ?
                <Grid container spacing={2}>
                    <Grid md={12} item>
                        <Typography variant='h6' gutterBottom>Running Lightboxes</Typography>
                        <LightGrid cards={cards}></LightGrid>
                    </Grid>
                    <Grid md={12} item>
                        <Typography variant='h6' gutterBottom>Upcoming Lightboxes</Typography>
                        <LightGrid cards={upcards}></LightGrid>
                    </Grid>

                    <Grid md={12} item>
                        <Typography variant='h6' gutterBottom>Expired Lightboxes</Typography>
                        <LightGrid cards={incards}></LightGrid>
                    </Grid>
                </Grid>
                : <LinearProgress style={{ margin: ('20%', '25%') }} color="secondary"></LinearProgress>)}
        </div>
    );
};

TableView.propTypes = {

};

export default TableView;
