import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CardHeader from '@material-ui/core/CardHeader';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Divider, CardActions, Link, useTheme, ListItemIcon } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import LightBoxSettings from './LightBoxSettings';
import moment from 'moment';
import appVars from "../appVars.json";
import { CloudDownload, DeleteOutline, Edit, FileCopy } from '@material-ui/icons';

const countdown = require('moment-countdown');



const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.primary.main
  },
  paper: {
    padding: theme.spacing(3)
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  lightTitle: {
    fontSize: '1rem'
  },
  root: {
    background: '#fff'
  },
  container: {
    padding: '1em',
  },
  media: {
    height: 255,
    backgroundPosition: 'top center'
  },
  cardHeader: {
    color: "#fff",
    background: theme.palette.primary.main,
    padding: theme.spacing(1)
  },
  menu_header: {
    padding: '5px',
    backgroundColor: theme.palette.primary.main,
    color: "#fff"
  },
  h5: {
    padding: '15px',
    color: theme.palette.primary.light
  },
  button: {
    borderStyle: 'solid',
    border: '1px',
    borderColor: '#fff',
    color: '#fff',
    marginRight: "7px"
  },
  text: {
    width: "100%",
  },
  dense: {
    float: "right",
  },
  text30: {
    width: "48%",
  },
  image: {
    maxWidth: '70%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(3)
  },
  drop: {
    width: '50%',
    marginLeft: '0',
    marginRight: 'auto',
    marginBottom: theme.spacing(3),
    display: 'none'
  }

}));


async function uploadFiles(files) {

  const formData = new FormData();

  formData.append('fileToUpload', files[0]);
  formData.append('submit', true);

  //console.log(files[0]);
  const res = await fetch("https://api.digitalwerks.com:7325/upload", {
    method: "POST",
    mode: "no-cors",
    body: formData
  });
  if (res.body !== null) {
    res
      .json()
      .then(res => console.log(res));
  }
  //console.log(res);

}





const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const LightCard = (props) => {
  const classes = useStyles();
  const im = JSON.parse(props.image);
  const [anchorEl, setAnchorEl] = useState(false);
  const [openLBE, setOpenLBE] = useState(false);
  const [timer, setTimer] = React.useState(moment(props.endTime).countdown().toString());
  const theme = useTheme();
  //console.log(props);

  setInterval(function () {

    var a = moment().countdown(props.endTime);

    setTimer(a.toString());

  }, 1000);





  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(false);
    setOpenLBE(false);
  };

  const handleClickOpenLBE = () => {
    setOpenLBE(true);
  };

  useEffect(() => {
    //fetchStats();
  }, []);

  // console.log(im);
  return (

    <Grid item xl={3} md={4} sm={2} xs={12} key={props.lightboxId}>
      <Card className={classes.root} key={props.lightboxId} id={props.lightboxId} >
        <CardHeader
          action={
            <IconButton aria-label="settings" onClick={handleClick}>
              <MoreVertIcon style={{ color: "#fff" }} />
            </IconButton>

          }
          title={props.description}
          className={classes.cardHeader}
          disableTypography={true}
        />
        <Menu
          id={props.lightboxId}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >


          <MenuItem onClick={handleClickOpenLBE}> <ListItemIcon><Edit></Edit></ListItemIcon>Settings</MenuItem>
          <MenuItem onClick={(e) => {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var data = { ...props };
            data.lightboxId = "NEW";
            data.pkRecId = 0;
            data.embed = JSON.stringify(props.embed);
            //console.log(data);

            var raw = JSON.stringify(data);

            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            };

            fetch(appVars.api_url + "/lightbox/add", requestOptions)
              .then(response => response.text())
              .then(result => window.location.reload())
              .catch(error => console.log('error', error));





          }}> <ListItemIcon><FileCopy></FileCopy></ListItemIcon>Copy</MenuItem>

          <MenuItem>
            <ListItemIcon><CloudDownload></CloudDownload></ListItemIcon>
            <Link color="textPrimary" title={'Download Google Tag Manager tag for Lightbox ' + props.description} target='_blank' href={appVars.api_url + '/generate-gtm-lightbox/' + props.lightboxId}>Download</Link>
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              var requestOptions = {
                method: 'GET',
                redirect: 'follow'
              };
              fetch(appVars.api_url + "/lightbox/delete/" + props.lightboxId, requestOptions)
                .then(response => response.text())
                .then(result => {
                  console.log(result)
                  window.location.reload();
                })
                .catch(error => console.log('error', error));
            }}> <ListItemIcon><DeleteOutline /></ListItemIcon>Delete</MenuItem>
          <Divider></Divider>
          <MenuItem><Link color="textPrimary" href={'/lightbox/preview/' + btoa(props.lightboxId + '|' + props.clientId)} target="_BLANK">Preview Lightbox (A)</Link></MenuItem>
          <MenuItem><Link color="textPrimary" href={'/lightbox/previewb/' + btoa(props.lightboxId + '|' + props.clientId)} target="_BLANK">Preview Lightbox (B)</Link></MenuItem>
          <MenuItem><Link color="textPrimary" href={'/lightbox/preview-html/' + btoa(props.lightboxId + '|' + props.clientId)} target="_BLANK">Preview Lightbox (HTML)</Link></MenuItem>

        </Menu>
        <CardActionArea onClick={handleClickOpenLBE}>
          <CardMedia
            className={classes.media}
            image={im.imageSrc}
            title={im.imageAlt}
            style={{ filter: (!props.status ? 'grayscale(100%)' : '') }}
          />
        </CardActionArea>
        <CardContent>


          <Grid container>
            <Grid item md={8} xs={12} sm={12} style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
              <Typography variant="body2" color="textSecondary" component="p">
                Start Time
              </Typography>
              <Typography variant="body2" color="primary" component="p">
                {new Date(props.startTime).toLocaleDateString()} - {new Date(props.startTime).toLocaleTimeString()}
              </Typography>
            </Grid>


            <Grid item md={4} xs={6} style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
              <Typography variant="body2" color="textSecondary" component="p">
                Display Days
              </Typography>
              <Typography variant="body2" color="primary" component="p">
                {props.cookieExpire}
              </Typography>
            </Grid>








            <Grid item md={8} xs={12} sm={12} style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
              <Typography variant="body2" color="textSecondary" component="p">
                End Time
              </Typography>
              <Typography variant="body2" color="primary" component="p">
                {new Date(props.endTime).toLocaleDateString()} - {new Date(props.endTime).toLocaleTimeString()}
              </Typography>
            </Grid>


            <Grid item md={4} xs={6} style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
              <Typography variant="body2" color="textSecondary" component="p">
                Trigger(ms)
              </Typography>
              <Typography variant="body2" color="primary" component="p">
                {props.delay}
              </Typography>
            </Grid>






            <Grid item md={12} style={{ margin: (theme.spacing(1), theme.spacing(0)) }}>
              <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}></Divider>
              <Typography variant="body2" color="primary" component="p">
                Ending In
              </Typography>
              {new Date() >= new Date(props.endTime) ?
                <Typography variant="body2" style={{ color: "red" }} component="p">Expired!</Typography> :
                <Typography variant="body2" color="textSecondary" component="p">
                  {timer}
                </Typography>
              }
            </Grid>
          </Grid>




        </CardContent>
        <CardActions style={{ display: "none" }} >
          <Button size="small" style={{ width: "50%" }} variant="outlined" onClick={handleClickOpenLBE} color="secondary">Edit Settings</Button>
          <Button size="small" style={{ width: "50%" }} variant="outlined" color="primary" onClick={(e) => {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var data = { ...props };
            data.lightboxId = "NEW";
            data.pkRecId = 0;
            data.embed = JSON.stringify(props.embed);
            //console.log(data);

            var raw = JSON.stringify(data);

            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            };

            fetch(appVars.api_url + "/lightbox/add", requestOptions)
              .then(response => response.text())
              .then(result => window.location.reload())
              .catch(error => console.log('error', error));





          }}>Duplicate</Button>
        </CardActions>


        <Dialog id="Dialog" fullScreen open={openLBE} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar className={classes.appBar}>
            <Toolbar >
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h4" className={classes.title}>

              </Typography>

            </Toolbar>
          </AppBar>




          <LightBoxSettings {...props} />





        </Dialog>





      </Card>
    </Grid>

  );
};

LightCard.propTypes = {

};

export default LightCard;
