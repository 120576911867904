import { React, useEffect, useState } from 'react';
import { style } from './index.scss';
import { MenuItem, TextField, IconButton } from '@material-ui/core';
import appVars from '../../../appVars.json';
import { MdAddCircle } from "react-icons/md";

const MatomoSites = (props) => {

    const [sites, setSites] = useState([{ idsite: '10', name: "" }]);
    const [bdisplay, setBeaconDisplay] = useState("inherit");

    async function fetchData() {


        try {
            var data = {
                module: 'API',
                method: 'SitesManager.getAllSites',
                format: 'json', // Assuming 'json' should be lowercase
                token_auth: appVars.matomo_token
            };

            var urlParams = new URLSearchParams(data).toString();


            const response = await fetch(appVars.matomo_url + "/index.php?" + urlParams);

            // Check if the response status is OK (this will not catch if no-cors is used)
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const resJson = await response.json();

            setSites(resJson);
        } catch (error) {
            console.error('Fetching Matomo data failed:', error);
        }

    }


    useEffect(() => {
        fetchData();
    }, []);
    return (
        <div className={style}>

            <TextField
                name="beaconId"
                id="beaconId"
                variant="outlined"
                inputProps={{
                    startAdornment: (
                        <IconButton size='small' style={{ display: bdisplay }} color="Primary" title={'Create Beacon Site for ' + props.client.name} target="_BLANK" onClick={(e) => {
                            var _d = {
                                "method": "SitesManager.addSite",
                                "module": "API",
                                "ecommerce": 1,
                                "siteSearch": 1,
                                "currency": "USD",
                                "format": "JSON",
                                "token_auth": appVars.matomo_token
                            };


                            _d['siteName'] = props.client.name
                            _d['urls'] = props.client.siteUrl;

                            var urlParams = new URLSearchParams(_d).toString();
                            var burl = appVars.matomo_url + "/index.php?" + urlParams;
                            _d['url_'] = burl;
                            // console.log(_d);


                            const bresponse = fetch(burl, {
                                method: 'GET' // *GET, POST, PUT, DELETE, etc.
                            });
                            bresponse
                                .then(users2 => {
                                    //client.beaconId = users2.body.value;

                                    // setClient(client);
                                    setBeaconDisplay("none");
                                    //  console.log(users2.body);
                                }).catch(err => console.log(err))




                        }} ><MdAddCircle />
                        </IconButton>

                    )
                }}
                fullWidth
                select
                {...props}

            >
                <MenuItem value="0">
                    <em>Do Not Track</em>
                </MenuItem>
                {Object.entries(sites).map((prop, key) => {
                    //  console.log(prop[1]);
                    return <MenuItem value={prop[1].idsite}>{prop[1].idsite} - {prop[1].name}</MenuItem>
                })
                }
            </TextField>
        </div>
    );
};

MatomoSites.propTypes = {

};

export default MatomoSites;
