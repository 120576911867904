// Consolidating imports from '@material-ui/core'
import {
    AppBar,
    Box,
    Button,
    Grid,
    TextField,
    Paper,
    Typography,

    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useTheme,
    makeStyles
} from '@material-ui/core';

// Importing React and hooks
import React, { useEffect, useState } from 'react';

// Importing custom utilities and components
import appVars from '../../appVars.json';
import FileUploads from '../file-uploads';
import { getCookie, lightenHexColor, darkenHexColor,rgbToHex } from '../../dwi-functions';
import ColorPicker from 'material-ui-color-picker';
import ColorThief from 'colorthief';
import { TbReportAnalytics } from "react-icons/tb";


const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
var id = getLastItem(window.location.href);


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

        maxWidth: 1600,
        margin: 'auto'
    },
    data: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    content: {
        marginTop: 0,
        [theme.breakpoints.up('sm')]: {
            marginTop: 0,
        },
    }
}));


const ReportSettings = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [admintoken, setAdmin] = useState(getCookie('_auta'));
    const [settings, setSettings] = useState({});
    const [profile, setProfile] = useState({});
    const [adsets, setAdsets] = useState({});
    const [color, setColor] = useState({});
    const [colors, setColors] = useState([[255,0,0],[0,255,0],[0,0,255]]);


    const MyInput = ({ field, form, ...props }) => {
        return <TextField shrink {...field} {...props} />;
    };



    const handleSettingsOpen = () => {
        setSettingsOpen(true);
    };


    const handleClose = () => {
        setSettingsOpen(false);
    };

    const handleChange = (event, newValue) => {



        settings.ad_sets[event.currentTarget.id].id = event.currentTarget.value;
        profile.settings = settings;
        setProfile(profile);
        setSettings(settings);

    };



    const handleColorChange = (color) => {
        setColor(color);
        profile.color = color;
        profile.settings = settings;
        setProfile(profile);
        // console.log(profile);
    };


    const childToParent = (childdata) => {
        var f = appVars.api_url + '/imageFiles/' + id + '.png';
        const img = document.getElementById('avatar');
        img.src = f; //console.log(f);
    };


    async function fetchData() {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "cid": props.client_id
        });



        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };


        const rp = await fetch(appVars.api_url + "/reportprofile/" + id, requestOptions);
        rp
            .json()
            .then((res3) => {

                if (res3 !== null) {

                    var sett = (typeof res3.settings === 'object' && res3.settings !== null ? res3.settings : JSON.parse(res3.settings));
                    var ads = (typeof res3.tabs === 'object' && res3.tabs !== null ? res3.tabs : JSON.parse(res3.tabs));

                    res3.settings = sett;
                    res3.tabs = JSON.parse(res3.tabs);
                    setProfile(res3);
                    setSettings(sett);
                    setAdsets(sett);
                    //  console.log(res3);

                } else {
                    profile.color = "#51704b";
                    profile.clientid = id;
                    profile.client = props.client
                    profile.settings = { "ad_sets": { "google_paid": { "id": "0" }, "google_grant": { "id": "0" }, "meta": { "id": "0" }, "bing": { "id": "0" }, "stackadapt": { "id": "0" } } }
                    profile.tabs = { "tabs": { "google_paid": { "label": "Google Paid", "charts": { "impressions": 1, "clicks": 1, "ctr": 1, "cpc": 1, "conversions": 1, "revenue": 1, "conv_rate": 1, "cost_conversion": 1, "searchTerms": 1, "region": 0, "comment": 1 } }, "google_grant": { "label": "Google Grant", "charts": { "impressions": 1, "clicks": 1, "ctr": 1, "cpc": 1, "conversions": 1, "revenue": 1, "conv_rate": 1, "cost_conversion": 1, "searchTerms": 1, "region": 0, "comment": 1 } }, "meta": { "label": "Meta", "charts": { "impressions": 1, "clicks": 1, "ctr": 1, "cpc": 1, "conversions": 1, "revenue": 1, "conv_rate": 1, "cost_conversion": 1, "searchTerms": 1, "region": 0, "comment": 1 } }, "bing": { "label": "Bing", "charts": { "impressions": 1, "clicks": 1, "ctr": 1, "cpc": 1, "conversions": 1, "revenue": 1, "conv_rate": 1, "cost_conversion": 1, "searchTerms": 1, "region": 0, "comment": 1 } }, "stackadapt": { "label": "Stackadapt", "charts": { "impressions": 1, "clicks": 1, "ctr": 1, "cpc": 1, "conversions": 1, "revenue": 1, "conv_rate": 1, "cost_conversion": 1, "searchTerms": 1, "region": 0, "comment": 1 } } } }

                    setProfile(profile);
                    setSettings(profile.settings);
                   // setSettingsOpen(true);
                }
                setLoading(false);


                const img = new Image();
                img.crossOrigin = 'Anonymous'; // This is important for loading images from external URLs
                img.src = appVars.api_url + '/imageFiles/' + id + '.jpeg'; // Replace with your image's URL
                img.onload = () => {
                    const colorThief = new ColorThief();
                    let palette = colorThief.getPalette(img, 5);
                  //  console.log(palette);
                    setColors(palette); // Logs the color palette
                };




            })
            .catch((err) => {
                console.log(err);
            });




    }


    useEffect(() => {


        if (loading) {
            fetchData();
        }


    });



    return (
        <div>
            <Button
                onClick={handleSettingsOpen}
                fullWidth
                style={{ color: theme.palette.primary.contrastText, marginBottom: theme.spacing(1) }}
                variant="contained"
                color='primary'
                startIcon={
                    <TbReportAnalytics />
                }
            >Report Settings</Button>
            <Dialog
                open={settingsOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="ls"
            >
                <DialogTitle id="alert-dialog-title">{<Typography variant="h5" gutterBottom>Ad Settings</Typography>}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={5}>
                                <div style={{ padding: theme.spacing(3), backgroundColor: profile.color, textAlign: 'center' }}>
                                    <img width={'100%'} id="avatar" src={appVars.api_url + '/imageFiles/' + id + '.png'} alt='' />
                                </div>
                                <FileUploads newid={id} childToParent={childToParent} ></FileUploads>
                            </Grid>
                            <Grid item sm={1}>
                                {

                                    (colors ?
                                        colors.map((entry, i) => (
                                            <Box p="1rem" data-color={rgbToHex(entry)}  color={lightenHexColor(rgbToHex(entry),20)}   style={{ height: '50px', width: "100%",marginBottom: theme.spacing(.5)}} bgcolor={rgbToHex(entry)} onClick={(e) => {
                                              profile.color = e.currentTarget.innerText;
                                              setProfile(profile);
                                              setColor(e.currentTarget.innerText)
                                              //console.log();
                                            }} >
                                                {rgbToHex(entry)}
                                            </Box>
                                        ))
                                        : '')
                                }
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <ColorPicker
                                    style={{ marginBottom: theme.spacing(1), marginTop: theme.spacing(0) }}
                                    placeholder="Brand Color"
                                    label="Brand Color"
                                    fullWidth
                                    onChange={handleColorChange}
                                    defaultValue={profile.color}
                                    value={color}
                                ></ColorPicker>
                                <MyInput size="small" onChange={handleColorChange} id="color" name="color" defaultValue={profile.color} fullWidth />
                                <MyInput onChange={handleChange} size="small" style={{ marginBottom: theme.spacing(1) }} id="meta" name="meta" placeholder="" label="META Id" defaultValue={(settings.ad_sets ? settings.ad_sets.meta.id : '')} fullWidth />
                                <MyInput onChange={handleChange} size="small" style={{ marginBottom: theme.spacing(1) }} id="stackadapt" name="stackadapt" placeholder="" label="StackAdapt Id" defaultValue={(settings.ad_sets ? settings.ad_sets.stackadapt.id : '')} fullWidth />
                                <MyInput onChange={handleChange} size="small" style={{ marginBottom: theme.spacing(1) }} id="bing" name="bing" placeholder="" label="Bing Id" defaultValue={(settings.ad_sets ? settings.ad_sets.bing.id : '')} fullWidth />
                                <MyInput onChange={handleChange} size="small" style={{ marginBottom: theme.spacing(1) }} id="google_paid" name="google_paid" placeholder="" label="Google Paid Id" defaultValue={(settings.ad_sets ? settings.ad_sets.google_paid.id : '')} fullWidth />
                                <MyInput onChange={handleChange} size="small" style={{ marginBottom: theme.spacing(1) }} id="google_grant" name="google_grant" placeholder="" label="Google Grant Id" defaultValue={(settings.ad_sets ? settings.ad_sets.google_grant.id : '')} fullWidth />

                            </Grid>



                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        style={{ color: theme.palette.primary.contrastText }}
                        onClick={handleClose} color="secondary">
                        Close
                    </Button>
                    <Button
                        style={{ color: theme.palette.primary.contrastText }}
                        onClick={(e) => {

                            // console.log('raw');
                            var sett = (typeof profile.settings === 'object' && profile.settings !== null ? profile.settings : JSON.parse(profile.settings));
                            var ads = (typeof profile.tabs === 'object' && profile.tabs !== null ? profile.tabs : JSON.parse(profile.tabs));

                            var data = profile;

                            delete data.updatedAt;
                            delete data.createdAt;
                            data.settings = JSON.stringify(sett);
                            data.tabs = JSON.stringify(ads);

                            //setProfile(profile);
                            var myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/json");
                            var raw = JSON.stringify(data);
                            //console.log(raw);
                            //  return false;

                            var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: raw,
                                redirect: 'follow'
                            };

                            fetch(appVars.api_url + "/reportprofile/", requestOptions)
                                .then(response => response.text())
                                .then(result => setSettingsOpen(false))
                                // .then(result =>window.location.reload()) 
                                .catch(error => console.log('error', error));







                        }}

                        variant="contained" color='primary'>Save</Button>
                </DialogActions>
            </Dialog>



        </div>
    );
};

ReportSettings.propTypes = {

};

export default ReportSettings;
