export function getUrlParameter(name) {
  // eslint-disable-next-line  
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};



export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function darkenHexColor(hex, percent) {

  if (!hex) {
    return "#b8b8b8"
  }


  // Remove the '#' if present
  hex = hex.replace(/#/g, '');

  // Convert hex to RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate the darkened RGB values
  const delta = 255 * (percent / 100);
  const newR = Math.max(0, r - delta);
  const newG = Math.max(0, g - delta);
  const newB = Math.max(0, b - delta);

  // Convert darkened RGB back to hex
  const darkenedHex = `#${Math.round(newR).toString(16).padStart(2, '0')}${Math.round(newG).toString(16).padStart(2, '0')}${Math.round(newB).toString(16).padStart(2, '0')}`;

  return darkenedHex;
}


export function lightenHexColor(hex, percent) {

  if (!hex) {
    return "#b8b8b8"
  }


  // Remove the '#' if present
  hex = hex.replace(/#/g, '');

  // Convert hex to RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate the lightened RGB values
  const delta = 255 * (percent / 100);
  const newR = Math.min(255, r + delta);
  const newG = Math.min(255, g + delta);
  const newB = Math.min(255, b + delta);

  // Convert lightened RGB back to hex
  const lightenedHex = `#${Math.round(newR).toString(16).padStart(2, '0')}${Math.round(newG).toString(16).padStart(2, '0')}${Math.round(newB).toString(16).padStart(2, '0')}`;

  return lightenedHex;
}




export function downloadText(filename, text) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function clickToCopy(input) {
  /* Get the text field */
  var copyText = document.getElementById(input);

  /* Select the text field */
  copyText.select();
  copyText.setSelectionRange(0, 99999); /*For mobile devices*/

  /* Copy the text inside the text field */
  document.execCommand("copy");
  console.log(copyText);
}


export function loadSplash(lightboxSettings) {

  //console.log(lightboxSettings);
  var dwiSplash = document.createElement('div');
  var im = JSON.parse(lightboxSettings.image);
  var link = document.createElement('link');

  // set the attributes for link element  
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = 'http://localhost:3000/a.css';

  //build markup for splash
  dwiSplash.setAttribute('class', 'dwiSplash');
  dwiSplash.innerHTML = '<div id="dl-background-splash-1"><div class=close><a id="close" onclick="sendClick(\'closed\',\'' + lightboxSettings.description + '\');">X</a></div><div class=donate-now><a href="' + lightboxSettings.url + '"onclick="sendClick(\'clicked\',\'' + lightboxSettings.description + '\');" ><img alt="' + im.imageAlt + '" title="' + im.imageAlt + '" src="' + im.imageSrc + '"/></a></div></div></div>';
  dwiSplash.appendChild(link);

  //make sure we are at the top of the page
  window.scrollTo(0, 0);

  //insert mark-up
  document.body.appendChild(dwiSplash);
  setCookie('DWI-' + lightboxSettings.lightbox_id, '1', lightboxSettings.cookie_expire);
  setTimeout(function () {
    document.getElementById("dl-background-splash-1").className += "load";
    document.getElementById('dl-background-splash-1').style["max-width"] = lightboxSettings.maxWidth;
    document.getElementById('close').style["color"] = lightboxSettings.themeColor;
  }, lightboxSettings.delay);

  window.dataLayer.push({
    'event': 'customEvent',
    'eventInfo': {
      'category': 'Lightbox Engagement',
      'action': 'Impression',
      'label': lightboxSettings.description,
      'value': 0,
      'nonInteraction': true
    }
  });
}


export function rgbToHex(rgb) {
  // Check if the input is an array of length 3 and contains valid RGB values
  if (!Array.isArray(rgb) || rgb.length !== 3 || rgb.some(val => val < 0 || val > 255)) {
    throw new Error('Invalid RGB array');
  }

  // Convert each component to a hexadecimal string
  let hex = rgb.map(color => {
    // Ensure the value is within bounds and convert to hex
    let hexValue = Math.max(0, Math.min(255, color)).toString(16);
    // Add a leading zero if necessary to ensure 2 digits
    return hexValue.length === 1 ? '0' + hexValue : hexValue;
  }).join('');

  // Return the formatted hexadecimal color code
  return '#' + hex;
}





export function resizeFont(fsize) {
  var lr = '300';
  var ur = '1366';
  var zz = (fsize / 16);
  var xx = lr / 100;
  var yy = 100 * (fsize - fsize) / (ur - lr);
  var str = 'calc(' + zz + 'rem + ((1vw - ' + xx + 'px) * ' + yy + '))';
  //console.log(str);
  return str;

}