import { Typography, makeStyles, Grid, CardHeader, Snackbar } from '@material-ui/core';
import React, { useState } from 'react';
import { useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import appVars from '../../appVars.json';
import StyleGuides from '../../clients/style-guides';
import ClipboardJS from "clipboard";
import Alert from "@material-ui/lab/Alert";


//const Prism = require('prismjs');
const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  hidetext: {
    color: "#000"
  }
});


var id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
id = decodeURI(id);


var g = Buffer.from(id, 'base64').toString('ascii').split('|');
id = g[0];


const LightboxPreviewc = (props) => {
  const classes = useStyles();
  const [lightbox, setLightbox] = useState([{}]);
  const [errors, setErrors] = useState(false);
  const [stats, setStats] = useState({});
  const [copySuccessShow, setCopySuccessShow] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");

  const handleClose = () => {
    setCopySuccessShow(false);
  };
  //console.log(props);


  async function fetchData(id) {
    const res = await fetch(appVars.api_url + "/lightbox/" + id);
    res
      .json()
      .then(res => {
        var a = new Date(res.startTime);
        var b = new Date(res.endTime);

        setLightbox(res);
        document.title = res.description
        //console.log(res);

        const script = document.createElement('script');
        script.src = appVars.api_url + "/scripts/html-preview.js";
        script.async = true;
        script.id = id + '|' + res.beaconId;
        script.className = "dwi-components";
        document.body.appendChild(script);

        var url = appVars.matomo_url + '/index.php?module=API&format=JSON&idSite=' + res.beaconId + '&period=range&date=' + a.getFullYear() + '-' + (a.getMonth() + 1) + '-' + a.getDate() + ',' + b.getFullYear() + '-' + (b.getMonth() + 1) + '-' + b.getDate() + '&method=Events.getName&expanded=1&pivotBy=Events.EventAction&pivotByColumnLimit=20&pivotByColumn=nb_events&token_auth=' + appVars.matomo_token + '&filter_limit=50';
        // console.log(url);
        //console.log(props)
       // Prism.highlightAll();
        const res2 = fetch(url, {
          method: "GET"
          // mode:"no-cors",
        });

        res2
          .then(response => response.json())
          .then((res2) => {

            var k = res2[0];
            var st = {
              label: res2[0].label,

            }


            //  console.log('k' ,  Object.values(k)[1]);
            // if (k.label === lightbox.lightboxId) {

            st['impressions'] = Object.values(k)[1];
            st['closed'] = Object.values(k)[2];
            st['clicked'] = Object.values(k)[3];
            //  }




            setStats(st);
            // setLoading(false);
            // console.log(st)
            //console.log(v);
          }).catch((err) => {
            setErrors(true);
            console.log(err);
          });






      })
      .catch(err => { setErrors(true); console.log(err); });





  }
  //console.log(errors)



  useEffect(() => {

    fetchData(id);
    //Prism.highlightAll();

    var clipboard = new ClipboardJS("#script");
    clipboard.on("success", function (e) {
      setCopySuccess(e.action + ": " + e.text);
      setCopySuccessShow(true);
      e.clearSelection();
    });

  }, []);


  return (
    <div style={{ backgroundColor: 'ghostwhite', paddingLeft: '2em', height: '100vh' }} className="hide-preview">

      <Typography variant="h6" color="secondary" align="center">For Lightbox Preview Only.<br></br>Close this window after Preview.</Typography>
      <Typography variant='body2' color="secondary" align='center'>* Date/Display Settings ignored on this preview page.</Typography>
      <Grid container xl={12} spacing={3}>
        <Grid item xl={3}>
          <Card className={classes.root}>
            <CardHeader title={lightbox.description}></CardHeader>
            <CardContent>
          
              <Typography variant="body2">Start Date -  <span style={{color:'red',fontWeight:'bold'}}>{lightbox.startTime}</span></Typography>
              <Typography variant="body2">End Date -  <span style={{color:'red',fontWeight:'bold'}}>{lightbox.endTime}</span></Typography>
              <Typography variant="body2">Display Every  <span style={{color:'red',fontWeight:'bold',fontSize: '1em'}}>{lightbox.cookieExpire}</span> days</Typography>
         
              <Grid container style={{ margin: '1em auto' }}>
                <Grid item xl={4}><Typography align='center'>Impressions:<br></br>{stats.impressions}</Typography></Grid>
                <Grid item xl={4}><Typography align='center'>Clicked: {(stats.clicked/stats.impressions).toFixed(2)}% <br></br> {stats.clicked}</Typography></Grid>
                <Grid item xl={4}><Typography align='center'>Closed: {((stats.impressions-stats.clicked)/stats.impressions).toFixed(2)}%<br></br> {stats.impressions-stats.clicked}</Typography></Grid>
              </Grid>


            </CardContent>
            <CardActions>
              <Button target="_BLANK" size="small" color="primary" variant="outlined" fullWidth href={lightbox.url}>Destination URL</Button>
            </CardActions>
          </Card>

          <Card className={classes.root} style={{ marginTop: '2em' }}>
            <CardHeader title="Install Instructions"></CardHeader>
            <CardContent>
              <Grid container style={{ margin: '0em auto' }}>
                <Grid item xl={12}>
                  <Typography variant='body2'>To activate the lighbox place the code in the <span style={{color:'red',fontWeight:'bold'}}>&lt;head></span> tag of the pages you would like the lightbox to show. The lightbox is restricted by the setting 'restrictto' below in the script tag. This lightbox will only show on URLS containing the value of that setting. Remove for all unrestricted display.</Typography>
                  <pre id="script-text" style={{background:'#272822',wordBreak:"break-all",whiteSpace:"initial",color:"#fff",padding:'1em',fontSize: '1rem',borderRadius:'10px'}}>
                    &lt;script class="dwi-components" id="{lightbox.lightboxId + '|' + lightbox.beaconId}" src="https://api.digitalwerks.com:7325/scripts/h.js">&lt;/script>
                  </pre></Grid>
              </Grid>


            </CardContent>
            <CardActions>
              <Button id="script" data-clipboard-target="#script-text" variant="outlined" fullWidth autoFocus color="primary">
                Copy to Clipboard
              </Button>
            </CardActions>
          </Card>






        </Grid>
        <Grid item xl={9}>
          <StyleGuides clientID={g[1]} display="none" async></StyleGuides></Grid>
      </Grid>




      <Snackbar
        open={copySuccessShow}
        autoHideDuration={1500}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {copySuccess}
        </Alert>
      </Snackbar>



    </div>
  );
};

LightboxPreviewc.propTypes = {

};

export default LightboxPreviewc;
