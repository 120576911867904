import MaterialTable from 'material-table';
import React from 'react';
import moment from 'moment';
import { lightenHexColor, darkenHexColor } from '../../dwi-functions';

const fontSize = "'initial";


const TableCard = (props) => {
    //console.log(props)
    var tableOptions = {
        rowStyle: { fontSize: fontSize, padding: 0, margin: 0 },
        cellStyle: { border: '1px solid #fff', textAlign: 'center' },
        headerStyle: { textAlign: "center", backgroundColor: props.color, color: "#fff", fontSize: fontSize, border: '1px solid #fff', paddingTop: 5, paddingBottom: 5 },
        grouping: false,
        exportButton: true,
        exportAllData: true,
        search: false,
        exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
        pageSize: props.count,
        pageSizeOptions: [10, 25, 50, 100],
        showTitle: false,
        toolbar: true,
        sort: true
    };


    return (
        <div >
            <MaterialTable
                data={props.data}
                columns={props.columns}
                options={tableOptions}

            />
        </div>
    );
};

TableCard.propTypes = {

};

export default TableCard;
