import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import {
    Typography,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Grid,
    Button,
    ButtonGroup,
    Snackbar,

    Toolbar,
    IconButton,
    LinearProgress,
    Avatar
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Link from "@material-ui/core/Link";
import ClipboardJS from "clipboard";
import appVars from '../../appVars.json';
import Screenshot from '../controls/screenshot';
import { Add, Edit, GridOffRounded, List } from "@material-ui/icons";
import _ from "lodash";
import { useTheme } from '@material-ui/core/styles';
import { BsMeta, BsDownload } from "react-icons/bs";
import Newclient from "../newclient";
import { useToken } from "../../Login/TokenProvider";




const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
const id = getLastItem(window.location.href);
const tableOptions = {
    grouping: true,
    pageSize: 25,
    pageSizeOptions: [10, 25, 50, 75, 100],
    showTitle: false,
    search: false
};









const SubClientList = (props) => {
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);
    const token = useToken();
    const admintoken = token.token;
    const theme = useTheme();
    const [brandColor, setBrandColor] = useState({ "primary": "#4F2D7F", "secondary": "#FFD100" });
    const [hasError, setErrors] = useState(false);
    const [copySuccessShow, setCopySuccessShow] = useState(false);
    const [clientUsers, setClientUsers] = useState(false);
    const [loading, setLoading] = useState(true);
    const [copySuccess, setCopySuccess] = useState("");
    const [hideList, sethideList] = useState(true);
    const [hideGrid, sethideGrid] = useState(false);
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setErrors(false);
        setCopySuccessShow(false);
    };

    const handleToggle = (e) => {
        if (e.currentTarget.getAttribute('toggle') === 'list') {
            sethideGrid(true);
            sethideList(false);
        } else {
            sethideList(true);
            sethideGrid(false);
        }

    };




    async function fetchData() {
        const res = await fetch(appVars.api_url + "/tagmanager_detail/" + id);
        res
            .json()
            .then((res) => {
                var a = _.sortBy(res, ['name']);

                if (props.status) {
                    a = _.filter(a, function (o) { return o.status; });
                } else {
                    a = _.filter(a, function (o) { return !o.status; });
                }
                //console.log(a);
                setClientUsers(a);
                setLoading(false);
                // console.log(clientUsers);
            }).catch((err) => {
                console.log(err);
                setErrors(true)
            });
    }

    useEffect(() => {


        if (clientUsers === false) {

            fetchData();
        }



        document.title = "Client Dashboard View"
        var clipboard = new ClipboardJS("#gtminstall");
        clipboard.on("success", function (e) {
            setCopySuccess(e.action + ": " + e.text);
            setCopySuccessShow(true);
            e.clearSelection();
        });

        var fbverification = new ClipboardJS("#fbverification");
        fbverification.on("success", function (e) {
            setCopySuccess(e.action + ": " + e.text);
            setCopySuccessShow(true);
            e.clearSelection();
        });



    });



    return (
        <div style={{ margin: theme.spacing(1) }}>
            <Toolbar style={{ marginBottom: theme.spacing(1), padding: '0' }} variant="regular">
                <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                    <Button size="medium" color="primary" onClick={toggle} startIcon={<Add></Add>}>New Client</Button>
                    <Button size="medium" toggle="grid" color="primary" onClick={handleToggle} startIcon={<GridOffRounded></GridOffRounded>}>Grid View</Button>
                    <Button size="medium" toggle="list" color="primary" onClick={handleToggle} startIcon={<List ></List>}>List View</Button>

                </ButtonGroup>
            </Toolbar>

            {(clientUsers !== false ?
                <div>
                    <div style={{ display: (hideGrid === true ? 'none' : 'initial') }}>
                        <Grid container spacing={1}>
                            <LinearProgress hidden={loading} color="secondary" >test</LinearProgress>
                            {clientUsers.map((item, index) => (
                                <Grid item xl={3} md={4} sm={4} xs={12}>
                                    <Screenshot item={item} url={item.siteUrl} clientname={item.name}></Screenshot>
                                </Grid>
                            ))}
                        </Grid>

                    </div>

                    <MaterialTable
                        style={{ display: (hideList === true ? 'none' : 'block') }}
                        data={clientUsers}
                        title="Google Tag Managers"
                        isLoading={loading}
                        options={tableOptions}
                        columns={[
                            { title: "pkRecId", field: "pkRecId", hidden: true },
                            { title: "siteUrl", field: "siteUrl", hidden: true },


                            {
                                title: "", field: "name",
                                render: (rowData) => (
                                    <IconButton size="small" color="secondary" href={"/subclientviewer/" + rowData.id}>
                                        <Edit></Edit>
                                    </IconButton>
                                ),
                            },
                            {
                                title: "owner", field: "owner",
                                render: (rowData) => (

                                    <Avatar variant="circular" src={appVars.api_url+'/imageFiles/' + rowData.owner + '.png'} title={rowData.owner} ></Avatar>

                                ),
                            },
                            {
                                title: "Screenshot", field: "site",
                                render: (rowData) => (

                                    <Screenshot hide={true} url={rowData.siteUrl} item={rowData} hideHeader={true} hideFooter={true} ></Screenshot>

                                ),
                            },
                            {
                                title: "Client Name", field: "name",
                                render: (rowData) => (
                                    <Link target="_BLANK" href={rowData.siteUrl}>
                                        {rowData.name}
                                    </Link>
                                ),
                            },
                            //   {title: "GTM ID",field: "tag"},
                            {
                                title: "Conversions", field: "fbconversions", render: (rowData) => (
                                    <Typography variant="body2">{rowData.fbconversions}</Typography>
                                )
                            },
                            {
                                title: "Revenue", field: "siteUrl", render: (rowData) => (
                                    <Typography variant="body2">{parseFloat(rowData.revenue).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    })}</Typography>
                                )
                            },

                            {
                                title: "Meta API?", field: "siteUrl", render: (rowData) => (
                                    (rowData.fbapitoken !== null
                                        ? <IconButton color="primary"><BsMeta></BsMeta></IconButton>
                                        : ''
                                    )

                                )
                            },
                            {
                                title: "Facebook Validation", field: "site",
                                render: (rowData) => (
                                    <Button variant="outlined" startIcon={<BsMeta />} color="secondary" href="" id="fbverification" size="small" data-clipboard-text={appVars.facebookVerifyUrl + "?id=" + rowData.id}>
                                        Copy
                                    </Button>

                                ),
                            },
                            {
                                title: "GTM Download", field: "tag", render: (rowData) => (
                                    <IconButton color="secondary" href={'https://api.digitalwerks.com:7325/generate-gtm-container/default/' + rowData.id} target="_BLANK"><BsDownload /></IconButton>

                                )
                            },
                            // {title: "Site Type", field: "site" },
                            {
                                title: "GTM Instructions", field: "site",
                                render: (rowData) => (
                                    <Button variant="outlined" color="secondary" href="" id="gtminstall" size="small" data-clipboard-text={appVars.lightwerksUrl + "gtm-install/" + rowData.id}>
                                        Copy
                                    </Button>
                                ),
                            },
                        ]}



                    />

                </div>
                : <LinearProgress style={{ margin: ('20%', '25%') }} color="secondary"></LinearProgress>)}



<Dialog
                maxWidth="lg"
                fullWidth
                aria-labelledby="confirmation-dialog-title"
                open={open}
            >
                <DialogTitle id="confirmation-dialog-title">Add New Client</DialogTitle>
                <DialogContent dividers>

                    <Grid container md={12} spacing={3}>



                        <Grid item md={12} >
                            <Newclient admintoken={admintoken} themeColor={brandColor} ></Newclient>

                        </Grid>

                    </Grid>





                </DialogContent>
                <DialogActions>
                    <Button size="small" variant="outlined" color="primary" onClick={toggle} autoFocus >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>



            <Snackbar open={hasError} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    The data can not be pulled at this time.
                    <Button color="primary" onClick={window.location.reload}>
                        Refresh
                    </Button>
                </Alert>
            </Snackbar>

            <Snackbar
                open={copySuccessShow}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success">
                    {copySuccess}
                </Alert>
            </Snackbar>
        </div>
    );
};

SubClientList.propTypes = {

};

export default SubClientList;
