import React, { useState } from 'react';
import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from './Navbar'
import 'typeface-roboto';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import themeFile from './theme.json';
import { getCookie } from './dwi-functions';
import { Redirect, BrowserRouter, Route, Switch } from 'react-router-dom';
import LightBoxes from './LightGrid/TableView';
import DashBoard from './DashBoard';
import Login from './Login';
import Logout from './logout';
import SignUp from './SignUp';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import TagManager from './TagManagers';
import Timers from './Timers';
import Clients from './clients';
import Reports from './reports';
import Addproject from './zoho-projects/add';
import PasswordReset from './SignInSide/reset-password';
import PasswordResetForm from './SignInSide/password-reset';
import ClientView from './clients/single_client';
import GtmInstall from './gtm-install';
import SAInstall from './SA-install';
import FBInstall from './fb-install';
import TagInstall from './tag-install';
import LightboxPreview from './LightCard/lightbox-preview';
import LightboxPreviewb from './LightCard/lightbox-preview b';
import LightboxPreviewc from './LightCard/lightbox-preview c';
import SubclientViewer from './clients/subclientviewer/';
import NewClient from './clients/newclient';
import NewUser from './Users/Account/new-user';
import Profile from './clients/single_client/profile';
import appVars from './appVars.json';
import Audit from './clients/clientaudit/';
import ControlPanel from './settings/ControlPanel';
import SitesTypes from './settings/SiteTypes';
import { DAppProvider } from "@usedapp/core";
import Account from './Users/Account';
import iGen from './Timers/igen/';
import ecommerce from './settings/SiteTypes/ecommerce';
import EmailLinkValidator from './email-link-validator';
import ImageDetails from './Timers/image-details';
import ReactGA from "react-ga4";
import TinyEditor from "./settings/editor";
import LightBoxSettings from '../src/LightCard/LightBoxSettings';
import GrapeEditor from './settings/grape-editor';
import Users from './UserTable';
import { TokenProvider } from './Login/TokenProvider'


const queryParameters = new URLSearchParams(window.location.search);
const au = queryParameters.get("au");

const theme = createTheme(themeFile);
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {

    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0, 0),
    // marginLeft: "75px"
  },


}));



const site = {
  title: appVars.application
}
document.title = site.title;

function App() {
  const classes = useStyles();
  // eslint-disable-next-line
  const [auth, setAuth] = useState(getCookie('_au'));
  // eslint-disable-next-line
  const [admintoken, setAdmin] = useState(getCookie('_auta'));
  ReactGA.initialize(appVars.ga4);



  return (
    <TokenProvider>
      <DAppProvider config={{}}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <BrowserRouter>
            <div >
              <Switch>

                <Route path="/tag-install" component={TagInstall} />
                <Route path="/gtm-install" component={GtmInstall} />
                <Route path="/SA-install" component={SAInstall} />
                <Route path="/fb-install" component={FBInstall} />
                <Route path="/lightbox/preview" component={LightboxPreview} />
                <Route path="/lightbox/previewb" component={LightboxPreviewb} />
                <Route path="/lightbox/preview-html" component={LightboxPreviewc} />
                <Route path="/profile" component={Profile} />
                <Route path="/audit" component={Audit} />
                <Route path="/igen" component={iGen} />
                <Route path="/register" component={SignUp} exact />
                <Route path="/login" component={Login} exact />
                <Route path="/password-reset" component={PasswordReset} exact />
                <Route path="/reset-password" component={PasswordResetForm} />
                <Route path="/start-new" render={() => (
                  au !== 'b762e4f0-cb3a-4c20-be25-59d6b016daf8' ? <Redirect to="/" /> : <NewClient></NewClient>
                )} />


                <ThemeProvider theme={theme}>
                  <div className={classes.root}>

                    <Navbar siteTitle={site.title}></Navbar>
                    <main className={classes.content}>
                      <div className={classes.toolbar} />
                      {auth ? null : <Redirect to="/login" />}

                      <Route path="/" component={DashBoard} exact />
                      <Route path="/client" component={Clients} exact />
                      <Route path="/control" component={ControlPanel} exact />
                      <Route path="/control/editor/" component={TinyEditor} />
                      <Route path="/control/grape-editor/" component={GrapeEditor} />
                      <Route path="/control/sites" component={SitesTypes} exact />
                      <Route path="/control/users/" component={Users} exact />
                      <Route path="/account" component={Account} />
                      <Route path="/control/users/new/" component={NewUser} exact />
                      <Route path="/client/new/" exact >
                        <NewClient admintoken={admintoken}></NewClient>
                      </Route>
                      <Route path="/clientview" >
                        <ClientView admintoken={admintoken}></ClientView>
                        </Route>
                      <Route path="/subclientviewer" component={SubclientViewer} />
                      <Route path="/lightbox/" component={LightBoxes} />
                      <Route path="/lightbox/add" component={LightBoxSettings} />
                      <Route path="/tagmanager" component={TagManager} />
                      <Route path="/control/sites/ecommerce" component={ecommerce} />
                      <Route path="/control/linkvalidator" component={EmailLinkValidator} />
                      <Route path="/reports" component={Reports} />
                      <Route path="/timers" component={Timers} />
                      <Route path="/projects/add" component={Addproject} exact />
                      <Route path="/imagecreator" component={ImageDetails} />
                      <Route path="/logout" component={Logout} />

                    </main>
                  </div>
                </ThemeProvider>
              </Switch>
            </div>

          </BrowserRouter>
        </MuiPickersUtilsProvider>

      </DAppProvider>
    </TokenProvider>
  );
}

export default App;
